import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement } from 'react'
import get from 'lodash/get'

import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'

import GenericInput from 'components/Common/Inputs/GenericInput'
import { Attribute, INDEX_PARAM_PLACEHOLDER } from 'objects/attributes'
import {
  nestedObject, ObjOfStr, ObjOfStrOrNum, ResponseError,
} from 'types'
import getCSSValue from 'helpers'
import { FieldValue } from 'reducers/types'
import './InputGroup.scss'

type Props = {
  field: Attribute;
  handleError: (k: string, v: boolean) => void;
  item: ObjOfStrOrNum | nestedObject;
  responseError: ResponseError | undefined;
  onChange: (v: FieldValue, f: Attribute) => void;
  disabled: boolean | undefined;
}

const formatFieldPath = (f: Attribute, index: number) => {
  const usedPath = f.updatePath || f.path
  let formattedPath = usedPath
  if (index !== undefined && typeof usedPath !== 'string') {
    formattedPath = usedPath.map((pathEl: string) => {
      if (pathEl === INDEX_PARAM_PLACEHOLDER) return String(index)
      return pathEl
    })
  }
  return formattedPath
}

export default function InputGroup({
  field, item, onChange, handleError, responseError, disabled,
}: Props): ReactElement {
  const { t } = useTranslation()
  const value = get(item, field.path)

  const deleteGroup = (f: Attribute, index: number) => {
    onChange(
      get(item, f.path).filter((_el: ObjOfStr, arrIndex: number) => index !== arrIndex),
      f,
    )
  }
  const addGroup = (f: Attribute) => {
    onChange(
      [...get(item, f.path), f.defaultElement],
      f,
    )
  }
  const renderGroup = (el: ObjOfStr, f: Attribute, index: number) => (
    <div key={f.key} className={`w-100 d-flex ${index > 1 ? 'mt-3' : ''}`}>
      {f.elements?.map(fieldEl => (
        <GenericInput
          key={fieldEl.key}
          field={fieldEl}
          value={get(el, fieldEl.path)}
          onChange={(v, att) => onChange(v, { ...att, updatePath: formatFieldPath(att, index) })}
          onFieldError={handleError}
          responseError={responseError}
          index={index}
          noLabel={index > 0}
          // disable if entire group is disabled or if this specific field is disabled
          disabled={disabled
            || (fieldEl.shouldBeDisabled
              && fieldEl.shouldBeDisabled.values.includes(get(item, fieldEl.shouldBeDisabled.path)))}
        />
      ))}
      {get(item, f.path).length > 1 && !disabled && (
        <div className={`d-flex align-items-center justify-content-center ${index === 0 ? 'mt-3' : ''}`}>
          <IconButton onClick={() => deleteGroup(f, index)}>
            <RemoveIcon htmlColor={getCSSValue('--color-carbone')} />
          </IconButton>
        </div>
      )}
    </div>
  )
  return (
    <>
      <div className="px-3 input-group">
        {!field.hideHeader && (
          <div className="d-flex justify-content-between align-items-center pl-2 w-100">
            <div className="group-title">{field.groupTitle && t(field.groupTitle)}</div>
            <div className="d-flex align-items-center justify-content-center">
              <IconButton onClick={() => addGroup(field)}>
                <AddIcon htmlColor={getCSSValue('--color-carbone')} />
              </IconButton>
            </div>
          </div>
        )}
        {value && value.map((el: ObjOfStr, index: number) => renderGroup(el, field, index))}
      </div>
      {
        !field.hideBottomDivider && <Divider className="my-3" />
      }
    </>
  )
}
