import getCSSValue from 'helpers'
import { ExtraLayer, ExtraLayerSource } from 'objects/types/const'
import { formatLayerUrl } from 'objects/utils'
import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'

const textColor = '--color-medium-grey'
const sourceLayer = ExtraLayerSource[ExtraLayer.TrackName]

const layout: mapboxgl.AnyLayout = {
  'text-font': ['Open Sans Regular'],
  'symbol-placement': 'line',
  'text-size': 12,
  'text-allow-overlap': false,
  'text-field': '{name}',
}

const paint: mapboxgl.AnyPaint = {
  'text-color': getCSSValue(textColor),
  'text-halo-width': 2,
  'text-halo-color': 'rgba(255,255,255,0.75)',
  'text-halo-blur': 1,
}

export default function NamesLayer(): ReactElement {
  return (
    <Source
      type="vector"
      url={formatLayerUrl(sourceLayer)}
      source-layer={sourceLayer}
      id={ExtraLayer.TrackName}
    >
      <Layer
        id={`${ExtraLayer.LineCode}-layer`}
        type="symbol"
        layout={{
          ...layout,
          'text-field': '{lineCode}',
          'symbol-placement': 'line-center',
        }}
        paint={paint}
        source-layer={sourceLayer}
        filter={['==', ['get', 'deletedAt'], null]}
      />
      <Layer
        id={`${ExtraLayer.TrackName}-layer`}
        type="symbol"
        layout={layout}
        paint={paint}
        source-layer={sourceLayer}
        filter={['==', ['get', 'deletedAt'], null]}
      />
    </Source>
  )
}
