import { ReactElement, ReactNode } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import IconButton from '@mui/material/IconButton'
import getCSSValue from 'helpers'

type Props = {
  onClickReturn: () => void;
  children: ReactNode;
}

const style = {
  height: 55,
  backgroundColor: getCSSValue('--color-near-white'),
}

export default function PanelHeader({
  onClickReturn,
  children,
}: Props): ReactElement {
  return (
    <div className="d-flex align-items-center" style={style}>
      <IconButton onClick={onClickReturn} className="mr-3 ml-2">
        <ArrowBackIcon />
      </IconButton>

      <div className="w-100 h-100 d-flex align-items-center">
        {children}
      </div>
    </div>
  )
}
