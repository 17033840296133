/* eslint-disable @typescript-eslint/no-explicit-any */
import { patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ShortProtection, Sector } from 'objects/types/protections'
import { ThunkApiConfig } from 'types'
import { store } from 'Store'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import ObjectURI from 'objects/uri'

export const addChild = createAsyncThunk<Sector, ShortProtection, ThunkApiConfig>(
  'sector/addChild',
  async (newChild, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, children } = detailsPanelState.item as Sector

    const updatedSector = {
      id,
      checksum,
      children: [...(children || []), {
        id: newChild.id,
      }],
    }

    try {
      const response: Sector = await patch(
        `/midi-next/v2/${ObjectURI.Sectors}/${updatedSector.id}`,
        updatedSector,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteChild = createAsyncThunk<Sector, string, ThunkApiConfig>(
  'sector/deleteChild',
  async (deletedChildId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, children } = detailsPanelState.item as Sector

    const updatedSector: Partial<Sector> = {
      id,
      checksum,
      children: children.filter(tp => tp.id !== deletedChildId),
    }

    try {
      const response: Sector = await patch(
        `/midi-next/v2/${ObjectURI.Sectors}/${updatedSector.id}`,
        updatedSector,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
