import { DateTime } from 'luxon'

const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1)

export const formatDate = (date: string): string => (
  DateTime.fromISO(date).toFormat('dd/MM/yyyy')
)

export const formateRelativeDate = (date: string): string => {
  const relativeDate = DateTime.fromISO(date).toRelativeCalendar({ locale: 'fr' })
  if (relativeDate !== null) return capitalize(relativeDate)
  return formatDate(date)
}
