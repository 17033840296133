/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Area } from 'objects/types/protections'
import { store } from 'Store'
import { ThunkApiConfig } from 'types'
import URI from '../uri'

type SubAreaParams = {
  id: string;
  subArea: Partial<Area>;
}

type AllSubAreaResponse = {
  areaId: string;
  subAreas: Area[];
}

export const getAllSubAreas = createAsyncThunk<AllSubAreaResponse, string, ThunkApiConfig>(
  'area/getAllSubAreas',
  async (areaId, thunkApi) => {
    try {
      const response = await get(`/midi-next/v2/${URI.areas}/${areaId}/${URI.subAreas}`)
      return {
        areaId,
        subAreas: response.results as Area[],
      }
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type SubAreaResponse = {
  areaId: string;
  subArea: Area;
}

export const addSubArea = createAsyncThunk<SubAreaResponse, SubAreaParams, ThunkApiConfig>(
  'area/addSubArea',
  async (params, thunkApi) => {
    try {
      const response: Area = await post(`/midi-next/v2/${URI.areas}/${params.id}/${URI.subAreas}/`, {
        label: params.subArea.label,
      })
      store.dispatch(getAllSubAreas(params.id))
      return {
        areaId: params.id,
        subArea: response,
      }
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const updateSubArea = createAsyncThunk<SubAreaResponse, SubAreaParams, ThunkApiConfig>(
  'area/updateSubAreaById',
  async (params, thunkApi) => {
    try {
      const response: Area = await patch(
        `/midi-next/v2/${URI.areas}/${params.id}/${URI.subAreas}/${params.subArea.id}/`, {
          label: params.subArea.label,
          checksum: params.subArea.checksum,
        },
      )
      store.dispatch(getAllSubAreas(params.id))
      return {
        areaId: params.id,
        subArea: response,
      }
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteSubArea = createAsyncThunk<string, SubAreaParams, ThunkApiConfig>(
  'area/deleteSubAreaById',
  async (params, thunkApi) => {
    try {
      await deleteRequest(`/midi-next/v2/${URI.areas}/${params.id}/${URI.subAreas}/${params.subArea.id}`)
      store.dispatch(getAllSubAreas(params.id))
      return params.subArea.id as string
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
