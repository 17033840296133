import { Fragment, ReactElement } from 'react'
import { useSelector } from 'react-redux'

import { InstructionState } from 'reducers/instruction'
import { RootState } from 'Store'
import ColorLoading from '../common/ColorLoading'
import SelectMap from './SelectMap'
import EditItemsList from './EditItemList/EditItemsList'
import SelectionFulfilled from '../common/SelectionFulfilled'
import './ItemsSelection.scss'

type Props = {
  selectMapTitle: string;
  selectMapSubtitle?: string;
}

const defaultProps = {
  selectMapSubtitle: undefined,
}

export default function ItemsSelection({ selectMapTitle, selectMapSubtitle }: Props): ReactElement {
  const { activeSubStep } = useSelector((state: RootState) => state.instruction) as InstructionState

  const SUB_STEPS = [
    {
      key: 0,
      component: <SelectMap
        title={selectMapTitle}
        subtitle={selectMapSubtitle}
      />,
    },
    {
      key: 1,
      component: <ColorLoading message="Instruction.creation.itemSelection.getObjectsLoading" />,
    },
    {
      key: 2,
      component: <SelectionFulfilled />,
    },
    {
      key: 3,
      component: <EditItemsList />,
    },
    {
      key: 4,
      component: <ColorLoading
        message="Instruction.creation.itemSelection.saveObjectsLoading"
      />,
    },
  ]

  return (
    <div id="items-selection" className="d-flex w-100 flex-column h-100">
      {SUB_STEPS.map(subStep => (activeSubStep === subStep.key
        ? (
          <Fragment key={subStep.key}>
            {subStep.component}
          </Fragment>
        )
        : null))}
    </div>
  )
}

ItemsSelection.defaultProps = defaultProps
