import { useTranslation } from '@osrdata/app_core/dist/translation'
import { HTMLInputTypeAttribute, ReactElement } from 'react'
import { useDispatch } from 'react-redux'

import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'

import './CustomTextField.scss'

export enum InputStyle {
  light = 'light',
  filled = 'grey',
  outlined = 'outlined',
}

type Props = {
  input: {
    label: string;
    value: unknown;
    type?: HTMLInputTypeAttribute;
    updateFunction: (v: string) => void;
    placeholder: string;
  };
  autoFocus?: boolean;
  style?: InputStyle;
  onBlur?: () => void;
  dense?: boolean;
}

const defaultProps = {
  autoFocus: false,
  style: InputStyle.outlined,
  onBlur: () => {},
  dense: false,
}

export default function CustomTextField({
  input, autoFocus, style, onBlur, dense,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const getClassname = () => {
    switch (style) {
      case InputStyle.filled:
        return 'filled-input elevation-2'
      case InputStyle.light:
        return 'light-input'
      case InputStyle.outlined:
      default:
        return 'outline-input'
    }
  }

  return (
    <FormControl key={input.label} variant="standard" className={`text-input w-100 ${dense ? '' : 'my-3'}`}>
      <InputLabel shrink>{t(input.label)}</InputLabel>
      <Input
        type={input.type || 'text'}
        autoFocus={autoFocus}
        placeholder={t(input.placeholder)}
        className={getClassname()}
        value={input.value}
        onChange={e => dispatch(input.updateFunction(e.target.value))}
        disableUnderline
        onBlur={onBlur}
      />
    </FormControl>
  )
}

CustomTextField.defaultProps = defaultProps
