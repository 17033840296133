import { Modal, Paper } from '@mui/material'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import SimpleButton from 'components/Common/SimpleButton/SimpleButton'
import doubleWheel from 'assets/icons/doublewheel.svg'
import SubnetServices from 'services/SubnetServices'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  SubnetState, toggleOpenValidationModal, toggleDisplayCreation, toggleShowLinearization,
} from 'reducers/subnet'
import { RootState } from 'Store'

import './SubnetValidationInfoModal.scss'

export default function SubnetValidationInfoModal(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    openValidationModal,
    displayCreation,
    showLinearization,
  } = useSelector((state: RootState) => state.subnet) as SubnetState

  const closeModal = () => {
    dispatch(SubnetServices.getAll())
    dispatch(toggleOpenValidationModal())
    if (showLinearization) {
      dispatch(toggleShowLinearization())
    }
    if (displayCreation) {
      dispatch(toggleDisplayCreation())
    }
  }

  const handleClose = (e:React.MouseEvent<HTMLElement>, reason: string) => {
    if (e && reason === 'backdropClick') { return }
    closeModal()
  }

  return (
    <Modal
      open={openValidationModal}
      onClose={handleClose}
      id="subnet-validation-modal"
    >
      <Paper className="d-flex flex-column align-items-center justify-content-center text-center">
        <div className="mt-4">
          <img src={doubleWheel} alt="" />
        </div>
        <div style={{ fontSize: 36, fontWeight: 800 }} className="my-4">
          {t('Subnets.modal.creation.title')}
        </div>
        <div style={{ fontSize: 20, fontWeight: 400 }}>
          {t('Subnets.modal.creation.subtitle')}
        </div>
        <div className="button-wrapper mt-4">
          <SimpleButton
            title="Revenir au tableau de bord"
            onClick={closeModal}
          />
        </div>
      </Paper>
    </Modal>
  )
}
