import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { GroupListItem, User } from 'services/cerbereTypes'
import InstructionServices from 'services/InstructionServices'
import UserServices from 'services/UserServices'
import { Instruction } from 'objects/types/instructions'
import { Area } from 'objects/types/protections'
import { Subnet } from 'objects/types/subnets'
import SubnetServices from 'services/SubnetServices'

export interface AdminState {
  activeTab: number;
  loading: boolean;
  instructionsList: Instruction[];
  openInstructionsList: Instruction[];
  subnetsList: Subnet[];
  groups: GroupListItem[];
  usersInfo: User[];
  areas: Area[];
}

const initialState: AdminState = {
  activeTab: 0,
  loading: false,
  instructionsList: [],
  openInstructionsList: [],
  subnetsList: [],
  groups: [],
  usersInfo: [],
  areas: [],
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload
    },
    openInstruction: (state, action: PayloadAction<Instruction>) => {
      const tabIndex = state.openInstructionsList.map(inst => inst.id).indexOf(action.payload.id)
      if (tabIndex === -1) {
        state.openInstructionsList.push(action.payload)
        state.activeTab = state.openInstructionsList.length
      } else {
        state.activeTab = tabIndex + 1
      }
    },
    closeInstruction: (state, action: PayloadAction<string>) => {
      const deletedInstIndex = state.openInstructionsList.map(inst => inst.id).indexOf(action.payload)
      if (deletedInstIndex + 1 === state.activeTab && deletedInstIndex === state.openInstructionsList.length - 1) {
        state.activeTab -= 1
      }
      state.openInstructionsList = state.openInstructionsList.filter(inst => inst.id !== action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(InstructionServices.getAll.fulfilled, (state, action) => {
      state.loading = false
      state.instructionsList = action.payload
    })
    builder.addCase(InstructionServices.create.fulfilled, (state, action) => {
      state.instructionsList.push(action.payload)
    })
    builder.addCase(InstructionServices.delete.fulfilled, (state, action) => {
      state.loading = false
      state.instructionsList = state.instructionsList.filter(instruction => instruction.id !== action.meta.arg)
      state.openInstructionsList = state.openInstructionsList.filter(instruction => instruction.id !== action.meta.arg)
    })
    builder.addCase(InstructionServices.delete.rejected, state => {
      state.loading = false
    })
    builder.addCase(SubnetServices.create.fulfilled, (state, action) => {
      state.subnetsList.push(action.payload)
    })
    builder.addCase(SubnetServices.update.fulfilled, (state, action) => {
      state.subnetsList = state.subnetsList.map(subnet => (
        subnet.id === action.payload.id
          ? action.payload
          : subnet
      ))
    })
    builder.addCase(SubnetServices.delete.fulfilled, (state, action) => {
      state.loading = false
      state.subnetsList = state.subnetsList.filter(subnet => subnet.slug !== action.meta.arg)
    })
    builder.addCase(SubnetServices.getAll.fulfilled, (state, action) => {
      state.loading = false
      state.subnetsList = action.payload
    })
    builder.addCase(UserServices.getGroups.fulfilled, (state, action) => {
      state.groups = action.payload
    })
    builder.addCase(UserServices.getUsersListInfo.fulfilled, (state, action) => {
      state.usersInfo.push(...action.payload)
    })
    builder.addMatcher(isAnyOf(
      InstructionServices.getAll.pending, InstructionServices.delete.pending,
    ), state => {
      state.loading = true
    })
    builder.addMatcher(isAnyOf(
      InstructionServices.addUsers.fulfilled, InstructionServices.addItems.fulfilled,
      InstructionServices.update.fulfilled,
    ), (state, action) => {
      state.instructionsList = state.instructionsList.map(instruction => (
        instruction.id === action.payload.id
          ? action.payload
          : instruction
      ))
      state.openInstructionsList = state.openInstructionsList.map(instruction => (
        instruction.id === action.payload.id
          ? action.payload
          : instruction
      ))
    })
  },
})

export const {
  openInstruction,
  closeInstruction,
  setActiveTab,
} = adminSlice.actions

export default adminSlice.reducer
