import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Divider from '@mui/material/Divider'

import Attributes from 'components/Panels/DetailsPanel/Attributes'
import ObjectsList from 'components/Panels/ListPanel/ObjectsList'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import { enableGeoEditor } from 'reducers/geoEditor'
import { isTrackSection } from 'objects/kind'
import { TrackSection } from 'objects/types'
import { MapState } from 'reducers/map'
import { RootState } from 'Store'

import TRACK_SECTION_ATTRIBUTES from './TrackSectionAttributes'

export default function TrackSectionDetails(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { item } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)
  const { centeredFeature } = useSelector((state: RootState): MapState => state.map)
  const [hasGeom, setHasGeom] = useState<boolean | undefined>(true)

  useEffect(() => {
    setHasGeom(centeredFeature && centeredFeature.geometry && centeredFeature.geometry.type !== null)
  }, [centeredFeature])

  return (
    <div id="track-section-details" className="object-attributes">
      <Attributes item={item as TrackSection} attributes={TRACK_SECTION_ATTRIBUTES()} />

      {item && isTrackSection(item) && item.track && (
        <>
          <Divider className="mt-2" />
          <div className="mx-4">
            <ObjectsList items={[item.track]} />
          </div>
        </>
      )}

      {!hasGeom && (
        <>
          <Divider className="my-2" />
          <div className="my-3 mx-4">
            <SimpleButton
              title={t('Details.button.createGeom')}
              onClick={() => {
                dispatch(enableGeoEditor())
              }}
              style={ButtonStyle.light}
            />
          </div>
        </>
      )}
    </div>
  )
}
