import { useDispatch, useSelector } from 'react-redux'
import { ReactElement, useEffect } from 'react'

import Paper from '@mui/material/Paper'

import ContextMenu from 'components/Common/ContextMenu/ContextMenu'
import AreaServices from 'services/AreaServices'
import { Area } from 'objects/types/protections'
import { AreasState } from 'reducers/areas'
import { RootState } from 'Store'
import { AREA_CONTEXT_MENU } from './const'
import './AreaTile.scss'
import SubAreas from './SubAreas'

type Props = {
  area: Area;
}

export default function AreaTile({ area }: Props): ReactElement {
  const dispatch = useDispatch()
  const { subAreasStates: dataFetchers } = useSelector((state: RootState) => state.areas) as AreasState

  useEffect(() => {
    const associatedDf = dataFetchers.find(df => df.areaId === area.id)
    if (associatedDf && associatedDf.loading && !associatedDf.isFetching) {
      dispatch(AreaServices.getAllSubAreas(area.id))
    }
  }, [dataFetchers])

  return (
    <Paper className="area-tile">
      <div className="d-flex justify-content-between align-items-center">
        <div className="title">{area.label}</div>
        <div>
          <ContextMenu menuItems={AREA_CONTEXT_MENU(area)} />
        </div>
      </div>
      <SubAreas areaId={area.id} subAreas={dataFetchers.find(df => df.areaId === area.id)?.subAreas || []} />
    </Paper>
  )
}
