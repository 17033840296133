/* eslint-disable @typescript-eslint/no-explicit-any */
import { FeatureCollection, LineString, Polygon } from 'geojson'
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Subnet } from 'objects/types/subnets'
import URI from 'services/uri'
import { ThunkApiConfig } from 'types'
import { ShortTrackSection } from 'objects/types'
import { filterFields } from 'objects/utils'

const getAll = createAsyncThunk<Subnet[], undefined, ThunkApiConfig>(
  'subnet/getAll',
  async (_unused, thunkApi) => {
    try {
      const response = await get(`/midi-next/v2/${URI.subnets}/`)
      return response.results as Subnet[]
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getDetails = createAsyncThunk<Subnet, string, ThunkApiConfig>(
  'subnet/getDetails',
  async (id: string, thunkApi) => {
    try {
      const response: Subnet = await get(`/midi-next/v2/${URI.subnets}/${id}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<Subnet, Partial<Subnet>, ThunkApiConfig>(
  'subnet/create',
  async (newInstruction, thunkApi) => {
    try {
      const response: Subnet = await post(`/midi-next/v2/${URI.subnets}/`, newInstruction)
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type GetItemsInBboxParams = {
  bbox: Polygon;
  originSubnet: string;
  pageSize: number;
}

export const getItemsInBbox = createAsyncThunk<
  FeatureCollection<LineString, ShortTrackSection>, GetItemsInBboxParams, ThunkApiConfig
>(
  'subnet/getItemsInBbox',
  async (params, thunkApi) => {
    try {
      const response: FeatureCollection<LineString, ShortTrackSection> = await get(
        '/chartis/v2/layer/midi_track_section/geojson/sch/',
        { bpolygon: params.bbox, subnetId: params.originSubnet, page_size: params.pageSize },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const UPDATABLE_FIELDS: Array<keyof Subnet> = [
  'label', 'checksum',
]

const update = createAsyncThunk<Subnet, Partial<Subnet>, ThunkApiConfig>(
  'subnet/update',
  async (updatedSubnet, thunkApi) => {
    try {
      const response: Subnet = await patch(
        `/midi-next/v2/${URI.subnets}/${updatedSubnet.slug}`,
        filterFields<Subnet>(updatedSubnet, UPDATABLE_FIELDS),
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'subnet/delete',
  async (id, thunkApi) => {
    try {
      await deleteRequest(`/midi-next/v2/${URI.subnets}/${id}`)
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const SubnetServices = {
  getAll,
  getDetails,
  create,
  update,
  getItemsInBbox,
  delete: deleteObject,
}

export default SubnetServices
