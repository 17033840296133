import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import GeoEditorService from 'components/GeoEditor/GeoEditorService'
import { ResponseError } from 'types'
import { createFulfilledMatcher, subnetCreationError } from './matchers/createMatchers'
import { deleteFulfilledMatcher } from './matchers/deleteMatchers'
import allErrorsMatcher from './matchers/matchers'
import { updateFulfilledMatcher } from './matchers/update'
import { validationFulfilledMatcher } from './matchers/validationMatchers'

export enum FeedbackType {
  validation = 'validation'
}

export interface FeedbackState {
  feedback?: ResponseError;
  message: string;
}

const initialState: FeedbackState = {
  feedback: undefined,
  message: '',
}

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.feedback = action.payload
    },
    setMessage: (state, action: PayloadAction<string | undefined>) => {
      state.message = action.payload || ''
    },
  },
  extraReducers: builder => {
    builder.addCase(GeoEditorService.updateTrackSectionGeometry.fulfilled, state => {
      state.feedback = { code: 200, data: { isGeometryUpdate: true } }
    })

    // Specific error handling for subnet creation
    builder.addCase(subnetCreationError, (state, action) => {
      if (action.payload) {
        state.feedback = {
          ...action.payload,
          additionalNestedFields: ['label'],
        }
      }
    })

    // Error Handling
    builder.addMatcher(allErrorsMatcher, (state, action) => {
      state.feedback = action.payload
    })

    // Delete Handling
    builder.addMatcher(deleteFulfilledMatcher, state => {
      state.feedback = { code: 204, data: {} }
    })

    // Create Handling
    builder.addMatcher(createFulfilledMatcher, state => {
      state.feedback = { code: 201, data: {} }
    })

    // Update Handling
    builder.addMatcher(updateFulfilledMatcher, state => {
      state.feedback = { code: 200, data: {} }
    })

    builder.addMatcher(validationFulfilledMatcher, state => {
      state.feedback = {
        code: 200,
        data: {
          type: FeedbackType.validation,
        },
      }
    })
  },
})

export const { setError, setMessage } = feedbackSlice.actions

export default feedbackSlice.reducer
