import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import set from 'lodash/set'

import ButtonStatus from 'components/Common/buttonStatus'
import { MidiObject } from 'objects/types'
import { UpdateField } from 'reducers/types'
import { ResponseError, ObjOfStrOrNum } from 'types'
import { updateErrorMatcher, updateFulfilledMatcher, updatePendingMatcher } from 'reducers/matchers/update'

type AutoCompleteParams = {
  key: string;
  loading: boolean;
  values: ObjOfStrOrNum[];
}

export interface ModificationPanelState {
  isLoading: boolean;
  buttonStatus: ButtonStatus;
  item: MidiObject;
  hasError: boolean;
  error?: ResponseError;
  autoCompletes: {[k: string]: AutoCompleteParams};
}

const initialState: ModificationPanelState = {
  isLoading: false,
  buttonStatus: ButtonStatus.Base,
  item: {} as MidiObject,
  hasError: false,
  error: undefined,
  autoCompletes: {},
}

const resetState = () => initialState

const setError = (state: ModificationPanelState, action: PayloadAction<ResponseError | undefined, string>) => {
  state.buttonStatus = ButtonStatus.Disabled
  state.error = action.payload
  state.hasError = true
  state.isLoading = false
}

const setLoading = (state: ModificationPanelState) => {
  state.buttonStatus = ButtonStatus.Loading
  state.isLoading = true
}

export const modificationPanelSlice = createSlice({
  name: 'modificationPanel',
  initialState,
  reducers: {
    resetModification: resetState,
    resetButtonStatus: state => {
      if (!state.hasError) state.buttonStatus = ButtonStatus.Base
    },
    setItemField: (state, action: PayloadAction<UpdateField>) => {
      const { value, path } = action.payload
      state.item = set(state.item, path, value) as MidiObject
    },
    updateItem: (state, action: PayloadAction<MidiObject>) => {
      state.item = action.payload
    },
    setHasError: (state, action: PayloadAction<boolean>) => {
      state.hasError = action.payload
      state.buttonStatus = action.payload
        ? ButtonStatus.Disabled
        : ButtonStatus.Base
    },
    updateAutoCompletes: (state, action: PayloadAction<AutoCompleteParams>) => {
      state.autoCompletes[action.payload.key] = action.payload
    },
  },
  extraReducers: builder => {
    // Pending
    builder.addMatcher(updatePendingMatcher, setLoading)

    // Fulfilled
    builder.addMatcher(updateFulfilledMatcher, resetState)

    // Error
    builder.addMatcher(updateErrorMatcher, setError)
  },
})

export const {
  resetButtonStatus,
  resetModification,
  updateItem: updateModificationItem,
  setItemField: setModificationItemField,
  setHasError: setModificationError,
} = modificationPanelSlice.actions

export default modificationPanelSlice.reducer
