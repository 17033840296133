import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import ButtonStatus from 'components/Common/buttonStatus'
import { Area } from 'objects/types/protections'
import AreaServices from 'services/AreaServices'

export enum ModalMode {
  creation = 'creation',
  modification = 'modification',
}

type SubAreaState = {
  areaId: string;
  loading: boolean;
  isFetching: boolean;
  subAreas: Area[];
}

const initialModal = {
  open: false,
  buttonStatus: ButtonStatus.Base,
  area: {},
  mode: ModalMode.creation,
}

export interface AreasState {
  loading: boolean;
  areas: Area[];
  subAreasStates: SubAreaState[];
  modal: {
    open: boolean;
    area: Partial<Area>;
    buttonStatus: ButtonStatus;
    mode: ModalMode;
  };
}

const initialState: AreasState = {
  loading: false,
  subAreasStates: [],
  areas: [],
  modal: initialModal,
}

export const areasSlice = createSlice({
  name: 'areas',
  initialState,
  reducers: {
    setSelectedArea: (state, action: PayloadAction<Partial<Area>>) => {
      state.modal.area = action.payload
    },
    toggleOpenModal: state => {
      state.modal.open = !state.modal.open
      if (!state.modal.open) state.modal.area = {}
    },
    setLabel: (state, action: PayloadAction<string>) => {
      state.modal.area.label = action.payload
      state.modal.buttonStatus = ButtonStatus.Base
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.modal.area.activityStartDate = action.payload
      state.modal.buttonStatus = ButtonStatus.Base
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.modal.area.activityEndDate = action.payload
      state.modal.buttonStatus = ButtonStatus.Base
    },
    setModalMode: (state, action: PayloadAction<ModalMode>) => {
      state.modal.mode = action.payload
    },
    setSubAreaLoading: (state, action: PayloadAction<string>) => {
      state.subAreasStates = state.subAreasStates.map(df => {
        if (df.areaId === action.payload) {
          return {
            ...df,
            loading: true,
          }
        }
        return df
      })
    },
  },
  extraReducers: builder => {
    builder.addCase(AreaServices.getAll.fulfilled, (state, action) => {
      state.loading = false
      state.areas = action.payload
      state.subAreasStates = action.payload.map(area => ({
        areaId: area.id,
        loading: true,
        isFetching: false,
        subAreas: [],
      }))
    })
    builder.addCase(AreaServices.getAll.pending, state => {
      state.loading = true
    })

    builder.addCase(AreaServices.create.fulfilled, (state, action) => {
      state.modal = initialModal
      state.areas.push(action.payload)
    })

    builder.addCase(AreaServices.delete.fulfilled, (state, action) => {
      state.areas = state.areas.filter(a => a.id !== action.payload)
    })

    builder.addCase(AreaServices.update.fulfilled, (state, action) => {
      state.modal = initialModal
      state.areas = state.areas.map(a => (a.id === action.payload.id
        ? action.payload
        : a))
    })

    builder.addCase(AreaServices.getAllSubAreas.pending, (state, action) => {
      state.subAreasStates = state.subAreasStates.map(df => {
        if (df.areaId === action.meta.arg) {
          return {
            ...df,
            loading: true,
            isFetching: true,
          }
        }
        return df
      })
    })

    builder.addCase(AreaServices.getAllSubAreas.fulfilled, (state, action) => {
      state.subAreasStates = state.subAreasStates.map(df => {
        if (df.areaId === action.payload.areaId) {
          return {
            ...df,
            loading: false,
            isFetching: false,
            subAreas: action.payload.subAreas,
          }
        }
        return df
      })
    })

    builder.addCase(AreaServices.getAllSubAreas.rejected, (state, action) => {
      state.subAreasStates = state.subAreasStates.map(df => {
        if (df.areaId === action.meta.arg) {
          return {
            ...df,
            loading: false,
          }
        }
        return df
      })
    })

    builder.addMatcher(isAnyOf(
      AreaServices.create.pending,
      AreaServices.update.pending,
    ), state => {
      state.modal.buttonStatus = ButtonStatus.Loading
    })

    builder.addMatcher(isAnyOf(
      AreaServices.create.rejected,
      AreaServices.update.rejected,
    ), state => {
      state.modal.buttonStatus = ButtonStatus.Disabled
    })
  },
})

export const {
  setSelectedArea, setLabel, setStartDate, setEndDate, toggleOpenModal, setModalMode,
  setSubAreaLoading,
} = areasSlice.actions

export default areasSlice.reducer
