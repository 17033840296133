import distance from '@turf/distance'
import {
  Feature, point, Properties,
} from '@turf/helpers'
import pointToLineDistance from '@turf/point-to-line-distance'
import { LineString, Point } from 'geojson'
import { TrackSection } from 'objects/types'

// Compute the distance between a Point, and either a LineString or an other point
const pointToFeatureDistance = (
  feature: Feature<Point | LineString, Properties>, refPoint: Feature<Point, Properties>,
): number => {
  if (feature.geometry.type === 'Point') return distance(feature.geometry, refPoint)

  return pointToLineDistance(refPoint, feature as Feature<LineString, Properties>)
}

// With an array of features and the mouse coords, we want to find the closest feature to the mouse
const getNearestFeature = (
  coords: [number, number], features: Feature<Point | LineString, TrackSection>[],
): Feature<Point | LineString, TrackSection> => {
  let chosenFeature = features[0]
  // Extracting Points from all features
  const snappingPoints = features.filter(f => f.geometry.type === 'Point')
  // Give priority to Points over Lines
  const chosenSnappingFeatures = snappingPoints.length > 0
    ? snappingPoints
    : features
  // Search for closest feature
  const mousePoint = point(coords)
  let minDist = pointToFeatureDistance(chosenFeature, mousePoint)
  chosenSnappingFeatures.forEach(snappingFeature => {
    const dist = pointToFeatureDistance(snappingFeature, mousePoint)
    if (dist < minDist) {
      minDist = dist
      chosenFeature = snappingFeature
    }
  })
  return chosenFeature
}

export default getNearestFeature
