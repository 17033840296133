/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Feature, Geometry } from 'geojson'
import { ObjectLayer } from 'objects/types/const'
import { ValidationParams } from 'components/Panels/DetailsPanel/utils'
import ObjectURI from 'objects/uri'
import { nestedObject, ObjOfStrOrNum, ThunkApiConfig } from 'types'
import { Feeder } from 'objects/types/protections'
import { filterFields, getUpdatedFields } from 'objects/utils'
import { addExtremity, deleteExtremity } from './extremities'
import { addResponsibleSubArea, deleteResponsibleSubArea } from './responsibleSubAreas'
import { addAssociatedSubArea, deleteAssociatedSubArea } from './associatedSubAreas'
import { addIncompatibleObject, deleteIncompatibleObject } from './incompatibleObjects'

import FEEDER_ATTRIBUTES from '../FeederAttributes'

const getDetails = createAsyncThunk<Feeder, string, ThunkApiConfig>(
  'feeder/getDetails',
  async (id, thunkApi) => {
    try {
      const response: Feeder = await get(`/midi-next/v2/${ObjectURI.Feeders}/${id}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<Geometry, Feeder>, string, ThunkApiConfig>(
  'feeder/getGeometry',
  async (id, thunkApi) => {
    try {
      const response: Feature<Geometry, Feeder> = await get(
        `/chartis/v2/layer/${ObjectLayer.Feeder}/geojson_feature/sch/`,
        { id },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<Feeder, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'feeder/create',
  async (newFeeder, thunkApi) => {
    try {
      const response: Feeder = await post(
        `/midi-next/v2/${ObjectURI.Feeders}/`,
        newFeeder,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<Feeder, Partial<Feeder>, ThunkApiConfig>(
  'feeder/updateById',
  async (updatedFeeder, thunkApi) => {
    try {
      const response: Feeder = await patch(
        `/midi-next/v2/${ObjectURI.Feeders}/${updatedFeeder.id}`,
        filterFields(updatedFeeder, getUpdatedFields(FEEDER_ATTRIBUTES())),
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'feeder/deleteById',
  async (id: string, thunkApi) => {
    try {
      await deleteRequest(`/midi-next/v2/${ObjectURI.Feeders}/${id}`)
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<Feeder, ValidationParams, ThunkApiConfig>(
  'feeder/validate',
  async ({ id, operation }, thunkApi) => {
    try {
      const response: Feeder = await post(
        `/midi-next/v2/${ObjectURI.Feeders}/${id}/${operation}/`,
        {},
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const FeederServices = {
  getDetails,
  getGeometry,
  create,
  update,
  validate,
  delete: deleteObject,
  addExtremity,
  deleteExtremity,
  addResponsibleSubArea,
  deleteResponsibleSubArea,
  addAssociatedSubArea,
  deleteAssociatedSubArea,
  addIncompatibleObject,
  deleteIncompatibleObject,
}

export default FeederServices
