import { FocusEventHandler, ReactElement } from 'react'

import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import RemoveIcon from '@mui/icons-material/Remove'

import { Area } from 'objects/types/protections'
import getCSSValue from 'helpers'

type Props = {
  subarea?: Area;
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onRemove: () => void;
}

const defaultProps = {
  subarea: undefined,
}

export default function SubAreaLine({ subarea, onBlur, onRemove }: Props): ReactElement {
  return (
    <div className="subarea-wrapper">
      <div style={{ flex: 1 }}>
        <TextField
          fullWidth
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          defaultValue={subarea ? subarea.label : ''}
          onBlur={onBlur}
        />
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <IconButton onClick={onRemove}>
          <RemoveIcon htmlColor={getCSSValue('--color-carbone')} />
        </IconButton>
      </div>
    </div>
  )
}

SubAreaLine.defaultProps = defaultProps
