import { Instruction } from 'objects/types/instructions'
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InstructionState } from 'reducers/instruction'
import { User } from 'services/cerbereTypes'
import InstructionServices from 'services/InstructionServices'
import { RootState } from 'Store'
import UserMultiSelect from '../common/UserMultiSelect'

import './Attributions.scss'

const getInitialSelectedUsers = (instruction: Partial<Instruction>, users: User[]): User[] => {
  try {
    const res = instruction.users !== undefined
      ? users.filter(user => instruction.users?.map(u => u.sub).includes(user.id))
      : []
    return res
  } catch (e) {
    return []
  }
}

export default function Attributions(): ReactElement {
  const dispatch = useDispatch()
  const {
    administrators, operators, supervisors, instruction,
  } = useSelector(
    (state: RootState) => state.instruction,
  ) as InstructionState
  const [selectedSupervisors, setSelectedSupervisors] = useState<User[]>(
    getInitialSelectedUsers(instruction, supervisors),
  )
  const [selectedOperators, setSelectedOperators] = useState<User[]>(
    getInitialSelectedUsers(instruction, operators),
  )
  const [selectedAdministrators, setSelectedAdministrators] = useState<User[]>(
    getInitialSelectedUsers(instruction, administrators),
  )

  const onBlur = () => {
    if (operators.length !== 0 && supervisors.length !== 0 && administrators.length !== 0) {
      dispatch(InstructionServices.addUsers({
        ...instruction,
        users: [
          ...selectedOperators.map(o => o.id),
          ...selectedSupervisors.map(s => s.id),
          ...selectedAdministrators.map(s => s.id),
        ],
      }))
    }
  }

  return (
    <div id="users-attributions" className="d-flex align-items-center flex-column">
      <div className="w-75">
        <div className="my-5">
          <UserMultiSelect
            options={administrators}
            label="Instruction.creation.userAssignation.adminSelect.label"
            placeholder="Instruction.creation.userAssignation.adminSelect.placeholder"
            onChange={setSelectedAdministrators}
            onBlur={onBlur}
            defaultValue={selectedAdministrators}
          />
        </div>
        <div className="my-5">
          <UserMultiSelect
            options={supervisors}
            label="Instruction.creation.userAssignation.supervisorSelect.label"
            placeholder="Instruction.creation.userAssignation.supervisorSelect.placeholder"
            onChange={setSelectedSupervisors}
            onBlur={onBlur}
            defaultValue={selectedSupervisors}
          />
        </div>
        <div className="my-5">
          <UserMultiSelect
            options={operators}
            label="Instruction.creation.userAssignation.operatorSelect.label"
            placeholder="Instruction.creation.userAssignation.operatorSelect.placeholder"
            onChange={setSelectedOperators}
            onBlur={onBlur}
            defaultValue={selectedOperators}
          />
        </div>
      </div>
    </div>
  )
}
