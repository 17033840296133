import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import MidiRole from 'services/midiRoleTypes'
import { OperatorState } from 'reducers/operator'
import { setRole } from 'reducers/app'
// import { setInstruction } from 'reducers/instruction'
import InstructionServices from 'services/InstructionServices'
import { Instruction, User } from 'objects/types/instructions'
import UserServices from 'services/UserServices'
import Loader from 'components/Common/Loader'
import { RootState } from 'Store'
import { COLUMNS } from './const'
import './InstructionsTable.scss'

export default function InstructionsTable(): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    instructionsList: list, usersInfo, loading,
  } = useSelector((state: RootState) => state.operator) as OperatorState

  useEffect(() => {
    dispatch(InstructionServices.getAll())
    dispatch(setRole(MidiRole.operator))
  }, [])

  useEffect(() => {
    list.forEach(instruction => {
      dispatch(UserServices.getUsersListInfo((instruction.users as User[]).map(u => u.sub)))
    })
  }, [list])

  if (loading) {
    return (
      <div className="h-100 w-100">
        <Loader />
      </div>
    )
  }

  const handleInstructionClick = (instruction: Instruction) => {
    dispatch(InstructionServices.getDetails(instruction.id))
  }

  return (
    <div className="instructions-dashboard-user">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {COLUMNS.map(column => (
                <TableCell key={column.title}>{t(column.title)}</TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map(instruction => (
              <TableRow
                key={instruction.id}
                hover
                className="instruction-row"
              >
                {COLUMNS.map(column => (
                  <TableCell
                    key={column.title}
                    onClick={() => { handleInstructionClick(instruction) }}
                  >
                    {column.formatter(instruction[column.propertyName], usersInfo)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
