import { RefObject } from 'react'
import {
  FlyToInterpolator, MapEvent, MapRef, MapRequest, ViewportProps,
} from 'react-map-gl'
import { Feature } from 'geojson'
import bbox from '@turf/bbox'
import MAIN_API from 'config/config'
import union from 'lodash/union'

import { MidiObject } from 'objects/types'
import { ObjectLayer, ExtraLayer, ExtraLayerSource } from 'objects/types/const'
// import { PanelName, PanelState } from 'reducers/panels/panel'
import { MapState, setLayersToUpdate } from 'reducers/map'
import { InstructionState } from 'reducers/instruction'
import { store } from 'Store'
import MAP_ICONS from './icons'

export const MAX_ZOOM = 19
export const INVALID_GEOM_TYPES = ['GeometryCollection', null]

export const DEFAULT_VIEWPORT: ViewportProps = {
  latitude: 46.88731499073388,
  longitude: 2.5682289198122756,
  zoom: 5.6650,
  bearing: 0,
  pitch: 0,
}

export type Coordinates = {
  lng: number;
  lat: number;
}

export type MapLayerObject = {
  sourceLayer: ObjectLayer;
  properties: MidiObject;
}

export type FeatureClickEvent = MapEvent & {
  features: MapLayerObject[];
}

export const transformRequest = (url: string | undefined, resourceType: string | undefined): MapRequest => {
  if (url === undefined) return { url: '' }
  if ((resourceType === 'Source' || resourceType === 'Tile') && url.startsWith(MAIN_API.proxy)) {
    return {
      url,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    }
  }
  return { url }
}

export const centerMap = (
  f: Feature, viewport: ViewportProps, updateViewport: (v: ViewportProps) => void, currentMapRef: MapRef, padding = 200,
): void => {
  if (!INVALID_GEOM_TYPES.includes(f.geometry.type) && currentMapRef) {
    const cameraOptions = currentMapRef.getMap().cameraForBounds(bbox(f), {
      padding,
    })
    if (cameraOptions && viewport.zoom && viewport.zoom < cameraOptions.zoom) {
      const newViewport: ViewportProps = {
        ...viewport,
        zoom: cameraOptions.zoom,
        latitude: cameraOptions.center.lat,
        longitude: cameraOptions.center.lng,
        transitionDuration: 800,
        transitionInterpolator: new FlyToInterpolator(),
      }
      updateViewport(newViewport)
    }
  }
}

const resetSource = (sourceId: string, mapRef: RefObject<MapRef>): void => {
  const mapInstance = mapRef?.current?.getMap()
  // Remove the tiles for a particular source
  mapInstance.style.sourceCaches[sourceId].clearTiles()

  // Load the new tiles for the current viewport (mapInstance.transform -> viewport)
  mapInstance.style.sourceCaches[sourceId].update(mapInstance.transform)

  // Force a repaint, so that the map will be repainted without you having to touch the map
  mapInstance.triggerRepaint()
}

export const refreshTiles = (mapRef: RefObject<MapRef>): void => {
  const { layers, layersToUpdate, selectedSubnet } = store.getState().map as MapState
  const { instruction } = store.getState().instruction as InstructionState

  if (mapRef.current && layersToUpdate.length !== 0) {
    const mapInstance = mapRef.current.getMap()
    const refreshedLayers: Array<ObjectLayer | ExtraLayer | string> = union(
      layers, layersToUpdate, layers.map(l => `hover-source-${l}`), layersToUpdate.map(l => `hover-source-${l}`),
    )
    refreshedLayers.forEach(layer => {
      let sourceLayer = layer
      if (Object.values(ExtraLayer).includes(layer as unknown as ExtraLayer)) {
        sourceLayer = ExtraLayerSource[layer as unknown as ExtraLayer]
      }

      // Define base tiles URL
      let tempCacheUrl = `${MAIN_API.proxy}/chartis/v2/layer/${
        sourceLayer
      }/mvt_tile/sch/?x={x}&y={y}&z={z}`

      // Add dates filters
      if (instruction.applicationDate !== undefined) {
        tempCacheUrl += `&activityStartDate__lte_or_null=${
          instruction.applicationDate
        }&activityEndDate__gte_or_null=${
          instruction.applicationDate
        }`
      }
      // Add subnet filter
      if (selectedSubnet !== undefined) {
        tempCacheUrl += `&subnetId=${selectedSubnet.id}`
      }

      // Add Date to force cache reset
      tempCacheUrl += `&#${Date.now()}`

      const sourceId = layer
      try {
        mapInstance.getSource(sourceId).tiles = [tempCacheUrl]
        resetSource(sourceId, mapRef)
        resetSource(`hover-source-${sourceId}`, mapRef)
      } catch (err) {
      // console.log(err)
      }
    })
    store.dispatch(setLayersToUpdate([]))
  }
}

export const addImagesToInstance = (currentMapRef: MapRef): void => {
  const currentMap = currentMapRef.getMap()
  MAP_ICONS.forEach(icon => {
    currentMap.loadImage(icon.image, (_err: Error, img: ImageBitmap) => {
      currentMap.addImage(icon.name, img)
    })
  })
}
