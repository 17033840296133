import { ITEM_TYPES, ObjectKind, ObjectLayer } from './types/const'
import {
  Isolator, MidiObject, ShortMidiObject, Track, TrackIdentificationSystem,
  TrackSection, Signal,
} from './types'
import ObjectURI from './uri'
import {
  Feeder, Sector, SubSector, TrackProtection, TrackProtectionGroup,
} from './types/protections'

export const isElectricalElement = (obj: MidiObject | ShortMidiObject): obj is TrackProtectionGroup => (
  obj.itemType === ITEM_TYPES.electricalelement || obj.detailUrl?.includes(ObjectURI.ElectricalElements)
)
export const isElectricalProtectionGroup = (obj: MidiObject | ShortMidiObject): obj is TrackProtectionGroup => (
  obj.itemType === ITEM_TYPES.electricalprotectiongroup || obj.detailUrl?.includes(ObjectURI.ElectricalProtectionGroups)
)
export const isFeeder = (obj: MidiObject | ShortMidiObject): obj is Feeder => (
  obj.itemType === ITEM_TYPES.feeder || obj.detailUrl?.includes(ObjectURI.Feeders)
)
export const isIsolator = (obj: MidiObject | ShortMidiObject): obj is Isolator => (
  obj.itemType === ITEM_TYPES.isolator || obj.detailUrl?.includes(ObjectURI.Isolators)
)
export const isSector = (obj: MidiObject | ShortMidiObject): obj is Sector => (
  obj.itemType === ITEM_TYPES.sector || (obj.detailUrl?.includes(ObjectURI.Sectors)
    && !obj.detailUrl?.includes(ObjectURI.SubSectors))
)
export const isSignal = (obj: MidiObject | ShortMidiObject): obj is Signal => (
  obj.itemType === ITEM_TYPES.signal || obj.detailUrl?.includes(ObjectURI.Signals)
)
export const isSubSector = (obj: MidiObject | ShortMidiObject): obj is SubSector => (
  obj.itemType === ITEM_TYPES.subsector || obj.detailUrl?.includes(ObjectURI.SubSectors)
)
export const isTrack = (obj: MidiObject | ShortMidiObject): obj is Track => (
  obj.itemType === ITEM_TYPES.track || obj.detailUrl?.includes(ObjectURI.Tracks)
)
export const isTrackIdentificationSystem = (obj: MidiObject | ShortMidiObject): obj is TrackIdentificationSystem => (
  obj.itemType === ITEM_TYPES.track || obj.detailUrl?.includes(ObjectURI.TIS)
)
export const isTrackProtection = (obj: MidiObject | ShortMidiObject): obj is TrackProtection => (
  obj.itemType === ITEM_TYPES.trackprotection || obj.detailUrl?.includes(ObjectURI.TrackProtections)
)
export const isTrackProtectionGroup = (obj: MidiObject | ShortMidiObject): obj is TrackProtectionGroup => (
  obj.itemType === ITEM_TYPES.trackprotectiongroup || obj.detailUrl?.includes(ObjectURI.TrackProtectionGroups)
)
export const isTrackSection = (obj: MidiObject | ShortMidiObject): obj is TrackSection => (
  obj.itemType === ITEM_TYPES.tracksection || obj.detailUrl?.includes(ObjectURI.TrackSections)
)

export const findObjectKind = (unknownItem: MidiObject | ShortMidiObject): ObjectKind => {
  switch (true) {
    case isElectricalElement(unknownItem):
      return ObjectKind.ElectricalElement
    case isElectricalProtectionGroup(unknownItem):
      return ObjectKind.ElectricalProtectionGroup
    case isFeeder(unknownItem):
      return ObjectKind.Feeder
    case isIsolator(unknownItem):
      return ObjectKind.Isolator
    case isSector(unknownItem):
      return ObjectKind.Sector
    case isSignal(unknownItem):
      return ObjectKind.Signal
    case isSubSector(unknownItem):
      return ObjectKind.SubSector
    case isTrack(unknownItem):
      return ObjectKind.Track
    case isTrackIdentificationSystem(unknownItem):
      return ObjectKind.TrackIdentificationSystem
    case isTrackProtection(unknownItem):
      return ObjectKind.TrackProtection
    case isTrackProtectionGroup(unknownItem):
      return ObjectKind.TrackProtectionGroup
    case isTrackSection(unknownItem):
    default:
      return ObjectKind.TrackSection
  }
}

export const KIND_TO_LAYER = {
  [ObjectKind.ElectricalElement]: ObjectLayer.ElectricalElement,
  [ObjectKind.ElectricalProtectionGroup]: ObjectLayer.ElectricalProtectionGroup,
  [ObjectKind.Feeder]: ObjectLayer.Feeder,
  [ObjectKind.Isolator]: ObjectLayer.Isolator,
  [ObjectKind.Sector]: ObjectLayer.Sector,
  [ObjectKind.Signal]: ObjectLayer.Signal,
  [ObjectKind.SubSector]: ObjectLayer.SubSector,
  [ObjectKind.Track]: ObjectLayer.Track,
  [ObjectKind.TrackIdentificationSystem]: ObjectLayer.TrackIdentificationSystem,
  [ObjectKind.TrackProtection]: ObjectLayer.TrackProtection,
  [ObjectKind.TrackProtectionGroup]: ObjectLayer.TrackProtectionGroup,
  [ObjectKind.TrackSection]: ObjectLayer.TrackSection,
}
