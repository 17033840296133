enum URI {
  cerbereApi = 'cerbere',
  instructions = 'instructions',
  instructionItems = 'instruction-items',
  users = 'users',
  areas = 'areas',
  subAreas = 'sub-areas',
  subnets = 'subnets'
}

export default URI
