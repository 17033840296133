import { ReactElement } from 'react'

import { ShortProtection } from 'objects/types/protections'
import ContextMenu, { ContextMenuItem } from 'components/Common/ContextMenu/ContextMenu'
import { store } from 'Store'
import { GenericAction } from 'types'

const IP_CONTEXT_MENU_ITEMS = (sp: ShortProtection, deleteAction: GenericAction): ContextMenuItem[] => [
  {
    key: 'delete',
    label: 'Details.linkedObjectContextMenu.delete',
    onClick: () => { store.dispatch(deleteAction(sp.id)) },
    show: true,
  },
]

type Props = {
  sp: ShortProtection;
  deleteAction: GenericAction;
}

export default function IpContextMenu({ sp, deleteAction }: Props): ReactElement {
  return <ContextMenu menuItems={IP_CONTEXT_MENU_ITEMS(sp, deleteAction)} />
}
