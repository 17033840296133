import { ReactElement } from 'react'
import ItemsSelection from '../ItemsSelection/ItemsSelection'
import UserAttribution from '../UserAttribution'
import InstructionCreation from './InstructionCreation'
import InstructionNameChoice from './NameChoice'
import InstructionRefChoice from './RefChoice'

export const NEW_VERSION_STEPS = [
  {
    activeStep: 0,
    view: <InstructionNameChoice
      title="Instruction.newVersion.nameTitle"
    />,
  },
  {
    activeStep: 1,
    view: <InstructionRefChoice />,
  },
  {
    activeStep: 2,
    view: <ItemsSelection
      selectMapTitle="Instruction.newVersion.selectMapTitle"
    />,
  },
  {
    activeStep: 3,
    view: <UserAttribution />,
  },
]

export default function NewVersion(): ReactElement {
  return (
    <InstructionCreation steps={NEW_VERSION_STEPS} />
  )
}
