import { ChangeEvent, ReactElement, useEffect } from 'react'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { MapState, setSelectedSubnet } from 'reducers/map'
import SubnetServices from 'services/SubnetServices'
import { AdminState } from 'reducers/admin'
import { RootState } from 'Store'
import './SubnetsMenu.scss'

type Props = {
  display: boolean;
  captureClick: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export default function SubnetsMenu({ display, captureClick }: Props): ReactElement {
  const dispatch = useDispatch()
  const { subnetsList } = useSelector((state: RootState): AdminState => state.admin)
  const { selectedSubnet } = useSelector((state: RootState): MapState => state.map)

  useEffect(() => {
    dispatch(SubnetServices.getAll())
  }, [])

  const onSelectSubnet = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedSubnet(subnetsList.find(s => s.id === event.target.value)))
  }

  return (
    <div
      id="subnets-menu"
      className={`toolbar-menu ${display ? 'display' : ''}`}
      onClickCapture={e => captureClick(e)}
    >
      <RadioGroup
        value={selectedSubnet?.id}
        onChange={onSelectSubnet}
      >
        {subnetsList.map(subnet => (
          <div key={subnet.id} className="d-flex align-items-center">
            <FormControlLabel
              value={subnet.id}
              control={<Radio checked={subnet.id === selectedSubnet?.id} />}
              label={subnet.label}
            />
          </div>
        ))}
      </RadioGroup>
    </div>

  )
}
