import React, { ReactElement } from 'react'

import CircularProgress from '@mui/material/CircularProgress'

type Props = {
  message?: string | undefined;
  overlay?: boolean;
}

const defaultProps: Props = {
  message: undefined,
  overlay: false,
}

const overlayStyle: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  width: '100%',
  height: '100%',
  zIndex: 10000,
}
export default function Loader({ message, overlay }: Props): ReactElement {
  const BaseLoader = (
    <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
      <CircularProgress />
      {message && <div className="m-2">{`${message}...`}</div>}
    </div>
  )
  if (overlay) {
    return (
      <div style={overlayStyle}>
        {BaseLoader}
      </div>
    )
  }
  return BaseLoader
}

Loader.defaultProps = defaultProps
