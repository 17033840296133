import { KP } from 'objects/types/common'
import {
  Isolator, ShortTrackIdentificationSystem, Signal, Track, TrackSection,
} from 'objects/types'
import { ObjectLayer, ObjectStatus } from 'objects/types/const'
import { Protection } from 'objects/types/protections'
import trackIcon from 'assets/icons/track.svg'
import isolatorIcon from 'assets/icons/isolator.png'
import signalIcon from 'assets/icons/signal.svg'
import s9Icon from 'assets/icons/s9.svg'
import s11Icon from 'assets/icons/s11.svg'
import validatedIcon from 'assets/icons/in_verification.svg'
import verifiedIcon from 'assets/icons/verified.svg'
import dispatchedIcon from 'assets/icons/dispatched.svg'
import { MapLayerObject } from './utils'

const formatKp = (rawKp: KP | undefined) => rawKp || 'PK manquant'

export const LAYERS_LABEL = {
  [ObjectLayer.ElectricalElement]: 'Object.type.electricalElement',
  [ObjectLayer.ElectricalProtectionGroup]: 'Object.type.electricalProtectionGroup',
  [ObjectLayer.Feeder]: 'Object.type.feeder',
  [ObjectLayer.Isolator]: 'Object.type.isolator',
  [ObjectLayer.Sector]: 'Object.type.sector',
  [ObjectLayer.Signal]: 'Object.type.signal',
  [ObjectLayer.SubSector]: 'Object.type.subSector',
  [ObjectLayer.Track]: 'Object.type.track',
  [ObjectLayer.TrackIdentificationSystem]: 'Object.type.track',
  [ObjectLayer.TrackProtection]: 'Object.type.trackProtection',
  [ObjectLayer.TrackProtectionGroup]: 'Object.type.trackProtectionGroup',
  [ObjectLayer.TrackSection]: 'Object.type.trackSection',
}

export const STATUS_LABEL = {
  [ObjectStatus.TODO]: 'Object.status.toDo',
  [ObjectStatus.IN_PROGRESS]: 'Object.status.toDo',
  [ObjectStatus.VALIDATED]: 'Object.status.validated',
  [ObjectStatus.VERIFIED]: 'Object.status.verified',
  [ObjectStatus.DISPATCHED]: 'Object.status.dispatched',
}

export const getStatusIcon = (status: ObjectStatus): string | undefined => {
  switch (Number(status)) {
    case ObjectStatus.VALIDATED:
      return validatedIcon
    case ObjectStatus.VERIFIED:
      return verifiedIcon
    case ObjectStatus.DISPATCHED:
      return dispatchedIcon
    case ObjectStatus.TODO:
    case ObjectStatus.IN_PROGRESS:
    default:
      return undefined
  }
}

export const getObjectPopupIcon = (object: MapLayerObject): string => {
  switch (object.sourceLayer) {
    case ObjectLayer.TrackIdentificationSystem:
    case ObjectLayer.Track:
      return trackIcon
    case ObjectLayer.Isolator:
      return isolatorIcon
    case ObjectLayer.Signal:
      return signalIcon
    case ObjectLayer.TrackProtection:
    case ObjectLayer.TrackProtectionGroup:
      return s9Icon
    case ObjectLayer.ElectricalElement:
    case ObjectLayer.ElectricalProtectionGroup:
    case ObjectLayer.Sector:
    case ObjectLayer.SubSector:
      return s11Icon
    default:
      return trackIcon
  }
}

export const getNoLabel = (layer: ObjectLayer): string => {
  switch (layer) {
    case (ObjectLayer.ElectricalElement):
      return 'Map.HoverPopupTitle.noLabelElectricalElement'
    case (ObjectLayer.ElectricalProtectionGroup):
      return 'Map.HoverPopupTitle.noLabelElectricalProtectionGroup'
    case (ObjectLayer.Feeder):
      return 'Map.HoverPopupTitle.noLabelFeeder'
    case (ObjectLayer.Sector):
      return 'Map.HoverPopupTitle.noLabelSector'
    case (ObjectLayer.SubSector):
      return 'Map.HoverPopupTitle.noLabelSubSector'
    case (ObjectLayer.TrackProtection):
      return 'Map.HoverPopupTitle.noLabelTrackProtection'
    case (ObjectLayer.TrackProtectionGroup):
      return 'Map.HoverPopupTitle.noLabelTrackProtectionGroup'
    default:
      return 'Map.HoverPopupTitle.noLabel'
  }
}

export const getTitle = (object: MapLayerObject, t: (s: string) => string): string => {
  switch (object.sourceLayer) {
    case ObjectLayer.Track:
      return `${(object.properties as Track).lineCode || ''}
       ${t('Map.HoverPopupTitle.track')}
       ${(object.properties as Track).name}`
    case ObjectLayer.TrackIdentificationSystem:
      return `${(object.properties as ShortTrackIdentificationSystem).lineCode}
      ${t('Map.HoverPopupTitle.track')}
      ${(object.properties as ShortTrackIdentificationSystem).trackName}`
    case ObjectLayer.Isolator:
      return `${t('Map.HoverPopupTitle.track')}
        ${(object.properties as Isolator).trackName} / 
        ${(object.properties as Isolator).kp}`
    case ObjectLayer.Signal:
      return `${(object.properties as Signal).type}
      ${(object.properties as Signal).label}`
    case ObjectLayer.ElectricalElement:
    case ObjectLayer.ElectricalProtectionGroup:
    case ObjectLayer.Feeder:
    case ObjectLayer.Sector:
    case ObjectLayer.SubSector:
    case ObjectLayer.TrackProtection:
    case ObjectLayer.TrackProtectionGroup:
      return (object.properties as Protection).label
        ? (object.properties as Protection).label
        : getNoLabel(object.sourceLayer)
    default:
      return `${formatKp((object.properties as TrackSection).startKp)
      }/${formatKp((object.properties as TrackSection).endKp)
      }`
  }
}
