import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import Divider from '@mui/material/Divider'

import LinkedProtections from 'objects/common/LinkedProtections/LinkedProtections'
import ResponsibleSubAreas from 'objects/common/ResponsibleSubAreas/ResponsibleSubAreas'
import AssociatedSubAreas from 'objects/common/AssociatedSubAreas/AssociatedSubAreas'
import Attributes from 'components/Panels/DetailsPanel/Attributes'
import Extremities from 'objects/common/Extremities/Extremities'
import { ElectricalProtectionGroup } from 'objects/types/protections'
import { CollapsibleMenu } from 'reducers/types'
import { MapState } from 'reducers/map'
import { ExtraKind } from 'objects/types/const'
import { RootState } from 'Store'

import ELECTRICAL_PROTECTION_GROUP_ATTRIBUTES from './ElectricalProtectionGroupAttributes'
import ElectricalProtectionGroupServices from './ElectricalProtectionGroupServices'
import Composition from './Composition/Composition'
import './ElectricalProtectionGroupDetails.scss'

export default function ElectricalProtectionGroupDetails(): ReactElement | null {
  const { item }: { item: ElectricalProtectionGroup } = useSelector(
    (state: RootState) => state.detailsPanel,
  )
  const { captureClick } = useSelector(
    (state: RootState): MapState => state.map,
  )

  return (
    <div className="electrical-element-details electrical-element-group-details object-attributes">
      <Attributes
        item={item}
        attributes={ELECTRICAL_PROTECTION_GROUP_ATTRIBUTES()}
      />

      <Divider className="my-2" />

      <div id="linked-objects">
        <Composition electricalElements={item.composition} />
        <Extremities
          type={ExtraKind.EPGExtremity}
          extremities={item.extremities}
          deleteExtremity={ElectricalProtectionGroupServices.deleteExtremity}
          switchOrientation={
            ElectricalProtectionGroupServices.switchExtremityDirection
          }
        />
        <ResponsibleSubAreas
          responsibleSubAreas={item.responsibleSubAreas}
          deleteRSAAction={
            ElectricalProtectionGroupServices.deleteResponsibleSubArea
          }
          addRSAAction={ElectricalProtectionGroupServices.addResponsibleSubArea}
        />
        <AssociatedSubAreas
          associatedSubAreas={item.associatedSubAreas}
          deleteASAAction={
            ElectricalProtectionGroupServices.deleteAssociatedSubArea
          }
          addASAAction={ElectricalProtectionGroupServices.addAssociatedSubArea}
        />
        <LinkedProtections
          linkedProtections={item.incompatibleProtections}
          deleteAction={
            ElectricalProtectionGroupServices.deleteIncompatibleObject
          }
          addIPAction={ElectricalProtectionGroupServices.addIncompatibleObject}
          type={CollapsibleMenu.incompatibleObjects}
        />
      </div>
      {captureClick !== undefined && <div className="capture-overlay" />}
    </div>
  )
}
