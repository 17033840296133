import { addOpenMenu, DetailsPanelState, removeOpenMenu } from 'reducers/panels/detailsPanel'
import { CollapsibleMenu } from 'reducers/types'
import { store } from 'Store'

const toggleOpenCollapsible = (c: CollapsibleMenu): void => {
  const { openMenus } = store.getState().detailsPanel as DetailsPanelState
  if (openMenus.includes(c)) store.dispatch(removeOpenMenu(c))
  else store.dispatch(addOpenMenu(c))
}

export default toggleOpenCollapsible
