import { ReactElement, SyntheticEvent } from 'react'

import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import { useTranslation } from '@osrdata/app_core/dist/translation'

import './TabsMenu.scss'

export interface Tab {
  key: string;
  value: string;
  label: string;
  component?: ReactElement;
}

type Props = {
  tabs: Tab[];
  handleTabChange: (e: SyntheticEvent, value: string) => void;
}

export default function TabsMenu({ tabs, handleTabChange }: Props): ReactElement {
  const { t } = useTranslation()

  return (
    <TabList onChange={handleTabChange} aria-label="tabs menu" className="tabs-menu">
      {tabs.map(tab => (
        <Tab
          key={tab.key}
          label={t(tab.label)}
          value={tab.value}
          className="tabs-menu-item"
        />
      ))}
    </TabList>
  )
}
