import { DateTime } from 'luxon'
import { Attribute, DATE_FORMAT } from 'objects/attributes'
import { FieldValue } from 'reducers/types'

type ChangeFunctionType = (v: FieldValue, f: Attribute) => void

const toRightDateTime = (date: string) => {
  try {
    DateTime.fromFormat(date, DATE_FORMAT)
  } catch (e) {
    return DateTime.fromISO(date)
  }
  return DateTime.fromFormat(date, DATE_FORMAT)
}

export const updateDayValueFrom = (rawNewDate: string | null, rawValue: FieldValue): string | null => {
  const value = rawValue as string
  if (rawNewDate !== null && value !== null && value !== '') {
    const newDate = toRightDateTime(rawNewDate)
    const formattedValue = toRightDateTime(value)
    if (newDate.isValid) {
      return !formattedValue.isValid
        ? newDate.toISODate()
        : formattedValue.set({ day: newDate.day, month: newDate.month }).toISODate()
    }
    return rawNewDate
  }
  if (rawNewDate !== null && toRightDateTime(rawNewDate).isValid) {
    return toRightDateTime(rawNewDate).toISODate()
  }
  return rawNewDate
}

export const validate = (
  rawV: string | null, f: Attribute, onChange: ChangeFunctionType, setLocalError: (b: boolean) => void,
): void => {
  if (rawV === '') {
    setLocalError(true)
  } else if (rawV !== null) {
    onChange(DateTime.fromISO(rawV).toISODate(), f)
  }
}
