import { ReactElement } from 'react'

import { Extremity } from 'objects/types/protections'
import ContextMenu, { ContextMenuItem } from 'components/Common/ContextMenu/ContextMenu'
import { store } from 'Store'
import { GenericAction } from 'types'

const EXTREMITY_CONTEXT_MENU_ITEMS = (
  extremity: Extremity, deleteAction: GenericAction<number>, switchOrientationAction: GenericAction<number> | undefined,
): ContextMenuItem[] => [
  {
    key: 'switchOrientation',
    label: 'Details.extremityContextMenu.switchOrientation',
    onClick: () => {
      if (switchOrientationAction !== undefined) {
        store.dispatch(switchOrientationAction(extremity.id))
      }
    },
    show: switchOrientationAction !== undefined,
  },
  {
    key: 'deleteExtremity',
    label: 'Details.extremityContextMenu.delete',
    onClick: () => { store.dispatch(deleteAction(extremity.id)) },
    show: true,
  },
]

type Props = {
  extremity: Extremity;
  deleteAction: GenericAction<number>;
  switchOrientationAction: GenericAction<number> | undefined;
}

export default function ExtremityContextMenu({
  extremity, deleteAction, switchOrientationAction,
}: Props): ReactElement {
  return <ContextMenu menuItems={EXTREMITY_CONTEXT_MENU_ITEMS(extremity, deleteAction, switchOrientationAction)} />
}
