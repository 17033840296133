export const ALL_SIGNAL_TYPES = [
  'A',
  'CARRE',
  'CV',
  'D',
  'GA',
  'ID',
  'IDD',
  'P',
  'R30',
  'RR30',
  'S',
  'TIV D MOB',
  'TIV R MOB',
  'TIV D FIXE',
  'TIVD B FIX',
  'TIVD C FIX',
  'TIV PENDIS',
  'TIV PENEXE',
  'TIV PENREP',
  'REP TGV',
  'JAL MAN',
  'CAB S',
  'CAB E',
  'FIN CAT',
  'BP DIS',
  'BP FIN',
  'BP EXE',
  'SECT',
  'CC EXE',
  'CC FIN',
  'REV',
  'G',
  'DEPOT',
  'IMP',
  'HEURT...',
  'PN...',
  'GIVRE',
  'DESTI',
  'SIFFLER',
  'TLD',
  'REP ITIN',
  'SLD',
  'SLM',
  'VOIE CONV',
  'MIBLAN VER',
  'DD',
  'CARRE A',
  'GARE',
  'LIMITETS',
  'JAL ARRET',
  'Z',
  'R',
  'ARRET VOY',
  'REPER VIT',
  'MIBLAN NOIR',
  'GABARIT',
  'TUNNEL',
  'LM',
  'LGR',
  'MV',
  'ARRET',
  'STOP',
  'ARRET A',
  'STOP A',
  'TECS',
  'TSCS',
  'L',
  'BIMODE',
  'BIMODE A',
  'CHEVRON',
  'DIVER',
  'SG HEURT',
  'S JDZ',
  'S (CH)',
  'S FIN EXPL',
  'B',
  'TAB DIVERS',
  'ATC',
  'APPROETSA',
  'CAB R',
  'SIG A TRAM',
  'SG LIMVIT',
  'SG MANOEUV',
  'SG TRELECT',
  'A TRAM',
]

export const BIG_SIGNAL_ICONS = [
  'ATC',
  'BP DIS',
  'CAB E',
  'CHEVRON BAS',
  'CAB R',
  'CAB S',
  'CC EXE',
  'CC FIN',
  'DD',
  'DEPOT',
  'G',
  'GA', // A tester en petit
  'GABARIT',
  'LGR',
  'LIMITETS',
  'LM',
  'R',
  'REP TGV',
  'REV',
  'SIFFLER',
  'Z',
]

export const EXISTING_SIGNAL_PANELS = [
  'A',
  'ATC',
  'BP DIS',
  'CAB E',
  'CAB R',
  'CAB S',
  'CARRE',
  'CC EXE',
  'CC FIN',
  'CHEVRON BAS',
  'CHEVRON HAUT',
  'CV',
  'D',
  'DD',
  'DEPOT',
  'G',
  'GA',
  'GABARIT',
  'ID',
  'JAL ARR E',
  'JAL ARR',
  'JAL MAN',
  'LGR',
  'LIMITETS',
  'LM',
  'M Cv',
  'R',
  'REP TGV',
  'REV',
  'S',
  'SIFFLER',
  'TECS D',
  'TECS G',
  'TSCS D',
  'TSCS G',
  'VIDE',
  'VIDEN',
  'VL A M M S Cv',
  'VL A M S Cv',
  'VL A RR S S C',
  'VL A S C',
  'VL A S S C',
  'VL A',
  'VL R A S C',
  'VL S',
  'Z',
]
