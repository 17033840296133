import { DateTime } from 'luxon'
import { InputTypes, Attribute } from 'objects/attributes'
import Parser from 'objects/parser'

const SUB_SECTOR_ATTRIBUTES = (defaultValues?: {[key: string]: string | undefined}): Array<Attribute> => ([
  {
    key: 'sector',
    path: 'sector',
    hideIfEmpty: true,
    label: 'Attributes.ElectricalElement.sector',
    parser: Parser.sector,
  },
  {
    key: 'label',
    path: 'label',
    label: 'Attributes.common.label',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'activityStartDate',
    path: 'activityStartDate',
    label: 'Attributes.common.activityStartDate',
    parser: Parser.date,
    isEditable: true,
    required: true,
    type: InputTypes.DatePicker,
    checkboxLabel: 'Attributes.common.activityStartDateCheckbox',
    creation: {
      required: true,
      defaultValue: defaultValues?.activityStartDate || DateTime.now().toISODate(),
    },
  },
  {
    key: 'activityEndDate',
    path: 'activityEndDate',
    label: 'Attributes.common.activityEndDate',
    parser: Parser.date,
    isEditable: true,
    required: true,
    type: InputTypes.DatePicker,
    checkboxLabel: 'Attributes.common.activityEndDateCheckbox',
    creation: {
      required: true,
      defaultValue: null,
    },
  },
])

export default SUB_SECTOR_ATTRIBUTES
