/**
 * Attributes are an implementation of a MidiObject field
 * This type is used to define precisely an object attibute, and how to interact with it
 * during the different processes in th app (display, modification, creation etc.)
 */
import { nestedObject, ObjOfStrOrNum, StrOrNum } from 'types'
import { FieldValue } from 'reducers/types'
import LineServices from './Lines/LineServices'
import { ObjectLayer } from './types/const'

export const LAYER_NAME = 'layerName'

export enum InputTypes {
  TextField = 'TextField',
  Select = 'Select',
  DatePicker = 'DatePicker',
  Autocomplete = 'Autocomplete',
  CaptureClick = 'CaptureClick',
}

export const DATE_FORMAT = 'dd/MM/yyyy'

type ValuesSource = typeof LineServices.getAll

type CreationAttribute = {
  required: boolean; // If Attribute is required when creating the object
  // Attribute's value at the beginning of the creation process
  defaultValue?: FieldValue;
}

export type CaptureMapClickParams = {
  updateParams: {
    clickedObjectPath: string | string[] | undefined;
    path: string | string[];
    addElement?: boolean; // When we want to append an array with the clicked object
  }[];
  objectLayer: ObjectLayer; // The layer to capture the click from
  displayedValueFormatter?: (o: nestedObject | undefined) => string;
}

export enum ArrayElementTypes {
  KP = 'KP'
}

export const INDEX_PARAM_PLACEHOLDER = '{index}'

export type ParserFunction<T> = (v: T) => StrOrNum

/**
 * The Attribute type
 */
export type Attribute = {
  key: string; // Attribut unique identifier (only unique for a given object)
  path: string | Array<string>; // Where to find the Attribute's raw data in object sent by back-end
  label: string; // Attribute displayed name to user
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parser: ParserFunction<any>; // Function to format the raw data from back-end
  isEditable?: boolean; // If Attribute should appear in modification panel, default is false
  shouldBeDisabled?: {
    path: string | string[];
    values: Array<FieldValue | ObjectLayer | undefined>;
  };
  isArray?: boolean; // ArrayParam: If Attribute is array, default is false
  hideHeader?: boolean; // ArrayParam: If should hide the top input group header, default is false
  hideBottomDivider?: boolean; // ArrayParam: If should hide the bottom input group divider, default is false
  groupTitle?: string; // ArrayParam: Title of an input group
  elements?: Attribute[]; // ArrayParam: Fields description for each array element
  defaultElement?: ObjOfStrOrNum; // ArrayParam: default element to add on modification, if needed
  hideDetails?: boolean; // If Attribute should hide in details panel, default is false
  hideIfEmpty?: boolean; // If Attribute should hide in details panel when null, default is false
  required?: boolean; // If Attribute should not be empty after a modification
  type?: InputTypes; // Attribute's data type: string, date, only a list of allowed values etc.
  values?: Array<{
    label: string;
    value: StrOrNum;
  }>; // List of allowed values if type = InputTypes.Select
  checkboxLabel?: string; // Checkbox label if type = InputTypes.DatePicker
  updatePath?: string | string[]; // Where to update the value, if different from path
  autoCompleteParams?: { // Required for InputTypes.Autocomplete
    key: string; // Unique identifier of the fetch options, used in autoComplete reducer
    source?: ValuesSource; // Method to call to fetch options
    options?: string[]; // Static options if there is no need to fetch options
    displayedValueFormatter: (o: ObjOfStrOrNum | string | undefined) => string; // Formatter to display options to user
  };
  captureClickParams?: CaptureMapClickParams | CaptureMapClickParams[]; // Required for InputTypes.CaptureClick
  // Required forInputTypes.CaptureClick: translated string to help user know the kind of object to click on
  captureClickHelper?: string;
  captureOnOpen?: boolean; // If we want the CaptureClick input to be selected on panel open, default is false
  creation?: CreationAttribute; // Rules concerning this Attribute in creation process
}
