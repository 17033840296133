import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import Divider from '@mui/material/Divider'

import LinkedProtections from 'objects/common/LinkedProtections/LinkedProtections'
import ResponsibleSubAreas from 'objects/common/ResponsibleSubAreas/ResponsibleSubAreas'
import AssociatedSubAreas from 'objects/common/AssociatedSubAreas/AssociatedSubAreas'
import Attributes from 'components/Panels/DetailsPanel/Attributes'
import Extremities from 'objects/common/Extremities/Extremities'
import { TrackProtectionGroup } from 'objects/types/protections'
import { CollapsibleMenu } from 'reducers/types'
import { MapState } from 'reducers/map'
import { ExtraKind } from 'objects/types/const'
import { RootState } from 'Store'

import TRACK_PROTECTION_GROUP_ATTRIBUTES from './TrackProtectionGroupAttributes'
import TrackProtectionGroupServices from './TrackProtectionGroupServices'
import Composition from './Composition/Composition'
import './TrackProtectionGroupDetails.scss'

export default function TrackProtectionGroupDetails(): ReactElement | null {
  const { item }: { item: TrackProtectionGroup } = useSelector((state: RootState) => state.detailsPanel)
  const { captureClick } = useSelector((state: RootState): MapState => state.map)

  return (
    <div className="track-protection-group-details object-attributes">
      <Attributes item={item} attributes={TRACK_PROTECTION_GROUP_ATTRIBUTES()} />

      <Divider className="my-2" />

      <div id="linked-objects">
        <Composition trackProtections={item.composition} />
        <Extremities
          type={ExtraKind.TPGExtremity}
          extremities={item.extremities}
          deleteExtremity={TrackProtectionGroupServices.deleteExtremity}
          switchOrientation={TrackProtectionGroupServices.switchExtremityDirection}
        />
        <ResponsibleSubAreas
          responsibleSubAreas={item.responsibleSubAreas}
          deleteRSAAction={TrackProtectionGroupServices.deleteResponsibleSubArea}
          addRSAAction={TrackProtectionGroupServices.addResponsibleSubArea}
        />
        <AssociatedSubAreas
          associatedSubAreas={item.associatedSubAreas}
          deleteASAAction={TrackProtectionGroupServices.deleteAssociatedSubArea}
          addASAAction={TrackProtectionGroupServices.addAssociatedSubArea}
        />
        <LinkedProtections
          linkedProtections={item.incompatibleProtections}
          deleteAction={TrackProtectionGroupServices.deleteIncompatibleObject}
          addIPAction={TrackProtectionGroupServices.addIncompatibleObject}
          type={CollapsibleMenu.incompatibleObjects}
        />
      </div>
      {captureClick !== undefined && <div className="capture-overlay" />}
    </div>
  )
}
