import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { GroupListItem, User } from 'services/cerbereTypes'
import InstructionServices from 'services/InstructionServices'
import UserServices from 'services/UserServices'
import { Instruction } from 'objects/types/instructions'

export interface SupervisorState {
  activeTab: number;
  loading: boolean;
  instructionsList: Instruction[];
  openInstructionsList: Instruction[];
  groups: GroupListItem[];
  usersInfo: User[];
}

const initialState: SupervisorState = {
  activeTab: 0,
  loading: false,
  instructionsList: [],
  openInstructionsList: [],
  groups: [],
  usersInfo: [],
}

export const supervisorSlice = createSlice({
  name: 'supervisor',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload
    },
    openInstruction: (state, action: PayloadAction<Instruction>) => {
      if (state.openInstructionsList.length === 0) {
        state.openInstructionsList.push(action.payload)
      } else {
        state.openInstructionsList = [action.payload]
      }
      state.activeTab = 1
    },
    closeInstruction: (state, action: PayloadAction<string>) => {
      const deletedInstIndex = state.openInstructionsList.map(inst => inst.id).indexOf(action.payload)
      if (deletedInstIndex + 1 === state.activeTab && deletedInstIndex === state.openInstructionsList.length - 1) {
        state.activeTab -= 1
      }
      state.openInstructionsList = state.openInstructionsList.filter(inst => inst.id !== action.payload)
    },
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(InstructionServices.getAll.fulfilled, (state, action) => {
      state.loading = false
      state.instructionsList = action.payload
    })
    builder.addCase(UserServices.getGroups.fulfilled, (state, action) => {
      state.groups = action.payload
    })
    builder.addCase(UserServices.getUsersListInfo.fulfilled, (state, action) => {
      state.usersInfo.push(...action.payload)
    })
    builder.addMatcher(isAnyOf(
      InstructionServices.getAll.pending, InstructionServices.delete.pending,
    ), state => {
      state.loading = true
    })
  },
})

export const {
  openInstruction,
  closeInstruction,
  setActiveTab,
  reset: resetSupervisor,
} = supervisorSlice.actions

export default supervisorSlice.reducer
