import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import GeoEditorService from 'components/GeoEditor/GeoEditorService'
import { Feature } from 'geojson'
import { CaptureMapClickParams } from 'objects/attributes'
import TrackServices from 'objects/Tracks/TrackServices'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'
import IsolatorServices from 'objects/Isolators/IsolatorServices'
import SignalServices from 'objects/Signals/SignalServices'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import { ShortMidiObject } from 'objects/types'
import { ObjectLayer, ExtraLayer } from 'objects/types/const'
import { Subnet } from 'objects/types/subnets'
import { ResponseError } from 'types'

import SubnetServices from 'services/SubnetServices'
import { DEFAULT_SUBNET_SLUG } from 'config/config'
import { deleteFulfilledMatcher } from './matchers/deleteMatchers'
import { createFulfilledMatcher } from './matchers/createMatchers'
import { updateFulfilledMatcher } from './matchers/update'
import allErrorsMatcher from './matchers/matchers'
import { getGeomFulfilledMatcher, getGeomPendingMatcher } from './matchers/getGeomMatchers'

const ALL_EXTRA_LAYERS = [ExtraLayer.Direction, ExtraLayer.LineCode, ExtraLayer.TrackName]
const ALL_OBJECT_LAYERS = [
  ObjectLayer.TrackSection, ObjectLayer.TrackIdentificationSystem, ObjectLayer.Track,
  ObjectLayer.Isolator, ObjectLayer.Signal, ObjectLayer.TrackProtection,
]

export interface MapState {
  isLoading: boolean;
  loadingMessage?: string;
  centeredFeature: Feature | undefined;
  layers: ObjectLayer[];
  layersToUpdate: Array<ObjectLayer | ExtraLayer>;
  hoveredPanelObject?: ShortMidiObject;
  error?: ResponseError;
  captureClick?: CaptureMapClickParams | CaptureMapClickParams[];
  selectedCaptureClickParamsIndex?: number; // used when captureClick is an array
  captureClickIndex?: number; // used for input groups with several capture clicks
  selectedSubnet?: Subnet;
}

export const initialState: MapState = {
  isLoading: false,
  centeredFeature: undefined,
  layers: [ObjectLayer.Track, ObjectLayer.Isolator, ObjectLayer.Signal],
  layersToUpdate: [],
}

const addLayerToUpdate = (state: MapState, layers: Array<ObjectLayer | ExtraLayer>, setLayer = false) => {
  if (setLayer) state.layersToUpdate = layers
  else {
    layers.forEach(layer => {
      if (!state.layersToUpdate.includes(layer)) {
        state.layersToUpdate.push(layer)
      }
    })
  }
}

const updateLoading = (state: MapState, message: string | undefined = undefined) => {
  if (message) {
    state.isLoading = true
    state.loadingMessage = message
  } else {
    state.isLoading = false
    state.loadingMessage = undefined
  }
}

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    reset: () => initialState,
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    updateCenteredFeature: (state, action: PayloadAction<Feature | undefined>) => {
      state.centeredFeature = action.payload
    },
    addLayer: (state, action: PayloadAction<ObjectLayer>) => {
      if (!state.layers.includes(action.payload)) {
        state.layers.push(action.payload)
      }
    },
    removeLayer: (state, action: PayloadAction<ObjectLayer>) => {
      state.layers = state.layers.filter(l => l !== action.payload)
    },
    setLayers: (state, action: PayloadAction<ObjectLayer[]>) => {
      state.layers = action.payload
    },
    setLayersToUpdate: (state, action: PayloadAction<Array<ObjectLayer | ExtraLayer>>) => {
      state.layersToUpdate = action.payload
    },
    setHoveredPanelObject: (state, action: PayloadAction<ShortMidiObject | undefined>) => {
      state.hoveredPanelObject = action.payload
    },
    resetError: state => {
      state.error = undefined
    },
    setError: (state, action: PayloadAction<ResponseError>) => {
      state.error = action.payload
    },
    setCaptureClick: (state, action: PayloadAction<CaptureMapClickParams | CaptureMapClickParams[] | undefined>) => {
      state.captureClick = action.payload
    },
    setSelectedCaptureClickParamsIndex: (state, action: PayloadAction<number | undefined>) => {
      state.selectedCaptureClickParamsIndex = action.payload
    },
    setCaptureClickIndex: (state, action: PayloadAction<number | undefined>) => {
      state.captureClickIndex = action.payload
    },
    setSelectedSubnet: (state, action: PayloadAction<Subnet | undefined>) => {
      state.selectedSubnet = action.payload
      addLayerToUpdate(state, [...ALL_OBJECT_LAYERS, ...ALL_EXTRA_LAYERS], true)
    },
  },
  extraReducers: builder => {
    // GeoEditor
    builder.addCase(GeoEditorService.getTrackSectionGeometry.pending, state => {
      updateLoading(state, 'Map.Loader.switchingToEditor')
    })
    builder.addCase(GeoEditorService.updateTrackSectionGeometry.pending, state => {
      updateLoading(state, 'Map.Loader.updatingGeometry')
    })
    builder.addCase(GeoEditorService.updateTrackSectionGeometry.fulfilled, state => {
      updateLoading(state)
      addLayerToUpdate(state, [ObjectLayer.TrackSection, ObjectLayer.Track, ...ALL_EXTRA_LAYERS], true)
    })
    builder.addCase(GeoEditorService.getTrackSectionGeometry.fulfilled, (state, action) => {
      updateLoading(state)
      state.centeredFeature = action.payload
    })

    builder.addCase(SubnetServices.getAll.fulfilled, (state, action) => {
      state.selectedSubnet = action.payload.find(s => s.slug === DEFAULT_SUBNET_SLUG)
    })

    // Error Handling
    builder.addMatcher(allErrorsMatcher, state => {
      updateLoading(state)
    })

    // CenterFeature
    builder.addMatcher(getGeomFulfilledMatcher, (state, action) => {
      updateLoading(state)
      state.centeredFeature = action.payload
    })
    builder.addMatcher(getGeomPendingMatcher, (state, action) => {
      updateLoading(state, 'Map.Loader.fetchingGeometry')
      state.centeredFeature = action.payload
    })

    // Delete object
    builder.addMatcher(isAnyOf(
      TrackSectionServices.delete.pending,
      TrackServices.delete.pending,
      IsolatorServices.delete.pending,
      SignalServices.delete.pending,
      TrackProtectionServices.delete.pending,
    ), state => {
      updateLoading(state, 'Map.Loader.deletingObject')
    })
    builder.addMatcher(deleteFulfilledMatcher, state => {
      updateLoading(state)
      addLayerToUpdate(state, [...ALL_OBJECT_LAYERS, ...ALL_EXTRA_LAYERS], true)
    })

    // Create Object
    builder.addMatcher(createFulfilledMatcher, state => {
      updateLoading(state)
      state.selectedCaptureClickParamsIndex = undefined
      addLayerToUpdate(state, [...ALL_OBJECT_LAYERS, ...ALL_EXTRA_LAYERS], true)
    })

    // Update Object
    builder.addMatcher(updateFulfilledMatcher, state => {
      updateLoading(state)
      state.selectedCaptureClickParamsIndex = undefined
      addLayerToUpdate(state, [...ALL_OBJECT_LAYERS, ...ALL_EXTRA_LAYERS], true)
    })
  },
})

export const {
  setIsLoading, updateCenteredFeature, addLayer, removeLayer, setLayers, setLayersToUpdate,
  setHoveredPanelObject, setCaptureClick, setCaptureClickIndex, setSelectedCaptureClickParamsIndex,
  reset: resetMap, setSelectedSubnet,
} = mapSlice.actions

export default mapSlice.reducer
