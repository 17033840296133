import { DateTime } from 'luxon'
import { ReactElement } from 'react'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import IconButton from '@mui/material/IconButton'
import SubnetServices from 'services/SubnetServices'
import Tooltip from '@mui/material/Tooltip'
import { store } from 'Store'

const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1)

export const formatDate = (date: string): string => (
  DateTime.fromISO(date).toFormat('dd/MM/yyyy')
)

export const formatRelativeDate = (date: string): string => {
  const relativeDate = DateTime.fromISO(date).toRelativeCalendar({ locale: 'fr' })
  if (relativeDate !== null) return capitalize(relativeDate)
  return formatDate(date)
}

export const formatStatus = (status: string): ReactElement => {
  switch (status) {
    case 'SUCCESS':
      return <CheckCircleOutlinedIcon color="success" />
    case 'PENDING':
      return (
        <Tooltip title="Cliquez pour rafraîchir le statut" arrow>
          <IconButton
            color="warning"
            aria-label="status pending"
            sx={{ padding: 0 }}
            onClick={() => { store.dispatch(SubnetServices.getAll()) }}
          >
            <AccessTimeOutlinedIcon />
          </IconButton>
        </Tooltip>
      )
    case 'FAILURE':
      return <CancelOutlinedIcon color="error" />
    default:
      break
  }
  return <AccessTimeOutlinedIcon color="success" />
}
