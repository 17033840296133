import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@mui/material/Button'

import { InstructionState, setActiveStep, setInstructionType } from 'reducers/instruction'
import { InstructionType } from 'objects/types/instructions'
import { RootState } from 'Store'
import './TypeChoice.scss'

const TYPE_CHOICES = [
  {
    key: 'S6',
    value: InstructionType.S6,
    className: 's6',
  },
  {
    key: 'S9',
    value: InstructionType.S9,
    className: 's9',
  },
  {
    key: 'S11',
    value: InstructionType.S11,
    className: 's11',
  },
]

export default function InstructionTypeChoice(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { activeStep } = useSelector((state: RootState) => state.instruction) as InstructionState

  return (
    <div id="instruction-type-choice" className="d-flex w-100 flex-column h-100">
      <div className="colored-title d-flex justify-content-center align-items-center">
        <div className="w-50 mx-auto">
          <div className="title-wrapper d-flex justify-content-center">
            <h1>{t('Instruction.creation.typeChoice')}</h1>
          </div>
          <div className="d-flex justify-content-around align-items-center w-100">
            {TYPE_CHOICES.map(choice => (
              <Button
                key={choice.key}
                className={`choice-button ${choice.className}`}
                onClick={() => {
                  dispatch(setInstructionType(choice.value))
                  dispatch(setActiveStep(activeStep + 1))
                }}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="blank-footer" />
    </div>
  )
}
