import { isAnyOf } from '@reduxjs/toolkit'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'

export const updateTPLinkedObjectsPendingMatcher = isAnyOf(
  TrackProtectionServices.deleteExtremity.pending,
  TrackProtectionServices.switchExtremityDirection.pending,
  TrackProtectionServices.addResponsibleSubArea.pending,
  TrackProtectionServices.deleteResponsibleSubArea.pending,
  TrackProtectionServices.addAssociatedSubArea.pending,
  TrackProtectionServices.deleteAssociatedSubArea.pending,
  TrackProtectionServices.addIncompatibleObject.pending,
  TrackProtectionServices.deleteIncompatibleObject.pending,
)

export const updateTPLinkedObjectsFulfilledMatcher = isAnyOf(
  TrackProtectionServices.switchExtremityDirection.fulfilled,
  TrackProtectionServices.deleteExtremity.fulfilled,
  TrackProtectionServices.addResponsibleSubArea.fulfilled,
  TrackProtectionServices.deleteResponsibleSubArea.fulfilled,
  TrackProtectionServices.addAssociatedSubArea.fulfilled,
  TrackProtectionServices.deleteAssociatedSubArea.fulfilled,
  TrackProtectionServices.addIncompatibleObject.fulfilled,
  TrackProtectionServices.deleteIncompatibleObject.fulfilled,
)

export const updateTPLinkedObjectsErrorMatcher = isAnyOf(
  TrackProtectionServices.switchExtremityDirection.rejected,
  TrackProtectionServices.deleteExtremity.rejected,
  TrackProtectionServices.addResponsibleSubArea.rejected,
  TrackProtectionServices.deleteResponsibleSubArea.rejected,
  TrackProtectionServices.addAssociatedSubArea.rejected,
  TrackProtectionServices.deleteAssociatedSubArea.rejected,
  TrackProtectionServices.addIncompatibleObject.rejected,
  TrackProtectionServices.deleteIncompatibleObject.rejected,
)
