import { useTranslation } from '@osrdata/app_core/dist/translation'
import getCSSValue from 'helpers'
import { ReactElement } from 'react'

type Props = {
  code: number;
}

const style = {
  backgroundColor: getCSSValue('--color-white'),
}

const getErrorMessage = (code: number): string => {
  switch (code) {
    case 403:
      return 'Error.forbidden.content'
    default:
      return 'Error.default'
  }
}

export default function ErrorOverlay({ code }: Props): ReactElement {
  const { t } = useTranslation()
  return (
    <div style={style} className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
      <div className="m-2 text-center">{t(getErrorMessage(code))}</div>
      <div>{`(Erreur ${code})`}</div>
    </div>
  )
}
