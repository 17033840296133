import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import Divider from '@mui/material/Divider'

import Attributes from 'components/Panels/DetailsPanel/Attributes'
import Extremities from 'objects/common/Extremities/Extremities'
import { ElectricalElement } from 'objects/types/protections'
import LinkedProtections from 'objects/common/LinkedProtections/LinkedProtections'
import ResponsibleSubAreas from 'objects/common/ResponsibleSubAreas/ResponsibleSubAreas'
import AssociatedSubAreas from 'objects/common/AssociatedSubAreas/AssociatedSubAreas'
import { ExtraKind } from 'objects/types/const'
import { RootState } from 'Store'

import { CollapsibleMenu } from 'reducers/types'
import ELECTRICAL_ATTRIBUTES from './ElectricalElementAttributes'
import ElectricalElementServices from './ElectricalElementServices'

export default function ElectricalElementDetails(): ReactElement | null {
  const { item: electricalElement }: { item: ElectricalElement } = useSelector((state: RootState) => state.detailsPanel)
  const {
    extremities, responsibleSubAreas, associatedSubAreas, incompatibleProtections,
  } = electricalElement
  return (
    <div id="electrical-element-details" className="object-attributes">
      <Attributes item={electricalElement} attributes={ELECTRICAL_ATTRIBUTES()} />
      <Divider className="my-2" />
      <div id="linked-objects">
        <Extremities
          type={ExtraKind.EEExtremity}
          extremities={extremities}
          deleteExtremity={ElectricalElementServices.deleteExtremity}
          switchOrientation={ElectricalElementServices.switchExtremityDirection}
        />
        <ResponsibleSubAreas
          responsibleSubAreas={responsibleSubAreas}
          deleteRSAAction={ElectricalElementServices.deleteResponsibleSubArea}
          addRSAAction={ElectricalElementServices.addResponsibleSubArea}
        />
        <AssociatedSubAreas
          associatedSubAreas={associatedSubAreas}
          deleteASAAction={ElectricalElementServices.deleteAssociatedSubArea}
          addASAAction={ElectricalElementServices.addAssociatedSubArea}
        />
        <LinkedProtections
          linkedProtections={incompatibleProtections}
          deleteAction={ElectricalElementServices.deleteIncompatibleObject}
          addIPAction={ElectricalElementServices.addIncompatibleObject}
          type={CollapsibleMenu.incompatibleObjects}
        />
      </div>
    </div>
  )
}
