import ButtonStatus from 'components/Common/buttonStatus'

const SAVE_BUTTON_TEXT = {
  [ButtonStatus.Base]: 'Modification.button.base',
  [ButtonStatus.Disabled]: 'Modification.button.base',
  [ButtonStatus.Loading]: 'Modification.button.loading',
  [ButtonStatus.Success]: 'Modification.button.success',
}

export default SAVE_BUTTON_TEXT
