import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import 'components/ObjectItem/ObjectItem.scss'
import SimpleButton, {
  ButtonStyle,
} from 'components/Common/SimpleButton/SimpleButton'
import { ElectricalElement } from 'objects/types/protections'
import ObjectTile from 'components/ObjectItem/ObjectTile'
import { DetailsPanelState, updateItem, shouldRefresh } from 'reducers/panels/detailsPanel'
import { disableGeoEditor, hideGeoEditor } from 'reducers/geoEditor'
import PanelNavigator from 'components/Panels/PanelNavigator'
import { PanelName } from 'reducers/panels/panel'
import { CaptureMapClickParams } from 'objects/attributes'
import { ObjectLayer } from 'objects/types/const'
import { setCaptureClick, setLayers } from 'reducers/map'
import { setMessage } from 'reducers/feedback'
import { CollapsibleMenu } from 'reducers/types'
import { RootState } from 'Store'
import toggleOpenCollapsible from 'objects/common/utils'
import CompositionContextMenu from './CompositionContextMenu'

type Props = {
  electricalElements: ElectricalElement[];
};

const captureClickParams: CaptureMapClickParams = {
  updateParams: [
    {
      clickedObjectPath: 'id',
      path: ['composition'],
      addElement: true,
    },
  ],
  objectLayer: ObjectLayer.ElectricalElement,
}

export default function Composition({
  electricalElements,
}: Props): ReactElement | null {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isCapturingClick, setIsCapturingClick] = useState(false)
  const { openMenus } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)

  if (!electricalElements) return null

  const onClick = () => {
    if (!isCapturingClick) {
      setIsCapturingClick(true)
      dispatch(hideGeoEditor())
      dispatch(setCaptureClick(captureClickParams))
      dispatch(setLayers([captureClickParams.objectLayer]))
      dispatch(
        setMessage(
          'Modification.helperText.electricalProtectionGroupCaptureClick',
        ),
      )
    } else {
      setIsCapturingClick(false)
      dispatch(setCaptureClick(undefined))
    }
  }

  return (
    <Accordion
      disableGutters
      expanded={openMenus.includes(CollapsibleMenu.composition)}
      onChange={() => toggleOpenCollapsible(CollapsibleMenu.composition)}
    >
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
        {`${t('Attributes.ElectricalProtectionGroup.composition')} (${
          electricalElements && electricalElements.length
        })`}
      </AccordionSummary>
      <AccordionDetails>
        {electricalElements
          && electricalElements.map(ee => (
            <ObjectTile
              key={ee.id}
              onClick={() => {
                dispatch(disableGeoEditor())
                dispatch(updateItem(ee))
                dispatch(shouldRefresh(true))
                PanelNavigator.push(PanelName.details)
              }}
              title={ee.label}
              subtitle={t('Object.type.electricalElement')}
              contextMenu={<CompositionContextMenu ee={ee} />}
            />
          ))}
        <div className="my-2 capture-button">
          <SimpleButton
            title={t('Details.button.addElectricalProtectionToGroup')}
            onClick={onClick}
            style={ButtonStyle.light}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
