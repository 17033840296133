import { feature } from '@turf/helpers'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { useSelector } from 'react-redux'
import MapGL, { MapEvent, MapRef } from 'react-map-gl'
import { Editor } from 'react-map-gl-draw'

import { snapOnHover } from 'components/GeoEditor/helpers/snapping'
import MapLoader from 'components/Map/MapLoader'
import HoverPopup from 'components/Map/Hover/HoverPopup'
import GeoEditor from 'components/GeoEditor/GeoEditor'
import { Modes } from 'components/GeoEditor/utils'

import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import { MapState } from 'reducers/map'
import { GeoEditorState } from 'reducers/geoEditor'
import { InstructionState } from 'reducers/instruction'
import { RootState } from 'Store'

import FeedbackSnackBar from '../Common/FeedbackSnackBar/FeedbackSnackBar'
import ObjectLayers from './ObjectLayers/ObjectLayers'
import {
  transformRequest, DEFAULT_VIEWPORT, centerMap, refreshTiles,
  addImagesToInstance, MAX_ZOOM, INVALID_GEOM_TYPES, FeatureClickEvent,
} from './utils'
import ContextMenu from './ContextMenu/ContextMenu'
import CreateButton from './CreateButton'
import Toolbar from './Toolbar/Toolbar'
import mapStyle from './style_empty.json'
import './Map.scss'
import FeatureClickPopup from './FeatureClick/FeatureClickPopup'
import onFeatureClick from './onFeatureClick'

export default function Map(): ReactElement {
  const mapRef = useRef<MapRef>(null)
  const {
    isLoading, centeredFeature, layers, layersToUpdate, loadingMessage, selectedSubnet,
  } = useSelector((state: RootState): MapState => state.map)
  const { active: showGeoEditor, mode } = useSelector((state: RootState): GeoEditorState => state.TIVEditor)
  const { instruction } = useSelector((state: RootState): InstructionState => state.instruction)
  const { item } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)
  const geoEditorRef = useRef<Editor>(null)
  const [viewport, setViewport] = useState(DEFAULT_VIEWPORT)
  const [hoveredEvent, setHoveredEvent] = useState<MapEvent | undefined>(undefined)
  const [clickEvent, setClickEvent] = useState<FeatureClickEvent | undefined>(undefined)
  const [contextHoveredEvent, setContextHoveredEvent] = useState<MapEvent | undefined>(undefined)
  const [contextEvent, setContextEvent] = useState<MapEvent | undefined>(undefined)
  const [disableHover, setDisableHover] = useState(false)
  const [disableScroll, setDisableScroll] = useState(false)

  useEffect(() => {
    if (mapRef.current) {
      addImagesToInstance(mapRef.current)
      if (instruction.geom) {
        centerMap(feature(instruction.geom), viewport, setViewport, mapRef.current, 0)
      }
    }
  }, [instruction.geom])

  useEffect(() => {
    refreshTiles(mapRef)
  }, [layersToUpdate])

  useEffect(() => {
    if (centeredFeature && centeredFeature.geometry
      && !INVALID_GEOM_TYPES.includes(centeredFeature.geometry.type) && mapRef.current) {
      centerMap(centeredFeature, viewport, setViewport, mapRef.current)
    }
  }, [centeredFeature])

  useEffect(() => {
    setClickEvent(undefined)
  }, [item])

  useEffect(() => {
    refreshTiles(mapRef)
  }, [selectedSubnet])

  const onHover = (e: MapEvent) => {
    // Hover used for snapping in GeoEditor
    if (showGeoEditor) snapOnHover(e, geoEditorRef)
    // Store event when not in GeoEditor
    else if (e.features && e.features.length === 1 && !disableHover) {
      setHoveredEvent(e)
    } else {
      setHoveredEvent(undefined)
    }
  }

  const onClick = (e: MapEvent) => {
    e.preventDefault()
    if (!contextEvent) {
      if (e.features?.length === 1) onFeatureClick(e.features[0])
      else setClickEvent(e as FeatureClickEvent)
    }
  }

  const onContextMenu = (e: MapEvent) => {
    e.preventDefault()
    if (!showGeoEditor) {
      setHoveredEvent(undefined)
      setContextEvent(e)
      setContextHoveredEvent(hoveredEvent)
    }
  }

  const enableDragAndRotate = !showGeoEditor || (showGeoEditor && mode === Modes.grab)

  return (
    <div className="map-wrapper position-relative">
      <MapGL
        {...viewport}
        ref={mapRef}
        transformRequest={transformRequest}
        maxZoom={MAX_ZOOM}
        width="100%"
        height="100%"
        mapStyle={mapStyle}
        onViewportChange={(newViewport: typeof DEFAULT_VIEWPORT) => { setViewport(newViewport) }}
        onHover={onHover}
        onMouseOut={() => setHoveredEvent(undefined)}
        onContextMenu={onContextMenu}
        onClick={onClick}
        clickRadius={10}
        interactiveLayerIds={showGeoEditor
          ? [...layers.map(l => `${l}-layer`), 'snapping-points', 'snapping-lines']
          : layers.map(l => `${l}-layer`)}
        preventStyleDiffing
        dragPan={enableDragAndRotate}
        dragRotate={enableDragAndRotate}
        scrollZoom={!disableScroll}
      >
        <ObjectLayers hoveredEvent={hoveredEvent} />
        {!clickEvent && (
          <HoverPopup event={hoveredEvent} />
        )}
        {clickEvent && (
          <FeatureClickPopup event={clickEvent} setEvent={setClickEvent} />
        )}
        {contextEvent && (
          <ContextMenu
            event={contextEvent}
            reset={() => setContextEvent(undefined)}
            hoveredEvent={contextHoveredEvent}
            setMapHover={setHoveredEvent}
          />
        )}
        <GeoEditor geoEditorRef={geoEditorRef} mapRef={mapRef} />
        <Toolbar disableHover={setDisableHover} disableScroll={setDisableScroll} />
      </MapGL>
      <CreateButton />
      {isLoading && <MapLoader message={loadingMessage} />}
      <FeedbackSnackBar />
    </div>
  )
}
