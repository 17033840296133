import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from '@osrdata/app_core/dist/translation'
// import get from 'lodash/get'

import Snackbar from '@mui/material/Snackbar'

import { GeoEditorState } from 'reducers/geoEditor'
import { RootState } from 'Store'
import { CreationPanelState, setButtonStatus } from 'reducers/panels/creationPanel'
import ButtonStatus from 'components/Common/buttonStatus'

export default function TrackSectionCreationSnackBar(): ReactElement | null {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { object, active: isGeoEditorActive } = useSelector((state: RootState): GeoEditorState => state.TIVEditor)
  const { newObject } = useSelector((state: RootState): CreationPanelState => state.creationPanel)
  const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>(undefined)

  const updateMessage = () => {
    if (!object) {
      setSnackbarMessage('Veuillez dessiner la géométrie du tronçon')
      dispatch(setButtonStatus(ButtonStatus.Disabled))
    } else {
      setSnackbarMessage(undefined)
      dispatch(setButtonStatus(ButtonStatus.Base))
    }
  }

  useEffect(() => {
    updateMessage()
  }, [])

  useEffect(() => {
    updateMessage()
  }, [object, newObject, isGeoEditorActive])

  return (
    <Snackbar
      open={snackbarMessage !== undefined}
      onClose={() => {}}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      message={t(snackbarMessage || '')}
    />
  )
}
