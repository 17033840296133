import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement } from 'react'

import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'

import './SimpleSearchBar.scss'

type Props = {
  value: string;
  onChange: (s: string) => void;
  rightIcon?: ReactElement | null;
  onRightIconClick?: () => void;
}

const defaultProps = {
  rightIcon: null,
  onRightIconClick: () => {},
}

export default function SearchBar({
  value, onChange, rightIcon, onRightIconClick,
}: Props): ReactElement {
  const { t } = useTranslation()
  return (
    <div className="simple-search-bar">
      <TextField
        variant="standard"
        placeholder={t('Instruction.creation.editList.searchPlaceholder')}
        value={value}
        fullWidth
        onChange={e => onChange(e.target.value)}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <div className="start-icon-wrapper">
              <SearchIcon className="start-icon" />
            </div>
          ),
          endAdornment: (
            <IconButton hidden={!rightIcon} onClick={onRightIconClick}>
              {rightIcon}
            </IconButton>
          ),
        }}
      />
    </div>
  )
}

SearchBar.defaultProps = defaultProps
