import { store } from 'Store'

const ADMIN_PERMISSIONS = 'midi-next::ADMIN'
const SUPERVISOR_PERMISSIONS = 'midi-next::SUPERVISOR'

export const isAdmin = (): boolean => {
  const { appPermissions } = store.getState().user

  return appPermissions.includes(ADMIN_PERMISSIONS)
}

export const isSupervisor = (): boolean => {
  const { appPermissions } = store.getState().user

  return appPermissions.includes(SUPERVISOR_PERMISSIONS) || isAdmin()
}
