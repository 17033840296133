import { get } from 'lodash'

import { CreationPanelState, setNewObjectField } from 'reducers/panels/creationPanel'
import { MapState } from 'reducers/map'
import { setModificationItemField } from 'reducers/panels/modificationPanel'
import { enableGeoEditor, GeoEditorState } from 'reducers/geoEditor'
import { PanelName } from 'reducers/panels/panel'
import { FieldValue } from 'reducers/types'
import { MidiObject } from 'objects/types'
import { ElectricalElement, TrackProtection } from 'objects/types/protections'
import { ObjectKind, ObjectLayer } from 'objects/types/const'
import { LAYER_NAME } from 'objects/attributes'
import { fetchSignalDetails } from 'objects/Signals/SignalServices'
import { store } from 'Store'

import { getActivePanel } from 'helpers'
import getClickedObjectDetails from '../getObjectDetails'
import {
  cleanCaptureClick, formatPath, getSelectedCaptureClickParam, onCaptureElectricalElement, onCaptureTrackProtection,
} from './helpers'
import { MapLayerObject } from '../utils'

const onFeatureClick = async (e: MapLayerObject): Promise<void> => {
  const { captureClick, captureClickIndex } = store.getState().map as MapState
  const { newObjectKind } = store.getState().creationPanel as CreationPanelState
  const { active: geoEditorActive } = store.getState().TIVEditor as GeoEditorState

  if (geoEditorActive) return

  if (e) {
    const object: MidiObject = e.properties
    const layer: ObjectLayer = e.sourceLayer
    if (captureClick !== undefined) {
      if (layer === ObjectLayer.TrackProtection) {
        onCaptureTrackProtection(object as TrackProtection)
      } else if (layer === ObjectLayer.ElectricalElement) {
        onCaptureElectricalElement(object as ElectricalElement)
      } else {
        let usedObject = object
        if (layer === ObjectLayer.Signal) {
          usedObject = await fetchSignalDetails(object.id)
        }

        // Handle if there is an array of params
        const { updateParams } = getSelectedCaptureClickParam(captureClick, layer)

        // Choose the right update function, either in Modification or Creation panel
        const updateFunction = getActivePanel() === PanelName.modification
          ? setModificationItemField
          : setNewObjectField

        // For each param, update the value inside the object
        updateParams.forEach(p => {
          let value: FieldValue = p.clickedObjectPath !== undefined ? get(usedObject, p.clickedObjectPath) : usedObject
          // Store the layer value if the param asks for it
          if (p.clickedObjectPath === LAYER_NAME) value = layer
          const formattedPath = formatPath(captureClickIndex, p.path)
          store.dispatch(updateFunction({ path: formattedPath, value }))
        })

        cleanCaptureClick()

        // Show geoEditor again if Track Section creation
        if (newObjectKind === ObjectKind.TrackSection) store.dispatch(enableGeoEditor())
      }
    } else if (getActivePanel() !== PanelName.modification) {
      getClickedObjectDetails(object, layer)
    }
  }
}

export default onFeatureClick
