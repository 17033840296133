import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import Attributes from 'components/Panels/DetailsPanel/Attributes'
import { Signal } from 'objects/types'
import { RootState } from 'Store'

import SIGNAL_ATTRIBUTES from './SignalAttributes'

export default function SignalDetails(): ReactElement | null {
  const { item: signal }: { item: Signal} = useSelector((state: RootState) => state.detailsPanel)
  return (
    <div id="signal-details" className="object-attributes">
      <Attributes item={signal} attributes={SIGNAL_ATTRIBUTES()} />
    </div>
  )
}
