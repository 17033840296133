import { setGeoJson } from '@osrdata/geoeditor/dist/reducer'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'

import ContextMenu from 'components/Common/ContextMenu/ContextMenu'
import { SubnetState, toggleShowLinearization } from 'reducers/subnet'
import { AdminState } from 'reducers/admin'
import Loader from 'components/Common/Loader'
import SubnetServices from 'services/SubnetServices'
import { RootState } from 'Store'
import { COLUMNS, SUBNET_CONTEXT_MENU } from './const'
import './SubnetsTable.scss'
import SubnetModificationModal from './SubnetModificationModal'
import Linearisation from '../SubnetCreation/Linearisation'

export default function SubnetsTable(): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { subnetsList: list, usersInfo, loading } = useSelector((state: RootState) => state.admin) as AdminState
  const { showLinearization, displayCreation } = useSelector((state: RootState) => state.subnet) as SubnetState

  useEffect(() => {
    if (!displayCreation) {
      dispatch(SubnetServices.getAll())
    }
  }, [displayCreation])

  const toggleDisplayLinearization = () => {
    dispatch(toggleShowLinearization())
    if (showLinearization) dispatch(setGeoJson(undefined))
  }

  if (loading) {
    return (
      <div className="h-100 w-100">
        <Loader />
      </div>
    )
  }

  return (
    <div className="subnets-dashboard">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {COLUMNS.map(column => (
                <TableCell key={column.title}>{t(column.title)}</TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map(subnet => (
              <TableRow
                key={subnet.id}
                hover
                className="instruction-row"
              >
                {COLUMNS.map(column => (
                  <TableCell
                    key={column.title}
                  >
                    {column.formatter(subnet[column.propertyName], usersInfo)}
                  </TableCell>
                ))}
                <TableCell><ContextMenu menuItems={SUBNET_CONTEXT_MENU(subnet)} /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SubnetModificationModal onClickLinearize={toggleDisplayLinearization} />
      {showLinearization && <Linearisation onClose={toggleDisplayLinearization} absolute />}
    </div>
  )
}
