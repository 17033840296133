import { useSelector } from 'react-redux'
import { ReactElement } from 'react'

import ActionButton from 'components/Common/ActionButton/ActionButton'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import ButtonStatus from 'components/Common/buttonStatus'
import { ObjectStatus } from 'objects/types/const'
import MidiRole from 'services/midiRoleTypes'
import { MidiObject } from 'objects/types'
import { RootState } from 'Store'
import { getValidateButtonText, ValidationOperation } from '../utils'

type Props = {
  onValidate: (o: string) => void;
}

export default function SupervisorButtons({ onValidate }: Props): ReactElement {
  const { item, buttonStatus } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)

  const typedItem = item as MidiObject
  if (typedItem.status !== undefined && typedItem.status !== ObjectStatus.VALIDATED) {
    return (
      <ActionButton
        title={getValidateButtonText(MidiRole.supervisor)}
        onClick={() => {}}
        status={typedItem.status > ObjectStatus.VALIDATED ? ButtonStatus.Success : ButtonStatus.Disabled}
      />
    )
  }

  return (
    <>
      <ActionButton
        title={getValidateButtonText(MidiRole.supervisor, Boolean(typedItem.deletedAt))}
        onClick={() => onValidate(ValidationOperation.verify)}
        status={buttonStatus}
      />
      <div className="my-2" />
      <ActionButton
        title={getValidateButtonText()}
        onClick={() => onValidate(ValidationOperation.refuse)}
        status={buttonStatus}
      />
    </>
  )
}
