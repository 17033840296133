import { Group, GroupListItem, Role } from 'services/cerbereTypes'
import UserServices from 'services/UserServices'
import { store } from 'Store'

const GROUP_NAME = 'midi-next'

const getRoleGroup = (groups: Group[], role: Role): Group | undefined => {
  let roleGroup = groups
    .find(group => group.name.toLowerCase().includes(role.toLowerCase()))
  // return id if we found a matching group
  if (roleGroup !== undefined) return roleGroup

  // if not, search in children if there are any
  let i = 0
  while (i < groups.length) {
    if (groups[i].children.length > 0) {
      roleGroup = getRoleGroup(groups[i].children, role)
      if (roleGroup !== undefined) {
        break
      }
    }
    i += 1
  }

  return roleGroup
}

const getRoleGroupId = (groups: GroupListItem[], appName: string, role: Role): string | undefined => {
  const appNameGroup = groups.find(group => group.service_name === appName)
  if (appNameGroup) {
    const roleGroup = getRoleGroup(appNameGroup.groups, role)
    // return id if we found a matching group
    if (roleGroup !== undefined) return roleGroup.id
  }
  return undefined
}

export const getOperators = (groups: GroupListItem[]): void => {
  const operatorGroupId = getRoleGroupId(groups, GROUP_NAME, Role.operator)
  if (operatorGroupId) {
    store.dispatch(UserServices.getGroupMembers({
      id: operatorGroupId, role: Role.operator,
    }))
  }
}

export const getSupervisors = (groups: GroupListItem[]): void => {
  const supervisorGroupId = getRoleGroupId(groups, GROUP_NAME, Role.supervisor)
  if (supervisorGroupId) {
    store.dispatch(UserServices.getGroupMembers({
      id: supervisorGroupId, role: Role.supervisor,
    }))
  }
}

export const getAdministrators = (groups: GroupListItem[]): void => {
  const administratorGroupId = getRoleGroupId(groups, GROUP_NAME, Role.admin)
  if (administratorGroupId) {
    store.dispatch(UserServices.getGroupMembers({
      id: administratorGroupId, role: Role.admin,
    }))
  }
}
