import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import Divider from '@mui/material/Divider'

import TrackIdentificationSystemsList from 'objects/TrackIdentificationSystems/TISList/TISList'
import Attributes from 'components/Panels/DetailsPanel/Attributes'
import { Track } from 'objects/types'
import { RootState } from 'Store'

import TRACK_ATTRIBUTES from './TrackAttributes'

export default function TrackDetails(): ReactElement | null {
  const { item: track }: { item: Track } = useSelector((state: RootState) => state.detailsPanel)
  return (
    <div id="track-details" className="object-attributes">
      <Attributes item={track} attributes={TRACK_ATTRIBUTES()} />

      <Divider className="mb-2 mt-4" />

      <TrackIdentificationSystemsList />
    </div>
  )
}
