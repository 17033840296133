import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import TrackIdentificationSystemServices from 'objects/TrackIdentificationSystems/TISServices'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'

export interface TisListState {
  isLoading: boolean;
}

const initialState: TisListState = {
  isLoading: false,
}

export const tisListSlice = createSlice({
  name: 'tisList',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
  extraReducers: builder => {
    builder.addMatcher(isAnyOf(
      TrackSectionServices.update.fulfilled, TrackSectionServices.update.rejected,
      TrackIdentificationSystemServices.delete.fulfilled, TrackIdentificationSystemServices.delete.rejected,
    ), state => {
      state.isLoading = false
    })
  },
})

export const { setLoading: setTisListLoading } = tisListSlice.actions

export default tisListSlice.reducer
