import { useTranslation } from '@osrdata/app_core/dist/translation'
import { useDispatch } from 'react-redux'
import { ReactElement } from 'react'

import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp'
import { SxProps } from '@mui/material'

import SimpleButton from 'components/Common/SimpleButton/SimpleButton'
import { resetInstruction } from 'reducers/instruction'
import getCSSValue from 'helpers'
import './SelectionFulfilled.scss'

const continueButtonStyle: SxProps = {
  backgroundColor: 'white',
  color: getCSSValue('--color-blue-900'),
  maxWidth: '360px',
  borderRadius: '23px',
  boxShadow: 'none',
  paddingTop: '12px',
}

export default function SelectionFulfilled(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <div id="selection-fulfilled" className="blue-background w-100 h-100">
      <div className="w-100 d-flex justify-content-center algin-items-center h-100 flex-column text-center">
        <div className="pb-2">
          <CheckCircleSharpIcon className="check-icon" />
        </div>
        <div>
          <h2>
            {t('Instruction.creation.userAssignation.fulfilled')}
          </h2>
        </div>
      </div>
      <div className="button-wrapper d-flex justify-content-around align-items-center w-100 mb-4">
        <div className="w-75 d-flex">
          <SimpleButton
            customStyle={continueButtonStyle}
            title={t('Instruction.button.returnToHome')}
            onClick={() => { dispatch(resetInstruction()) }}
          />
        </div>
      </div>
    </div>
  )
}
