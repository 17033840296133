import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import MidiRole from 'services/midiRoleTypes'

export interface AppState {
  error: null | undefined;
  fullscreen: boolean;
  role: MidiRole;
}

const initialState: AppState = {
  error: null,
  fullscreen: false,
  role: MidiRole.operator,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleFullscreen: state => {
      state.fullscreen = !state.fullscreen
    },
    getError: (state, action: PayloadAction<null>) => {
      state.error = action.payload
    },
    setRole: (state, action: PayloadAction<MidiRole>) => {
      state.role = action.payload
    },
  },
})

export const { toggleFullscreen, getError, setRole } = appSlice.actions

export default appSlice.reducer
