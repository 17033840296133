import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from '@osrdata/app_core/dist/translation'

import {
  resetButtonStatus, resetModification, setModificationError, setModificationItemField, updateModificationItem,
} from 'reducers/panels/modificationPanel'
import { RootState, store } from 'Store'

import ActionButton from 'components/Common/ActionButton/ActionButton'
import ButtonStatus from 'components/Common/buttonStatus'
import ErrorOverlay from 'components/Common/ErrorOverlay'
import { AttributesOfKind, UpdateServiceOfKind } from 'objects/services'
import { FieldValue } from 'reducers/types'
import { ObjectKind } from 'objects/types/const'
import { parseStringFieldValue } from 'objects/utils'
import { Attribute } from 'objects/attributes'
import { findObjectKind } from 'objects/kind'
import PanelTemplate from '../PanelTemplate/PanelTemplate'
import PanelFieldsContent from '../PanelFieldsContent'
import SAVE_BUTTON_TEXT from './utils'
import PanelNavigator from '../PanelNavigator'
import './ModificationPanel.scss'

export default function ModificationPanel(): ReactElement | null {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { item } = useSelector((state: RootState) => state.detailsPanel)
  const {
    buttonStatus, item: updatedItem, error, isLoading,
  } = useSelector(
    (state: RootState) => state.modificationPanel,
  )
  const [attributes, setAttributes] = useState<Array<Attribute>>([])
  const [kind, setKind] = useState<ObjectKind>(ObjectKind.TrackSection)
  const [errorByKey, setErrorByKey] = useState<{[key: string]: boolean}>({})
  const [displayError, setDisplayError] = useState(false)

  const updateLocalItem = () => {
    if (item !== undefined) {
      let newKind
      // Check if updatedItem has not been set manually (if different from details item for example)
      if (Object.keys(updatedItem).length !== 0) {
        newKind = findObjectKind(updatedItem)
      } else {
        dispatch(updateModificationItem(item))
        newKind = findObjectKind(item)
      }
      setKind(newKind)
      setAttributes(AttributesOfKind(store)[newKind])
    }
  }

  useEffect(() => {
    updateLocalItem()
  }, [])

  useEffect(() => {
    // if at least one field has an error, set modification Error to true
    dispatch(setModificationError(!Object.values(errorByKey).every(v => v === false)))
  }, [errorByKey])

  useEffect(() => {
    if (error && error.code !== 400) setDisplayError(true)
  }, [error])

  const handleError = (key: string, value: boolean) => {
    setErrorByKey({
      ...errorByKey,
      [key]: value,
    })
  }

  const onChange = (value: FieldValue, field: Attribute) => {
    const path = field.updatePath || field.path
    if (buttonStatus !== ButtonStatus.Base) {
      dispatch(resetButtonStatus())
    }
    dispatch(setModificationItemField({ value: parseStringFieldValue(value), path }))
  }

  const onReturn = () => {
    dispatch(resetModification())
    PanelNavigator.goBack()
  }

  const onSave = () => {
    dispatch(UpdateServiceOfKind[kind](updatedItem))
  }

  const renderContent = () => (displayError
    ? <ErrorOverlay code={error.code} />
    : (
      <PanelFieldsContent
        attributes={attributes.filter(a => a.isEditable)}
        handleError={handleError}
        item={updatedItem}
        responseError={error}
        onChange={onChange}
        isLoading={isLoading}
      />
    ))

  return (
    <PanelTemplate
      id="modification-panel"
      onClickReturn={onReturn}
      headerContent={(<h2 className="w-100 m-0 mt-1">{t('Modification.title')}</h2>)}
      footerButton={displayError
        ? undefined
        : <ActionButton title={SAVE_BUTTON_TEXT} onClick={onSave} status={buttonStatus} />}
    >
      {renderContent()}
    </PanelTemplate>
  )
}
