import { RefObject } from 'react'
import { MapRef } from 'react-map-gl'
import { polygon } from '@turf/helpers'
import { Polygon } from 'geojson'

import { GeoEditorFeature } from '../types'

// Gives the Polygon envelopping  what the user is currently seeing on the map
export const getBbox = (mapRef: RefObject<MapRef>): Polygon | undefined => {
  if (mapRef.current) {
    const { _ne: ne, _sw: sw } = mapRef.current.getMap().getBounds()
    const windowBbox = polygon([[
      [sw.lng, sw.lat],
      [ne.lng, sw.lat],
      [ne.lng, ne.lat],
      [sw.lng, ne.lat],
      [sw.lng, sw.lat],
    ]])
    return windowBbox.geometry
  }
  return undefined
}

// Delete a point from an object, given its index in the coordinates array, and returns the new object
export const deletePointInGeometry = (object: GeoEditorFeature, indexToRemove: number): GeoEditorFeature => {
  const { coordinates } = object.geometry
  const newCoordinates = coordinates.length > 1
    ? coordinates.filter((_c, i) => i !== indexToRemove)
    : coordinates
  return {
    ...object,
    geometry: {
      ...object.geometry,
      coordinates: newCoordinates,
    },
  }
}
