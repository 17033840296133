/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Area } from 'objects/types/protections'
import { ThunkApiConfig } from 'types'
import URI from '../uri'
import {
  addSubArea, getAllSubAreas, updateSubArea, deleteSubArea,
} from './subareas'

const getAll = createAsyncThunk<Area[], string | undefined, ThunkApiConfig>(
  'area/getAll',
  async (search, thunkApi) => {
    try {
      const response = await get(`/midi-next/v2/${URI.areas}/`, {
        search: search || '',
      })
      return response.results as Area[]
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<Area, Partial<Area>, ThunkApiConfig>(
  'area/create',
  async (newArea, thunkApi) => {
    try {
      const response: Area = await post(`/midi-next/v2/${URI.areas}/`, newArea)
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<Area, Partial<Area>, ThunkApiConfig>(
  'area/updateById',
  async (updatedArea, thunkApi) => {
    try {
      const response: Area = await patch(`/midi-next/v2/${URI.areas}/${updatedArea.id}`, updatedArea)
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'area/deleteById',
  async (id, thunkApi) => {
    try {
      await deleteRequest(`/midi-next/v2/${URI.areas}/${id}`)
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const AreaServices = {
  getAll,
  create,
  update,
  delete: deleteObject,
  addSubArea,
  updateSubArea,
  getAllSubAreas,
  deleteSubArea,
}

export default AreaServices
