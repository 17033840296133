import { useTranslation } from '@osrdata/app_core/dist/translation'
import { Fragment, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import Button from '@mui/material/Button'
import Step from '@mui/material/Step'

import FeedbackSnackBar from 'components/Common/FeedbackSnackBar/FeedbackSnackBar'
import {
  InstructionState, resetInstruction, setActiveStep, setActiveSubStep,
} from 'reducers/instruction'
import { RootState } from 'Store'
import InstructionTypeChoice from './TypeChoice'
import InstructionNameChoice from './NameChoice'
import InstructionRefChoice from './RefChoice'
import ItemsSelection from '../ItemsSelection/ItemsSelection'
import UserAttribution from '../UserAttribution'
import './InstructionCreation.scss'

export const CREATION_STEPS = [
  {
    activeStep: 0,
    view: <InstructionTypeChoice />,
  },
  {
    activeStep: 1,
    view: <InstructionNameChoice
      title="Instruction.creation.nameChoice.title"
      subtitle="Instruction.creation.nameChoice.subtitle"
    />,
  },
  {
    activeStep: 2,
    view: <InstructionRefChoice />,
  },
  {
    activeStep: 3,
    view: <ItemsSelection
      selectMapTitle="Instruction.creation.itemSelection.title"
      selectMapSubtitle="Instruction.creation.itemSelection.subtitle"
    />,
  },
  {
    activeStep: 4,
    view: <UserAttribution />,
  },
]

type Props = {
  steps?: typeof CREATION_STEPS;
} & typeof defaultProps

const defaultProps = {
  steps: CREATION_STEPS,
}

export default function InstructionCreation({ steps }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { activeStep } = useSelector((state: RootState) => state.instruction) as InstructionState

  const onReturn = () => {
    if (activeStep === 0) dispatch(resetInstruction())
    else {
      dispatch(setActiveStep(activeStep - 1))
      dispatch(setActiveSubStep(0))
    }
  }

  const onClose = () => {
    dispatch(resetInstruction())
  }

  const getBackButtonText = () => (activeStep === 0
    ? 'Instruction.button.returnToHome'
    : 'Instruction.button.returnToPreviousStep')

  return (
    <>
      <div id="object-creation">
        <div className="w-100 stepper-bar">
          <div className="return-wrapper">
            <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={onReturn}>
              <span>{t(getBackButtonText())}</span>
            </Button>
          </div>
          <div className="stepper-wrapper">
            <Stepper activeStep={activeStep}>
              {steps.map(step => (
                <Step key={step.activeStep}>
                  <StepLabel />
                </Step>
              ))}
            </Stepper>
          </div>
          {activeStep > 2 && (
          <div className="close-wrapper">
            <IconButton onClick={onClose}>
              <CloseIcon className="close-icon" />
            </IconButton>
          </div>
          )}
        </div>
        {steps.map(step => (activeStep === step.activeStep
          ? (
            <Fragment key={step.activeStep}>
              {step.view}
            </Fragment>
          )
          : null))}
      </div>
      <FeedbackSnackBar />
    </>
  )
}

InstructionCreation.defaultProps = defaultProps
