import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from '@osrdata/app_core/dist/translation'

import Button from '@mui/material/Button'
import { CircularProgress } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'

import { ObjOfStr } from 'types'
import ButtonStatus from '../buttonStatus'
import './ActionButton.scss'

type Props = {
  title: ObjOfStr;
  onClick: () => void;
  status?: ButtonStatus;
} & typeof defaultProps

const defaultProps = {
  status: ButtonStatus.Base,
}

type Param = {
  disabled: boolean;
  leftIcon: ReactElement | null;
  className?: string;
}

const STATUS_PARAMS: {[key: string]: Param} = {
  [ButtonStatus.Base]: {
    disabled: false,
    leftIcon: null,
  },
  [ButtonStatus.Loading]: {
    disabled: true,
    leftIcon: <CircularProgress size={20} className="mx-2" style={{ color: 'black' }} />,
    className: 'loading',
  },
  [ButtonStatus.Success]: {
    disabled: true,
    leftIcon: <DoneIcon className="mx-2" />,
    className: 'success',
  },
  [ButtonStatus.Disabled]: {
    disabled: true,
    leftIcon: null,
  },
}

export default function ActionButton({
  title, onClick, status,
}: Props): ReactElement {
  const [params, setParams] = useState<Param>(STATUS_PARAMS[status])
  const { t } = useTranslation()

  useEffect(() => {
    setParams(STATUS_PARAMS[status])
  }, [status])

  return (
    <div className={`main-button d-flex w-100 justify-content-center ${params.className || ''}`}>
      <Button
        className={`main-button ${params.className || ''}`}
        variant="contained"
        size="large"
        onClick={onClick}
        disabled={params.disabled}
      >
        {params?.leftIcon}
        {t(title[status])}
      </Button>
    </div>
  )
}

ActionButton.defaultProps = defaultProps
