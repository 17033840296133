import Attributes from 'components/Panels/DetailsPanel/Attributes'
import AssociatedSubAreas from 'objects/common/AssociatedSubAreas/AssociatedSubAreas'
import Extremities from 'objects/common/Extremities/Extremities'
import LinkedProtections from 'objects/common/LinkedProtections/LinkedProtections'
import ResponsibleSubAreas from 'objects/common/ResponsibleSubAreas/ResponsibleSubAreas'
import { ExtraKind } from 'objects/types/const'
import { Feeder } from 'objects/types/protections'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'

import Divider from '@mui/material/Divider'

import { CollapsibleMenu } from 'reducers/types'
import FEEDER_ATTRIBUTES from './FeederAttributes'
import FeederServices from './FeederServices'

export default function FeederDetails(): ReactElement | null {
  const { item: feeder }: { item: Feeder } = useSelector((state: RootState) => state.detailsPanel)

  const {
    extremities, responsibleSubAreas, associatedSubAreas, incompatibleProtections,
  } = feeder

  return (
    <div id="feeders-details" className="object-attributes">
      <Attributes item={feeder} attributes={FEEDER_ATTRIBUTES()} />
      <Divider className="my-2" />
      <div id="linked-objects">
        <Extremities
          type={ExtraKind.FeederExtremity}
          extremities={extremities}
          deleteExtremity={FeederServices.deleteExtremity}
        />
        <ResponsibleSubAreas
          responsibleSubAreas={responsibleSubAreas}
          deleteRSAAction={FeederServices.deleteResponsibleSubArea}
          addRSAAction={FeederServices.addResponsibleSubArea}
        />
        <AssociatedSubAreas
          associatedSubAreas={associatedSubAreas}
          deleteASAAction={FeederServices.deleteAssociatedSubArea}
          addASAAction={FeederServices.addAssociatedSubArea}
        />
        <LinkedProtections
          linkedProtections={incompatibleProtections}
          deleteAction={FeederServices.deleteIncompatibleObject}
          addIPAction={FeederServices.addIncompatibleObject}
          type={CollapsibleMenu.incompatibleObjects}
        />
      </div>

    </div>
  )
}
