import { NULL_STRING } from '../const'

export enum UserTrackProtectionType {
  LINE = 'Ligne',
  STATION = 'Gare',
  NOT_ALONE = 'ZE'
}

export enum TrackProtectionType {
  LINE = 'LINE',
  STATION = 'STATION',
  NOT_ALONE = 'NOT_ALONE'
}

export const TrackProtectionTypeMap = [
  {
    label: UserTrackProtectionType.LINE,
    value: TrackProtectionType.LINE,
  },
  {
    label: UserTrackProtectionType.STATION,
    value: TrackProtectionType.STATION,
  },
  {
    label: UserTrackProtectionType.NOT_ALONE,
    value: TrackProtectionType.NOT_ALONE,
  },
]

export enum UserMGPTType {
  NO_MGPT = 'Pas de MGPT',
  MGPT_0 = 'Niveau 0',
  MGPT_1 = 'Niveau 1',
  MGPT_2 = 'Niveau 2',
}

export const MGPTType = {
  NO_MGPT: NULL_STRING,
  MGPT_0: 0,
  MGPT_1: 1,
  MGPT_2: 2,
}

export const MGPTTypeMap = [
  {
    label: UserMGPTType.NO_MGPT,
    value: MGPTType.NO_MGPT,
  },
  {
    label: UserMGPTType.MGPT_0,
    value: MGPTType.MGPT_0,
  },
  {
    label: UserMGPTType.MGPT_1,
    value: MGPTType.MGPT_1,
  },
  {
    label: UserMGPTType.MGPT_2,
    value: MGPTType.MGPT_2,
  },
]

export enum UserWorkTrainsType {
  ALLOWED = 'Autorisé',
  NOT_ALLOWED = 'Non Autorisé',
}

export enum BooleanType {
  True = 'true',
  False = 'false',
}

export const WorkTrainsTypeMap = [
  {
    label: UserWorkTrainsType.ALLOWED,
    value: BooleanType.True,
  },
  {
    label: UserWorkTrainsType.NOT_ALLOWED,
    value: BooleanType.False,
  },
]

export enum UserTrackProtectionGroupType {
  LINE = 'Ligne',
  STATION = 'Gare',
  BOTH = 'Les deux'
}

export enum TrackProtectionGroupType {
  LINE = 'LINE',
  STATION = 'STATION',
  BOTH = 'BOTH'
}

export const TrackProtectionGroupTypeMap = [
  {
    label: UserTrackProtectionGroupType.LINE,
    value: TrackProtectionGroupType.LINE,
  },
  {
    label: UserTrackProtectionGroupType.STATION,
    value: TrackProtectionGroupType.STATION,
  },
  {
    label: UserTrackProtectionGroupType.BOTH,
    value: TrackProtectionGroupType.BOTH,
  },
]

export enum UserBooleeanType {
  True = 'Possible',
  False = 'Impossible',
}

export const BooleanTypeMap = [
  {
    label: UserBooleeanType.True,
    value: BooleanType.True,
  },
  {
    label: UserBooleeanType.False,
    value: BooleanType.False,
  },
]
