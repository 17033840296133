import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useEffect, useState } from 'react'
import debounce from 'lodash/debounce'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'

import FeedbackSnackBar from 'components/Common/FeedbackSnackBar/FeedbackSnackBar'
import Loader from 'components/Common/Loader'
import AreaServices from 'services/AreaServices'
import {
  AreasState, ModalMode, setModalMode, toggleOpenModal,
} from 'reducers/areas'
import { RootState, store } from 'Store'
import SearchAreas from './SearchAreas'
import AreaTile from './AreaTile'
import { MODE_BUTTON_ACTION, MODE_BUTTON_TEXT, MODE_TITLE } from './const'
import AreaModal from './AreaModal'
import './AreasTab.scss'

const debouncedGetAll = debounce((s: string) => store.dispatch(AreaServices.getAll(s)), 500)

export default function AreasTab(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const { modal: { area, mode }, areas, loading } = useSelector((state: RootState) => state.areas) as AreasState

  useEffect(() => {
    dispatch(AreaServices.getAll())
  }, [])

  const onSearch = (s: string) => {
    setSearch(s)
    debouncedGetAll(s)
  }

  return (
    <div id="areas-tab" className="d-flex flex-column w-100 p-4">
      <div className="d-flex justify-content-between align-items-center">
        <SearchAreas value={search} onChange={onSearch} />
        <div className="button-wrapper">
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              dispatch(setModalMode(ModalMode.creation))
              dispatch(toggleOpenModal())
            }}
          >
            {t('Areas.newAreaButton')}
          </Button>
        </div>
      </div>
      {loading ? <Loader /> : (
        <div className="d-flex mt-4 overflow-auto">
          <Grid container spacing={3}>
            {areas.map(a => (
              <Grid item key={a.label} md={3} sm={6} xs={12}>
                <AreaTile area={a} />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      <AreaModal
        title={MODE_TITLE[mode]}
        onButtonClick={() => MODE_BUTTON_ACTION[mode](area)}
        buttonText={MODE_BUTTON_TEXT[mode]}
      />
      <FeedbackSnackBar />
    </div>
  )
}
