import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement } from 'react'

import BigLoader from 'components/Common/BigLoader'
import getCSSValue from 'helpers'

export enum Colors {
  blue = 'blue',
  purple = 'purple'
}

type Props = {
  message: string;
  color?: Colors;
} & typeof defaultProps

const defaultProps = {
  color: Colors.blue,
}

export default function ColorLoading({ message, color }: Props): ReactElement {
  const { t } = useTranslation()

  return (
    <div
      className="w-100 h-100 d-flex justify-content-center align-items-center flex-column"
      style={{
        backgroundColor: color === Colors.blue ? getCSSValue('--color-blue-900') : getCSSValue('--color-purple-500'),
      }}
    >
      <BigLoader />
      <h3>{t(message)}</h3>
    </div>
  )
}

ColorLoading.defaultProps = defaultProps
