import { RENDER_STATE, SHAPE } from 'react-map-gl-draw'
import { ObjOfStrOrNum } from 'types'
import { FeatureStyleType } from './types'

const FILL_COLOR = '#CFCFCF'
const STROKE_COLOR = '#CFCFCF'
const SELECTED_FILL_COLOR = '#000'

const CIRCLE_RADIUS = 6
const CIRCLE_FILL_COLOR = '#FFF'

const DEFAULT_STYLE = {
  stroke: '#000',
  strokeWidth: 2,
  fill: FILL_COLOR,
  fillOpacity: 0.1,
}

const SELECTED_STYLE = {
  stroke: STROKE_COLOR,
  strokeWidth: 3,
  fill: FILL_COLOR,
  fillOpacity: 0.3,
}

const UNCOMMITTED_STYLE = {
  stroke: CIRCLE_FILL_COLOR,
  strokeWidth: 4,
  fill: CIRCLE_FILL_COLOR,
  fillOpacity: 0.2,
}

const INACTIVE_STYLE = UNCOMMITTED_STYLE

export const getEditHandleStyle = ({
  shape, state,
}: FeatureStyleType): ObjOfStrOrNum => {
  let style: ObjOfStrOrNum = {}
  switch (state) {
    case RENDER_STATE.SELECTED:
      style = { ...SELECTED_STYLE, fill: SELECTED_FILL_COLOR }
      break
    case RENDER_STATE.INACTIVE:
      style = { ...INACTIVE_STYLE }
      break
    case RENDER_STATE.UNCOMMITTED:
    case RENDER_STATE.CLOSING:
      style = { ...UNCOMMITTED_STYLE }
      break
    default:
      style = { ...DEFAULT_STYLE }
  }

  switch (shape) {
    case SHAPE.CIRCLE:
      style.r = CIRCLE_RADIUS
      break
    default:
  }

  return style
}

export const getFeatureStyle = ({ feature, state }: FeatureStyleType): ObjOfStrOrNum | null => {
  const type = feature.properties?.shape || feature.geometry.type
  let style: ObjOfStrOrNum | null = null

  switch (state) {
    case RENDER_STATE.SELECTED:
      style = { ...SELECTED_STYLE, fill: SELECTED_FILL_COLOR }
      break
    default:
      style = { ...DEFAULT_STYLE }
  }

  switch (type) {
    case SHAPE.POINT:
      style.r = CIRCLE_RADIUS
      break
    case SHAPE.LINE_STRING:
      style.fill = 'none'
      break
    default:
      break
  }

  return style
}
