/* eslint-disable @typescript-eslint/no-explicit-any */
import { patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ElectricalElement, ElectricalProtectionGroup } from 'objects/types/protections'
import { ThunkApiConfig } from 'types'
import { store } from 'Store'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import ObjectURI from 'objects/uri'

export const addElectricalElement = createAsyncThunk<ElectricalProtectionGroup, ElectricalElement, ThunkApiConfig>(
  'electricalProtectionGroup/addElectricalElement',
  async (electricalElementToAdd, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, composition } = detailsPanelState.item as ElectricalProtectionGroup

    const updatedElectricalProtectionGroup = {
      id,
      checksum,
      composition: [...composition, {
        id: electricalElementToAdd.id,
      }],
    }

    try {
      const response: ElectricalProtectionGroup = await patch(
        `/midi-next/v2/${ObjectURI.ElectricalProtectionGroups}/${updatedElectricalProtectionGroup.id}`,
        updatedElectricalProtectionGroup,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteElectricalElement = createAsyncThunk<ElectricalProtectionGroup, string, ThunkApiConfig>(
  'electricalProtectionGroup/deleteElectricalElement',
  async (deletedEeId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, composition } = detailsPanelState.item as ElectricalProtectionGroup

    const updatedElectricalProtectionGroup: Partial<ElectricalProtectionGroup> = {
      id,
      checksum,
      composition: composition.filter(ee => ee.id !== deletedEeId),
    }

    try {
      const response: ElectricalProtectionGroup = await patch(
        `/midi-next/v2/${ObjectURI.ElectricalProtectionGroups}/${updatedElectricalProtectionGroup.id}`,
        updatedElectricalProtectionGroup,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
