import { AllGeoJSON, featureCollection, Properties } from '@turf/helpers'
import { Feature, FeatureCollection, LineString } from 'geojson'
import {
  ReactElement, RefObject, useEffect, useState,
} from 'react'
import { useSelector } from 'react-redux'
import { Layer, MapRef, Source } from 'react-map-gl'
import explode from '@turf/explode'

import { GeoEditorState } from 'reducers/geoEditor'
import { ObjOfStrOrNum } from 'types'
import { RootState } from 'Store'

import { updateSnappingData } from './utils'

type Props = {
  mapRef: RefObject<MapRef>;
}

export default function SnappingLayer({ mapRef }: Props): ReactElement {
  const { snappingFeatures } = useSelector((state: RootState): GeoEditorState => state.TIVEditor)
  const [
    snappingFeatureCollection, setSnappingFeatureCollection,
  ] = useState<AllGeoJSON>(featureCollection(snappingFeatures as Feature<LineString, Properties>[]))
  const [zoom, setZoom] = useState<number>(mapRef.current?.getMap().getZoom())
  const [center, setCenter] = useState<ObjOfStrOrNum>(mapRef.current?.getMap().getCenter())

  useEffect(() => {
    updateSnappingData(mapRef)
  }, [])

  useEffect(() => {
    if (zoom !== mapRef.current?.getMap().getZoom()) {
      setZoom(mapRef.current?.getMap().getZoom())
    }
    if (JSON.stringify(center) !== JSON.stringify(mapRef.current?.getMap().getCenter())) {
      setCenter(mapRef.current?.getMap().getCenter())
    }
  }, [mapRef.current?.getMap().getZoom(), mapRef.current?.getMap().getCenter()])

  useEffect(() => {
    updateSnappingData(mapRef)
  }, [zoom, center])

  useEffect(() => {
    setSnappingFeatureCollection(featureCollection(snappingFeatures as Feature<LineString, Properties>[]))
  }, [snappingFeatures])

  return (
    <>
      <Source
        id="snapping-lines-source"
        type="geojson"
        data={snappingFeatureCollection as FeatureCollection}
      >
        <Layer
          id="snapping-lines"
          type="line"
          paint={{
            'line-color': 'hsla(0, 100%, 50%, 0.001)',
            'line-width': 10 * 2,
          }}
        />
      </Source>
      <Source
        id="snapping-points-source"
        type="geojson"
        data={explode(snappingFeatureCollection)}
      >
        <Layer
          id="snapping-points"
          type="circle"
          paint={{
            'circle-color': 'hsla(0, 100%, 50%, 0.001)',
            'circle-radius': 3,
          }}
        />
      </Source>
    </>
  )
}
