import {
  useEffect, ReactElement, useState, Suspense,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Router, Switch, Route, Redirect,
} from 'react-router-dom'
import { auth } from '@osrdata/app_core'

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import CircularProgress from '@mui/material/CircularProgress'

import { RootState } from 'Store'
import TopBar from 'components/TopBar/TopBar'
import history from 'customHistory'
import 'App.scss'
import Loader from 'components/Common/Loader'
import { isAdmin, isSupervisor } from 'helpers/permissions'
import {
  ADMIN_HOMEPATH, ADMIN_ROUTES, OPERATOR_HOMEPATH, OPERATOR_ROUTES, RouteType, SUPERVISOR_HOMEPATH, SUPERVISOR_ROUTES,
} from 'Routes'
import AccessDenied from 'AccessDenied'

const APP_NAME = 'Midi'

const ACCESS_PERM = 'midi-next::ACCESS'

export default function App(): ReactElement {
  const dispatch = useDispatch()
  const { isLogged, appPermissions } = useSelector((state: RootState) => state.user)
  const [isLoading, setIsLoading] = useState(true)
  const [homePath, setHomePath] = useState<string | undefined>(undefined)
  const [routes, setRoutes] = useState<Array<RouteType> | undefined>(undefined)
  const [accessDenied, setAccessDenied] = useState(false)

  const getUserParams = (): void => {
    switch (true) {
      case isAdmin():
        setHomePath(ADMIN_HOMEPATH)
        setRoutes(ADMIN_ROUTES)
        break
      case isSupervisor():
        setHomePath(SUPERVISOR_HOMEPATH)
        setRoutes(SUPERVISOR_ROUTES)
        break
      default:
        setHomePath(OPERATOR_HOMEPATH)
        setRoutes(OPERATOR_ROUTES)
        break
    }
  }

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0) {
      getUserParams()
      if (!appPermissions.includes(ACCESS_PERM)) {
        setAccessDenied(true)
      }
      setIsLoading(false)
    }
  }, [isLogged, appPermissions])

  const displayApp = !isLoading && homePath && routes

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterLuxon} locale="fr">
        <Suspense fallback={<CircularProgress />}>
          <TopBar appName={APP_NAME} />
          {accessDenied ? <AccessDenied /> : (
            <div className="d-flex app-container">
              {!displayApp && <Loader message="Chargement" />}
              {displayApp && (
              <Router history={history}>
                <Switch>
                  {routes.map(route => (
                    <Route exact key={route.path} component={route.component} path={route.path} />
                  ))}
                  <Redirect to={homePath} />
                </Switch>
              </Router>
              )}
            </div>
          )}
        </Suspense>
      </LocalizationProvider>
    </>
  )
}
