import { ReactElement } from 'react'

import { Area } from 'objects/types/protections'
import ContextMenu, { ContextMenuItem } from 'components/Common/ContextMenu/ContextMenu'
import { store } from 'Store'
import { GenericAction } from 'types'

const ASA_CONTEXT_MENU_ITEMS = (rsa: Area, deleteAction: GenericAction): ContextMenuItem[] => [
  {
    key: 'delete',
    label: 'Details.subAreaContextMenu.deleteAssociated',
    onClick: () => { store.dispatch(deleteAction(rsa.id)) },
    show: true,
  },
]

type Props = {
  asa: Area;
  deleteAction: GenericAction;
}

export default function asaContextMenu({ asa, deleteAction }: Props): ReactElement {
  return <ContextMenu menuItems={ASA_CONTEXT_MENU_ITEMS(asa, deleteAction)} />
}
