import { MidiObject } from 'objects/types'
import { AppDispatch } from 'Store'
import { ObjOfStrOrNum } from 'types'

export type DispatchFunction = (dispatch: AppDispatch) => void

export type FieldValue = string | number | ObjOfStrOrNum | null | MidiObject | ObjOfStrOrNum[] | boolean;

export interface UpdateField {
  path: Array<string> | string;
  value: FieldValue;
}

export enum CollapsibleMenu {
  composition = 'composition',
  children = 'children',
  extremities = 'extremities',
  responsibleSubAreas = 'responsibleSubAreas',
  associatedSubAreas = 'associatedAreas',
  incompatibleObjects = 'incompatibleObjects',
}
