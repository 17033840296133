import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement } from 'react'

import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import './ItemListSearch.scss'

type Props = {
  value: string;
  onChange: (s: string) => void;
}

export default function ItemListSearch({ value, onChange }: Props): ReactElement {
  const { t } = useTranslation()

  return (
    <div id="item-list-search" className="w-25 mx-auto">
      <TextField
        variant="standard"
        placeholder={t('Instruction.creation.editList.searchPlaceholder')}
        value={value}
        fullWidth
        onChange={e => onChange(e.target.value)}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <div className="start-icon-wrapper">
              <SearchIcon className="start-icon" />
            </div>
          ),
        }}
      />
    </div>
  )
}
