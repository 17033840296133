import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'

import { ObjectLayer } from 'objects/types/const'
import { MapIconNames } from 'components/Map/icons'
import { LayerProps } from 'objects/types'
import { formatLayerUrl } from 'objects/utils'

const sourceLayer = ObjectLayer.Feeder

export default function FeedersLayer({ visible }: LayerProps): ReactElement {
  return (
    <Source
      type="vector"
      url={formatLayerUrl(sourceLayer)}
      source-layer={sourceLayer}
      id={sourceLayer}
    >
      <Layer
        id={`${sourceLayer}-layer`}
        type="symbol"
        layout={{
          'icon-size': ['step', ['zoom'],
            0, 11,
            0.2,
          ],
          'icon-image': MapIconNames.feeder,
          visibility: visible ? 'visible' : 'none',
          'icon-allow-overlap': true,
        }}
        paint={{}}
        source-layer={sourceLayer}
        minzoom={12}
        filter={['==', ['get', 'deletedAt'], null]}
      />
    </Source>
  )
}
