import { ReactElement, ReactNode } from 'react'

import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import ListItem from '@mui/material/ListItem'

import './ObjectItem.scss'

type Props = {
  onClick: () => void;
  title: string;
  subtitle?: string | undefined;
  contextMenu: ReactNode;
}

const defaultProps = {
  subtitle: undefined,
}

export default function ObjectTile({
  onClick, title, subtitle, contextMenu,
}: Props): ReactElement {
  return (
    <ListItem
      secondaryAction={contextMenu}
      className="list-item-wrapper elevation-2 my-2 pl-0"
      disablePadding
    >
      <ListItemButton
        role={undefined}
        onClick={onClick}
      >
        <ListItemText>
          <div className="list-text">{title}</div>
          {subtitle && (<div className="list-subtitle">{subtitle}</div>)}
        </ListItemText>
      </ListItemButton>
    </ListItem>
  )
}

ObjectTile.defaultProps = defaultProps
