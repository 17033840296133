import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useState } from 'react'

import MoreHorizTwoToneIcon from '@mui/icons-material/MoreHorizTwoTone'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'

import './ContextMenu.scss'

export type ContextMenuItem = {
  key: string;
  label: string;
  onClick: () => void;
  show?: boolean;
}

type Props = {
  menuItems: ContextMenuItem[];
}

export default function ContextMenu({ menuItems }: Props): ReactElement {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (next?: () => void) => {
    setAnchorEl(null)
    if (next !== undefined) next()
  }

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls={open ? 'context-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizTwoToneIcon />
      </IconButton>
      <Menu
        className="context-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        open={open}
        onClose={() => handleClose()}
      >
        {menuItems.filter(i => i.show).map(item => (
          <MenuItem key={item.key} onClick={() => handleClose(item.onClick)}>
            {t(item.label)}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
