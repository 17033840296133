import { ReactElement } from 'react'

import { Area } from 'objects/types/protections'
import ContextMenu, { ContextMenuItem } from 'components/Common/ContextMenu/ContextMenu'
import { store } from 'Store'
import { GenericAction } from 'types'

const RSA_CONTEXT_MENU_ITEMS = (
  rsa: Area, deleteAction: GenericAction,
): ContextMenuItem[] => [
  {
    key: 'delete',
    label: 'Details.subAreaContextMenu.deleteResponsible',
    onClick: () => { store.dispatch(deleteAction(rsa.id)) },
    show: true,
  },
]

type Props = {
  rsa: Area;
  deleteAction: GenericAction;
}

export default function RSAContextMenu({ rsa, deleteAction }: Props): ReactElement {
  return <ContextMenu menuItems={RSA_CONTEXT_MENU_ITEMS(rsa, deleteAction)} />
}
