import { useTranslation } from '@osrdata/app_core/dist/translation'
import { AsyncThunkAction } from '@reduxjs/toolkit'
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import { ShortProtection } from 'objects/types/protections'
import ObjectTile from 'components/ObjectItem/ObjectTile'
import SearchBar, { SearchSelectedValueType } from 'components/Common/SearchBar/SearchBar'
import { GenericAction, ThunkApiConfig } from 'types'
import 'components/ObjectItem/ObjectItem.scss'
import { ITEM_TYPES } from 'objects/types/const'
import PanelNavigator from 'components/Panels/PanelNavigator'
import { setLoading } from 'reducers/panels/detailsPanel'
import { PanelName } from 'reducers/panels/panel'
import { findObjectKind } from 'objects/kind'
import { GetDetailsServiceOfKind, GetGeometryServiceOfKind } from 'objects/services'
import { PROTECTIONS_SEARCH_URI } from 'objects/uri'
import { RootState } from 'Store'
import { CollapsibleMenu } from 'reducers/types'
import IPContextMenu from './LPContextMenu'
import toggleOpenCollapsible from '../utils'

type Props = {
  linkedProtections: ShortProtection[];
  deleteAction: GenericAction;
  addIPAction: (sp: ShortProtection) => AsyncThunkAction<unknown, ShortProtection, ThunkApiConfig>;
  type: CollapsibleMenu;
  params?: {
    title: string;
    searchPlaceholder: string;
    buttonLabel: string;
    filteredTypes?: ITEM_TYPES[];
  };
} & typeof defaultProps

const defaultProps = {
  params: {
    title: 'Attributes.TrackProtection.incompatibleObjects',
    searchPlaceholder: 'Details.searchPlaceholder.protection',
    buttonLabel: 'Details.button.addIncompatibleObject',
  },
}
const labelFormatter = (sp: SearchSelectedValueType): string => {
  if (sp !== undefined && sp.label !== undefined) {
    const label = `${sp.label}`
    return label
  }
  return ''
}

export default function LinkedProtections({
  linkedProtections, deleteAction, addIPAction, params, type,
}: Props): ReactElement | null {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [displaySearch, setDisplaySearch] = useState(false)
  const { item, openMenus } = useSelector((state: RootState) => state.detailsPanel)

  const usedIP = linkedProtections || []

  const onCloseSearch = (value: SearchSelectedValueType | undefined = undefined) => {
    setDisplaySearch(false)
    if (value !== undefined) dispatch(addIPAction(value as ShortProtection))
  }

  const getSearchParams = () => (params.filteredTypes
    ? {
      instance_of: params.filteredTypes,
    }
    : undefined
  )

  return (
    <Accordion
      disableGutters
      expanded={openMenus.includes(type)}
      onChange={() => toggleOpenCollapsible(type)}
    >
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
        {`${t(params.title)} (${
          usedIP && usedIP.length
        })`}
      </AccordionSummary>
      <AccordionDetails>
        {usedIP && usedIP.map(sp => (
          <ObjectTile
            key={sp.id}
            onClick={() => {
              const kind = findObjectKind(sp)
              dispatch(setLoading(true))
              dispatch(GetDetailsServiceOfKind[kind](sp.id))
              PanelNavigator.push(PanelName.details)
              dispatch(GetGeometryServiceOfKind[kind](sp.id))
            }}
            title={sp.mainRepresentation || ''}
            subtitle={sp.secondaryRepresentation || ''}
            contextMenu={<IPContextMenu deleteAction={deleteAction} sp={sp} />}
          />
        ))}
        <div className="my-2">
          {displaySearch
            ? (
              <SearchBar
                onSelect={onCloseSearch}
                searchURI={`/midi-next/v2/${PROTECTIONS_SEARCH_URI}/`}
                placeholder={t(params.searchPlaceholder)}
                labelFormatter={(sp: SearchSelectedValueType) => labelFormatter(sp)}
                filteredIds={[item.id] as string[]}
                params={getSearchParams()}
              />
            )
            : (
              <>
                <SimpleButton
                  title={t(params.buttonLabel)}
                  onClick={() => { setDisplaySearch(true) }}
                  style={ButtonStyle.light}
                />
              </>
            )}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

LinkedProtections.defaultProps = defaultProps
