import { ReactElement, ReactNode } from 'react'

import PanelHeader from '../PanelHeader'
import './PanelTemplate.scss'

type Props = {
  id: string;
  onClickReturn: () => void;
  headerContent: ReactNode;
  children: ReactNode;
  footerButton: ReactElement | undefined;
}

export default function PanelTemplate({
  id, onClickReturn, headerContent, children, footerButton,
}: Props): ReactElement {
  return (
    <div className="panel d-flex flex-column" id={id}>
      <PanelHeader onClickReturn={onClickReturn}>
        {headerContent}
      </PanelHeader>

      <div className="content-wrapper d-flex flex-column position-relative">
        {children}

        {footerButton && (
          <div className="button-wrapper px-4">
            {footerButton}
          </div>
        )}
      </div>
    </div>
  )
}
