import { InstructionType, LayersByInstructionType } from 'objects/types/instructions'
import { ReactElement } from 'react'
import { MapEvent, Popup } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { InstructionState } from 'reducers/instruction'
import { RootState } from 'Store'

import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import { useTranslation } from '@osrdata/app_core/dist/translation'

import CONTEXT_MENU_ITEMS from './contextMenuItems'
import './ContextMenu.scss'

type Props = {
  event: MapEvent;
  reset: () => void;
  hoveredEvent: MapEvent | undefined;
  setMapHover: (e: MapEvent | undefined) => void;
}

// TODO: Put show state in Redux
export default function ContextMenu({
  event, reset, hoveredEvent, setMapHover,
}: Props): ReactElement | null {
  const { t } = useTranslation()
  const { instruction } = useSelector((state: RootState): InstructionState => state.instruction)
  const coords = event.lngLat
  const object = hoveredEvent && hoveredEvent.features
    ? hoveredEvent.features[0]
    : undefined

  const handleMenuClick = (next?: () => void) => {
    if (next !== undefined) next()
    setTimeout(reset, 300)
  }

  return (
    <Popup
      longitude={coords[0]}
      latitude={coords[1]}
      closeButton={false}
      className="popup-wrapper"
      tipSize={0}
      anchor="top-left"
      captureScroll
      captureClick
      capturePointerMove
      onPointerMove={() => setMapHover(undefined)}
      onClose={() => reset()}
    >
      <Paper className="popup">
        <List className="list">
          {CONTEXT_MENU_ITEMS(object)
            .filter(i => i.show)
            .filter(i => (
              i.layer === undefined || LayersByInstructionType[instruction.type as InstructionType].includes(i.layer)
            ))
            .map(item => (
              <ListItemButton className="d-flex" key={item.key} onClick={() => handleMenuClick(item.onClick)} dense>
                <ListItemIcon className="icon">
                  {item.icon}
                </ListItemIcon>
                <ListItemText>
                  <div className="label">{t(item.label)}</div>
                </ListItemText>
              </ListItemButton>
            ))}
        </List>
      </Paper>
    </Popup>
  )
}
