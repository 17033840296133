import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ArrowForward from '@mui/icons-material/ArrowForward'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'

import FeedbackSnackBar from 'components/Common/FeedbackSnackBar/FeedbackSnackBar'
import UserServices from 'services/UserServices'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import { ReactComponent as MapIcon } from 'assets/icons/map.svg'
import { ReactComponent as GroupIcon } from 'assets/icons/group.svg'
import { AdminState } from 'reducers/admin'
import { RootState } from 'Store'
import getCSSValue from 'helpers'

import GeneralInfo from './GeneralInfo'
import Attributions from './Attributions'
import { getAdministrators, getOperators, getSupervisors } from '../utils'
import NewPerimeter from './NewPerimeter'
import './Parameters.scss'

const TABS = (displayNewPerimeter: (show: boolean) => void) => [
  {
    key: 0,
    title: 'Instruction.dashboard.parameters.generalInfo',
    icon: InfoIcon,
    component: (
      <GeneralInfo />
    ),
  },
  {
    key: 1,
    title: 'Instruction.dashboard.parameters.perimeter',
    icon: MapIcon,
    component: (
      <NewPerimeter onClose={() => displayNewPerimeter(false)} />
    ),
  },
  {
    key: 2,
    title: 'Instruction.dashboard.parameters.attributions',
    icon: GroupIcon,
    component: (
      <Attributions />
    ),
  },
]

type Props = {
  onClose: () => void;
}

export default function Parameters({ onClose }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState(0)
  const { groups } = useSelector((state: RootState) => state.admin) as AdminState
  const [showNewPerimeterFlow, setShowNewPerimeterFlow] = useState(false)

  useEffect(() => {
    dispatch(UserServices.getGroups())
  }, [])

  useEffect(() => {
    getOperators(groups)
    getSupervisors(groups)
    getAdministrators(groups)
  }, [groups])

  useEffect(() => {
    setShowNewPerimeterFlow(selectedTab === 1)
  }, [selectedTab])

  useEffect(() => {
    if (!showNewPerimeterFlow) setSelectedTab(0)
  }, [showNewPerimeterFlow])

  return (
    <>
      <div className="instruction-parameters">
        <Paper className="content-wrapper">
          <div className="title-wrapper">
            <div className="title">
              {t('Instruction.dashboard.parameters.title')}
            </div>
            <div>
              <Button variant="outlined" endIcon={<ArrowForward />} onClick={onClose} className="return-button">
                <span>{t('Instruction.dashboard.parameters.close')}</span>
              </Button>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            {TABS(setShowNewPerimeterFlow).map((tab, i) => (
              <Button
                key={tab.key}
                className={`tab ${i === selectedTab ? 'selected' : ''}`}
                onClick={() => setSelectedTab(i)}
              >
                <div className="icon-wrapper">
                  <tab.icon fill={i === selectedTab ? 'white' : getCSSValue('--color-blue-500')} />
                </div>
                <div className="title">
                  {t(tab.title)}
                </div>
              </Button>
            ))}
          </div>
          <div className="content">
            {TABS(setShowNewPerimeterFlow)[selectedTab].component}
          </div>
        </Paper>
      </div>
      <FeedbackSnackBar />
    </>
  )
}
