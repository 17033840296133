/* eslint-disable @typescript-eslint/no-explicit-any */
import { patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Feeder } from 'objects/types/protections'
import { nestedObject, ObjOfStrOrNum, ThunkApiConfig } from 'types'
import { store } from 'Store'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import ExtremityCreation from 'objects/common/Extremities/types'
import ObjectURI from 'objects/uri'

export const addExtremity = createAsyncThunk<Feeder, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'feeder/addExtremity',
  async (newExtremity, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, extremities } = detailsPanelState.item as Feeder
    const typedNewExtremity = newExtremity as unknown as ExtremityCreation

    const kp = typedNewExtremity.kilometricPoints[0]

    const formattedNewExtremity = {
      trackIdentificationSystem: kp.trackIdentificationSystem,
      value: kp.value,
    }

    const updatedFeeder = {
      id,
      checksum,
      extremities: [
        ...extremities,
        formattedNewExtremity,
      ],
    }

    try {
      const response: Feeder = await patch(
        `/midi-next/v2/${ObjectURI.Feeders}/${updatedFeeder.id}`,
        updatedFeeder,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteExtremity = createAsyncThunk<Feeder, number, ThunkApiConfig>(
  'feeder/deleteExtremityById',
  async (deletedExtremityId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, extremities } = detailsPanelState.item as Feeder

    const updatedFeeder = {
      id,
      checksum,
      extremities: extremities.filter(extremity => extremity.id !== deletedExtremityId),
    }

    try {
      const response: Feeder = await patch(
        `/midi-next/v2/${ObjectURI.Feeders}/${updatedFeeder.id}`,
        updatedFeeder,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
