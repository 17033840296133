import { ReactElement } from 'react'
import { useTranslation } from '@osrdata/app_core/dist/translation'

import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import info from 'assets/icons/warning.svg'

import { fieldValueToString } from 'objects/utils'
import { InputDataType } from '../utils'
import './CustomSelect.scss'

export default function CustomSelect({
  field, value, onChange, error, helperText, disabled,
}: InputDataType): ReactElement | null {
  const { t } = useTranslation()
  if (field.values === undefined) return null

  return (
    <div key={field.key} className="w-100 my-3 px-2">
      <FormControl fullWidth key={field.key} error={error && !disabled} variant="standard">
        <InputLabel shrink disabled={disabled}>{t(field.label)}</InputLabel>
        <Select
          value={value === undefined ? '' : fieldValueToString(value as string)}
          onChange={e => onChange(e.target.value, field)}
          className="select"
          disableUnderline
          disabled={disabled}
        >
          {field.values.map(option => (
            <MenuItem
              key={option.label}
              value={fieldValueToString(option.value)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {helperText && !disabled && (
          <FormHelperText error={error} className="helper d-flex">
            <div>
              <img src={info} alt="info icon" className="helper-icon mr-2" />
            </div>
            <div className="text-wrapper">
              {t(helperText)}
            </div>
          </FormHelperText>
        )}
      </FormControl>
    </div>
  )
}
