import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement } from 'react'

import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import './SearchAreas.scss'

type Props = {
  value: string;
  onChange: (s: string) => void;
}

export default function SearchAreas({ value, onChange }: Props): ReactElement {
  const { t } = useTranslation()

  return (
    <div id="search-areas" className="w-25 d-flex justify-content-center align-items-center">
      <TextField
        variant="standard"
        placeholder={t('Areas.searchPlaceholder')}
        value={value}
        fullWidth
        onChange={e => onChange(e.target.value)}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <div className="start-icon-wrapper">
              <SearchIcon className="start-icon" />
            </div>
          ),
        }}
      />
    </div>
  )
}
