/* eslint-disable @typescript-eslint/no-explicit-any */
import { patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Area, TrackProtectionGroup } from 'objects/types/protections'
import { ThunkApiConfig } from 'types'
import { store } from 'Store'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import ObjectURI from 'objects/uri'

export const addAssociatedSubArea = createAsyncThunk<TrackProtectionGroup, Area, ThunkApiConfig>(
  'trackProtectionGroup/addAssociatedSubArea',
  async (newAssociatedSubArea, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, associatedSubAreas } = detailsPanelState.item as TrackProtectionGroup

    const updatedTrackProtectionGroup: Partial<TrackProtectionGroup> = {
      id,
      checksum,
      associatedSubAreas: [...associatedSubAreas, newAssociatedSubArea],
    }

    try {
      const response: TrackProtectionGroup = await patch(
        `/midi-next/v2/${ObjectURI.TrackProtectionGroups}/${updatedTrackProtectionGroup.id}`,
        updatedTrackProtectionGroup,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteAssociatedSubArea = createAsyncThunk<TrackProtectionGroup, string, ThunkApiConfig>(
  'trackProtectionGroup/deleteAssociatedSubArea',
  async (deletedAreaId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, associatedSubAreas } = detailsPanelState.item as TrackProtectionGroup

    const updatedTrackProtectionGroup: Partial<TrackProtectionGroup> = {
      id,
      checksum,
      associatedSubAreas: associatedSubAreas.filter(subAreas => subAreas.id !== deletedAreaId),
    }

    try {
      const response: TrackProtectionGroup = await patch(
        `/midi-next/v2/${ObjectURI.TrackProtectionGroups}/${updatedTrackProtectionGroup.id}`,
        updatedTrackProtectionGroup,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
