import ElectricalElementServices from 'objects/ElectricalElements/ElectricalElementServices'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'
import FeederServices from 'objects/Feeders/FeederServices'
import IsolatorServices from 'objects/Isolators/IsolatorServices'
import SectorServices from 'objects/Sectors/SectorServices'
import SignalServices from 'objects/Signals/SignalServices'
import SubSectorServices from 'objects/SubSectors/SubSectorServices'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import TrackServices from 'objects/Tracks/TrackServices'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'

import { isAnyOf } from '@reduxjs/toolkit'

export const getDetailsFulfilledMatcher = isAnyOf(
  ElectricalElementServices.getDetails.fulfilled,
  ElectricalProtectionGroupServices.getDetails.fulfilled,
  FeederServices.getDetails.fulfilled,
  IsolatorServices.getDetails.fulfilled,
  SectorServices.getDetails.fulfilled,
  SignalServices.getDetails.fulfilled,
  SubSectorServices.getDetails.fulfilled,
  TrackProtectionGroupServices.getDetails.fulfilled,
  TrackProtectionServices.getDetails.fulfilled,
  TrackSectionServices.getDetails.fulfilled,
  TrackServices.getDetails.fulfilled,
)

export const getDetailsPendingMatcher = isAnyOf(
  ElectricalElementServices.getDetails.pending,
  ElectricalProtectionGroupServices.getDetails.pending,
  FeederServices.getDetails.pending,
  IsolatorServices.getDetails.pending,
  SectorServices.getDetails.pending,
  SignalServices.getDetails.pending,
  SubSectorServices.getDetails.pending,
  TrackProtectionGroupServices.getDetails.pending,
  TrackProtectionServices.getDetails.pending,
  TrackSectionServices.getDetails.pending,
  TrackServices.getDetails.pending,
)

export const getDetailsErrorMatcher = isAnyOf(
  ElectricalElementServices.getDetails.rejected,
  ElectricalProtectionGroupServices.getDetails.rejected,
  FeederServices.getDetails.rejected,
  IsolatorServices.getDetails.rejected,
  SectorServices.getDetails.rejected,
  SignalServices.getDetails.rejected,
  SubSectorServices.getDetails.rejected,
  TrackProtectionGroupServices.getDetails.rejected,
  TrackProtectionServices.getDetails.rejected,
  TrackSectionServices.getDetails.rejected,
  TrackServices.getDetails.rejected,
)
