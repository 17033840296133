import { Fragment, ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { InstructionState } from 'reducers/instruction'
import UserServices from 'services/UserServices'
import { AdminState } from 'reducers/admin'
import { RootState } from 'Store'

import ColorLoading from '../common/ColorLoading'
import { getOperators, getSupervisors } from '../utils'
import SelectionFulfilled from './SelectionFulfilled'
import SelectFields from './SelectFields'
import './index.scss'

const SUB_STEPS = [
  {
    key: 0,
    component: <SelectFields />,
  },
  {
    key: 1,
    component: <ColorLoading message="Instruction.creation.itemSelection.getObjectsLoading" />,
  },
  {
    key: 2,
    component: <SelectionFulfilled />,
  },
]

export default function UserAttribution(): ReactElement {
  const dispatch = useDispatch()
  const { groups } = useSelector((state: RootState) => state.admin) as AdminState
  const { activeSubStep } = useSelector((state: RootState) => state.instruction) as InstructionState

  useEffect(() => {
    dispatch(UserServices.getGroups())
  }, [])

  useEffect(() => {
    getOperators(groups)
    getSupervisors(groups)
  }, [groups])

  return (
    <div id="instruction-user-assignation" className="d-flex w-100 flex-column h-100">
      {SUB_STEPS.map(subStep => (activeSubStep === subStep.key
        ? (
          <Fragment key={subStep.key}>
            {subStep.component}
          </Fragment>
        )
        : null))}
    </div>
  )
}
