/* eslint-disable @typescript-eslint/no-explicit-any */
import { patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Area, SubSector } from 'objects/types/protections'
import { ThunkApiConfig } from 'types'
import { store } from 'Store'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import ObjectURI from 'objects/uri'

export const addResponsibleSubArea = createAsyncThunk<SubSector, Area, ThunkApiConfig>(
  'subSector/addResponsibleSubArea',
  async (newResponsibleSubArea, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, responsibleSubAreas } = detailsPanelState.item as SubSector

    const updatedSubSector: Partial<SubSector> = {
      id,
      checksum,
      responsibleSubAreas: [...responsibleSubAreas, newResponsibleSubArea],
    }

    try {
      const response: SubSector = await patch(
        `/midi-next/v2/${ObjectURI.SubSectors}/${updatedSubSector.id}`,
        updatedSubSector,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteResponsibleSubArea = createAsyncThunk<SubSector, string, ThunkApiConfig>(
  'subSector/deleteResponsibleSubArea',
  async (deletedAreaId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, responsibleSubAreas } = detailsPanelState.item as SubSector

    const updatedSubSector: Partial<SubSector> = {
      id,
      checksum,
      responsibleSubAreas: responsibleSubAreas.filter(subAreas => subAreas.id !== deletedAreaId),
    }

    try {
      const response: SubSector = await patch(
        `/midi-next/v2/${ObjectURI.SubSectors}/${updatedSubSector.id}`,
        updatedSubSector,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
