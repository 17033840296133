import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'

import { MapIconNames } from 'components/Map/icons'
import { LayerProps } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import { formatLayerUrl } from 'objects/utils'

const sourceLayer = ObjectLayer.Isolator

export default function IsolatorsLayer({ visible }: LayerProps): ReactElement {
  return (
    <Source
      type="vector"
      url={formatLayerUrl(sourceLayer)}
      source-layer={sourceLayer}
      id={sourceLayer}
    >
      <Layer
        id={`${sourceLayer}-layer`}
        type="symbol"
        layout={{
          'icon-rotate': ['get', 'angle'],
          'icon-rotation-alignment': 'map',
          'icon-size': ['step', ['zoom'],
            0, 11,
            0.2,
          ],
          'icon-image': MapIconNames.isolator,
          visibility: visible ? 'visible' : 'none',
          'icon-allow-overlap': true,
        }}
        paint={{}}
        source-layer={sourceLayer}
        minzoom={14}
        filter={['==', ['get', 'deletedAt'], null]}
      />
    </Source>
  )
}
