import { useTranslation } from '@osrdata/app_core/dist/translation'
import {
  ChangeEvent, ReactElement, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'

import Pagination from '@mui/material/Pagination'

import { ReactComponent as WarningTriangle } from 'assets/icons/warning_triangle.svg'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import {
  InstructionState, setActiveStep, setActiveSubStep, setItems,
} from 'reducers/instruction'
import InstructionServices from 'services/InstructionServices'
import { RootState, store } from 'Store'
import ItemListSearch from './ItemListSearch'
import ItemsList from './ItemsList'
import './EditItemsList.scss'

const NB_ITEMS_PER_PAGE = 100

const updateItemsDetails = (id: string | undefined, page: number, search: string) => {
  if (id) {
    store.dispatch(InstructionServices.getItemsDetailsList({
      id, page, search,
    }))
  }
}

const debouncedUpdate = debounce(updateItemsDetails, 600)

export default function EditItemsList(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    instruction, totalItems, activeStep, hasError, loading,
  } = useSelector((state: RootState) => state.instruction) as InstructionState
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (instruction.itemCount !== 0) {
      updateItemsDetails(instruction.id, page, search)
    }
  }, [instruction.itemCount])

  const onChangePage = (_e: ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage)
    updateItemsDetails(instruction.id, newPage, search)
  }

  const onDeleteItem = (itemId: string) => {
    dispatch(InstructionServices.deleteItem({
      id: instruction.id as string,
      itemId,
    }))
  }

  const onSearch = (s: string) => {
    setSearch(s)
    debouncedUpdate(instruction.id as string, page, s)
  }

  const nbOfPages = Math.floor(totalItems / NB_ITEMS_PER_PAGE) + 1

  return (
    <>
      <div className="colored-title d-flex justify-content-center align-items-center">
        <div className="w-50 mx-auto">
          <div className="d-flex justify-content-center align-items-center text-center flex-column">
            <h1>{t('Instruction.creation.editList.title')}</h1>
          </div>
        </div>
      </div>
      <div className="edit-items-list d-flex flex-column">
        {hasError && !loading ? (
          <>
            <div
              className="error-wrapper d-flex justify-content-center align-items-center h-100 m-auto"
            >
              <WarningTriangle className="warning-icon" />
              <div className="warning-message">
                {t('Instruction.creation.editList.errorObject')}
              </div>
            </div>
            <div className="mt-3" style={{ flex: 1 }}>
              <SimpleButton
                style={ButtonStyle.primary}
                title={t('Instruction.creation.editList.retry')}
                onClick={() => {
                  dispatch(InstructionServices.getDetails(instruction.id || ''))
                }}
              />
            </div>
          </>
        ) : (
          <>
            <ItemListSearch value={search} onChange={onSearch} />
            <ItemsList onDeleteItem={onDeleteItem} />
            <div className="d-flex justify-content-center align-items-center">
              <Pagination count={nbOfPages} page={page} onChange={onChangePage} showLastButton showFirstButton />
            </div>
            <div className="mt-3">
              <SimpleButton
                style={ButtonStyle.primary}
                title={t('Instruction.button.validateObjectsList')}
                onClick={() => {
                  dispatch(setItems([]))
                  dispatch(setActiveStep(activeStep + 1))
                  dispatch(setActiveSubStep(0))
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}
