import PanelNavigator from 'components/Panels/PanelNavigator'
import ElectricalElementServices from 'objects/ElectricalElements/ElectricalElementServices'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'
import FeederServices from 'objects/Feeders/FeederServices'
import IsolatorServices from 'objects/Isolators/IsolatorServices'
import SectorServices from 'objects/Sectors/SectorServices'
import SignalServices from 'objects/Signals/SignalServices'
import SubSectorServices from 'objects/SubSectors/SubSectorServices'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import TrackServices from 'objects/Tracks/TrackServices'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'
import { MidiObject } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import { setLoading } from 'reducers/panels/detailsPanel'
import { PanelName } from 'reducers/panels/panel'
import { store } from 'Store'
import { GenericAction } from 'types'

const setActions = (
  id: string, getDetailsAction: GenericAction, getGeomAction: GenericAction,
) => {
  store.dispatch(setLoading(true))
  store.dispatch(getDetailsAction(id))
  PanelNavigator.push(PanelName.details)
  store.dispatch(getGeomAction(id))
}

const getClickedObjectDetails = (object: MidiObject, layer: ObjectLayer): void => {
  switch (layer) {
    case ObjectLayer.Track:
      setActions(object.id, TrackServices.getDetails, TrackServices.getGeometry)
      break
    case ObjectLayer.TrackSection:
      setActions(object.id, TrackSectionServices.getDetails, TrackSectionServices.getGeometry)
      break
    case ObjectLayer.Isolator:
      setActions(object.id, IsolatorServices.getDetails, IsolatorServices.getGeometry)
      break
    case ObjectLayer.Sector:
      setActions(object.id, SectorServices.getDetails, SectorServices.getGeometry)
      break
    case ObjectLayer.Feeder:
      setActions(object.id, FeederServices.getDetails, FeederServices.getGeometry)
      break
    case ObjectLayer.Signal:
      setActions(object.id, SignalServices.getDetails, SignalServices.getGeometry)
      break
    case ObjectLayer.SubSector:
      setActions(object.id, SubSectorServices.getDetails, SubSectorServices.getGeometry)
      break
    case ObjectLayer.TrackProtection:
      setActions(
        object.id, TrackProtectionServices.getDetails, TrackProtectionServices.getGeometry,
      )
      break
    case ObjectLayer.TrackProtectionGroup:
      setActions(
        object.id,
        TrackProtectionGroupServices.getDetails,
        TrackProtectionGroupServices.getGeometry,
      )
      break
    case ObjectLayer.ElectricalElement:
      setActions(
        object.id, ElectricalElementServices.getDetails, ElectricalElementServices.getGeometry,
      )
      break
    case ObjectLayer.ElectricalProtectionGroup:
      setActions(
        object.id,
        ElectricalProtectionGroupServices.getDetails,
        ElectricalProtectionGroupServices.getGeometry,
      )
      break
    default:
      break
  }
}

export default getClickedObjectDetails
