import { ReactElement } from 'react'
import { useTranslation } from '@osrdata/app_core/dist/translation'

import FormHelperText from '@mui/material/FormHelperText'

import info from 'assets/icons/warning.svg'

type Props = {
  helperText: string;
}

export default function CustomFormHelperText({ helperText }: Props): ReactElement {
  const { t } = useTranslation()
  return (
    <FormHelperText error className="helper d-flex">
      <div>
        <img src={info} alt="info icon" className="helper-icon mr-2" />
      </div>
      <div className="text-wrapper">
        {t(helperText)}
      </div>
    </FormHelperText>
  )
}
