import { ReactElement } from 'react'
import { Instruction, User } from 'objects/types/instructions'
import { User as CerbereUser } from 'services/cerbereTypes'
import UserBadges from 'views/admin/UserBadges'
import { formatDate, formateRelativeDate } from './utils'

type Formatter<T, P, R> = (t: T, p: R) => P

type Column = {
  title: string;
  propertyName: keyof Instruction;
  formatter: Formatter<unknown, string | number | ReactElement, CerbereUser[]>;
}

export const COLUMNS: Column[] = [
  {
    title: 'Instruction.dashboard.columns.myInstructions',
    propertyName: 'name',
    formatter: (o): string => o as string,
  },
  {
    title: 'Instruction.dashboard.columns.type',
    propertyName: 'type',
    formatter: (o): string => o as string,
  },
  {
    title: 'Instruction.dashboard.columns.attributions',
    propertyName: 'users',
    formatter: (userIds: unknown, usersInfo: CerbereUser[]): ReactElement => (
      <UserBadges userIds={userIds as User[]} usersInfo={usersInfo} />
    ),
  },
  {
    title: 'Instruction.dashboard.columns.nbObjects',
    propertyName: 'itemCount',
    formatter: o => o as number,
  },
  {
    title: 'Instruction.dashboard.columns.lastUpdate',
    propertyName: 'updateDate',
    formatter: d => formateRelativeDate(d as string),
  },
  {
    title: 'Instruction.dashboard.columns.creationDate',
    propertyName: 'creationDate',
    formatter: d => formatDate(d as string),
  },
  {
    title: 'Instruction.dashboard.columns.applicationDate',
    propertyName: 'applicationDate',
    formatter: d => formatDate(d as string),
  },
]

export default COLUMNS
