/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Feature, Geometry } from 'geojson'
import { Line, Track } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import { ValidationParams } from 'components/Panels/DetailsPanel/utils'
import ObjectURI from 'objects/uri'
import { nestedObject, ObjOfStrOrNum, ThunkApiConfig } from 'types'
import { filterFields, getUpdatedFields } from 'objects/utils'
import TRACK_ATTRIBUTES from './TrackAttributes'

const formatLinePayload = ({ id, code, label }: Line) => ({ id, code, label })

const getDetails = createAsyncThunk<Track, string, ThunkApiConfig>(
  'track/getDetails',
  async (id: string, thunkApi) => {
    try {
      const response: Track = await get(`/midi-next/v2/${ObjectURI.Tracks}/${id}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<Geometry, Track>, string, ThunkApiConfig>(
  'track/getGeometry',
  async (id: string, thunkApi) => {
    try {
      const response: Feature<Geometry, Track> = await get(
        `/chartis/v2/layer/${ObjectLayer.Track}/geojson_feature/sch/`,
        { id },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<Track, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'track/create',
  async (newTrack, thunkApi) => {
    try {
      let formattedNewTrack = newTrack as Partial<Track>
      if (newTrack.line && (newTrack.line as Partial<Line>).code === '') {
        formattedNewTrack = filterFields(
          newTrack, TRACK_ATTRIBUTES().filter(att => att.required).map(att => att.key),
        ) as Partial<Track>
      }
      const response: Track = await post(`/midi-next/v2/${ObjectURI.Tracks}/`, formattedNewTrack)
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<Track, Track, ThunkApiConfig>(
  'track/updateById',
  async (updatedTrack: Track, thunkApi) => {
    try {
      const response: Track = await patch(
        `/midi-next/v2/${ObjectURI.Tracks}/${updatedTrack.id}`,
        {
          ...filterFields(updatedTrack, getUpdatedFields(TRACK_ATTRIBUTES())),
          line: updatedTrack.line !== null ? formatLinePayload(updatedTrack.line) : updatedTrack.line,
        },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'track/deleteById',
  async (id: string, thunkApi) => {
    try {
      await deleteRequest(`/midi-next/v2/${ObjectURI.Tracks}/${id}`)
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<Track, ValidationParams, ThunkApiConfig>(
  'track/validate',
  async ({ id, operation }, thunkApi) => {
    try {
      const response: Track = await post(`/midi-next/v2/${ObjectURI.Tracks}/${id}/${operation}/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const TrackServices = {
  getDetails,
  getGeometry,
  create,
  update,
  validate,
  delete: deleteObject,
}

export default TrackServices
