import { ReactElement, useEffect, useState } from 'react'
import { Layer, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'

import { GeoEditorState } from 'reducers/geoEditor'
import { RootState } from 'Store'
import { LayerProps } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import getCSSValue from 'helpers'
import { deletedObjectsColor, mainTracksColor, servTracksColor } from 'objects/styles'
import { formatLayerUrl } from 'objects/utils'

const sourceLayer = ObjectLayer.TrackSection

type Props = LayerProps & {
  displayDeleted?: boolean;
} & typeof defaultProps

const defaultProps = {
  displayDeleted: true,
}

const getLineColor = (displayDeleted: boolean): mapboxgl.Expression => (
  displayDeleted
    ? ['case',
      ['!=', ['get', 'deletedAt'], null], getCSSValue(deletedObjectsColor),
      ['==', ['get', 'trackType'], 'MAIN'], getCSSValue(mainTracksColor),
      getCSSValue(servTracksColor),
    ] : ['case',
      ['==', ['get', 'trackType'], 'MAIN'], getCSSValue(mainTracksColor),
      getCSSValue(servTracksColor),
    ]
)

export default function TrackSectionsLayer({ visible, displayDeleted }: Props): ReactElement {
  const { active: showGeoEditor } = useSelector((state: RootState): GeoEditorState => state.TIVEditor)
  const { item } = useSelector((state: RootState) => state.detailsPanel)
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (item) setSelectedId(item.id)
    else setSelectedId(undefined)
  }, [item])

  const dimElement = selectedId
    ? ['any', ['!=', ['get', 'id'], selectedId], showGeoEditor]
    : showGeoEditor

  return (
    <Source
      type="vector"
      url={formatLayerUrl(sourceLayer)}
      source-layer={sourceLayer}
      id={sourceLayer}
    >
      <Layer
        id={`${sourceLayer}-layer`}
        type="line"
        paint={{
          'line-color': getLineColor(displayDeleted),
          'line-width': ['case',
            ['==', ['get', 'trackType'], 'MAIN'], 2,
            1,
          ],
          'line-opacity': ['case',
            dimElement, 0.2,
            1,
          ],
        }}
        layout={{ visibility: visible ? 'visible' : 'none' }}
        source-layer={sourceLayer}
      />
    </Source>
  )
}

TrackSectionsLayer.defaultProps = defaultProps
