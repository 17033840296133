enum ObjectURI {
  ElectricalElements = 'electrical-elements',
  ElectricalProtectionGroups = 'electrical-protections-groups',
  Feeders = 'feeders',
  Isolators = 'isolators',
  SubSectors = 'sub-sectors',
  Sectors = 'sectors',
  Signals = 'signals',
  SubAreas = 'sub-areas',
  TIS = 'track-identification-systems',
  TrackProtectionGroups = 'track-protection-groups',
  TrackProtections = 'track-protections',
  TrackSections = 'track-sections',
  Tracks = 'tracks',
}

export const PROTECTIONS_SEARCH_URI = 'protections-search'

export default ObjectURI
