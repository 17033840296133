import { isAnyOf } from '@reduxjs/toolkit'
import GeoEditorService from 'components/GeoEditor/GeoEditorService'
import InstructionServices from 'services/InstructionServices'
import SubnetServices from 'services/SubnetServices'
import { createErrorMatcher } from './createMatchers'
import { deleteErrorMatcher } from './deleteMatchers'
import { getDetailsErrorMatcher } from './getDetailsMatchers'
import { getGeomErrorMatcher } from './getGeomMatchers'
import { updateErrorMatcher } from './update'
import { validationErrorMatcher } from './validationMatchers'

const allErrorsMatcher = isAnyOf(
  GeoEditorService.getTrackSectionGeometry.rejected, GeoEditorService.updateTrackSectionGeometry.rejected,
  getGeomErrorMatcher, getDetailsErrorMatcher, deleteErrorMatcher, updateErrorMatcher, createErrorMatcher,
  validationErrorMatcher,
  InstructionServices.create.rejected,
  InstructionServices.delete.rejected,
  InstructionServices.getItemsInBbox.rejected,
  SubnetServices.getItemsInBbox.rejected,
)

export default allErrorsMatcher
