import {
  createSlice, isAnyOf, PayloadAction,
} from '@reduxjs/toolkit'
import { Subnet } from 'objects/types/subnets'
import { TrackSection } from 'objects/types'
import SubnetServices from 'services/SubnetServices'

export interface SubnetState {
  displayCreation: boolean;
  activeStep: number;
  activeSubStep: number; // Used when there are several screens for 1 step
  subnet: Partial<Subnet>;
  items: TrackSection[]; // detailed list of the instruction's items
  totalItems: number; // detailed list of the instruction's items
  loading: boolean;
  openModal: boolean;
  openValidationModal: boolean;
  showLinearization: boolean;
}

const initialState: SubnetState = {
  displayCreation: false,
  activeStep: 0,
  activeSubStep: 0,
  subnet: {},
  loading: false,
  items: [],
  totalItems: 0,
  openModal: false,
  openValidationModal: false,
  showLinearization: false,
}

export const subnet = createSlice({
  name: 'subnet',
  initialState,
  reducers: {
    reset: () => initialState,
    toggleDisplayCreation: state => {
      state.displayCreation = !state.displayCreation
    },
    setSubnet: (state, action: PayloadAction<Partial<Subnet>>) => {
      state.subnet = action.payload
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload
    },
    setActiveSubStep: (state, action: PayloadAction<number>) => {
      state.activeSubStep = action.payload
    },
    setSubnetLabel: (state, action: PayloadAction<string>) => {
      state.subnet.label = action.payload
    },
    setSubnetOrigin: (state, action: PayloadAction<string>) => {
      state.subnet.originalSubnet = action.payload
    },
    setItems: (state, action: PayloadAction<TrackSection[]>) => {
      state.items = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    toggleOpenModal: state => {
      state.openModal = !state.openModal
    },
    toggleOpenValidationModal: state => {
      state.openValidationModal = !state.openValidationModal
    },
    toggleShowLinearization: state => {
      state.showLinearization = !state.showLinearization
    },
  },
  extraReducers: builder => {
    builder.addCase(SubnetServices.create.fulfilled, (state, action) => {
      state.subnet = action.payload
    })
    builder.addCase(SubnetServices.getDetails.fulfilled, (state, action) => {
      state.subnet = action.payload
    })
    builder.addCase(SubnetServices.getItemsInBbox.fulfilled, (state, action) => {
      state.subnet.trackSectionsIds = action.payload.features.map(f => f.properties.id)
    })
    builder.addCase(SubnetServices.update.fulfilled, state => {
      state.openModal = !state.openModal
    })
    builder.addMatcher(isAnyOf(
      SubnetServices.create.rejected,
      SubnetServices.getItemsInBbox.rejected,
    ), state => {
      if (state.activeSubStep > 0) state.activeSubStep -= 1
      state.openValidationModal = false
    })
  },
})

export const {
  toggleDisplayCreation,
  setSubnet,
  setActiveStep,
  setActiveSubStep,
  setSubnetLabel,
  setItems,
  setLoading,
  reset: resetSubnet,
  setSubnetOrigin,
  toggleOpenModal,
  toggleOpenValidationModal,
  toggleShowLinearization,
} = subnet.actions

export default subnet.reducer
