import { useTranslation } from '@osrdata/app_core/dist/translation'
import { useDispatch, useSelector } from 'react-redux'
import { ReactElement } from 'react'

import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'

import { CREATE_BUTTON_TEXT } from 'components/Panels/CreationPanel/utils'
import ActionButton from 'components/Common/ActionButton/ActionButton'
import CustomDatePicker from 'components/Common/Inputs/CustomDatePicker/CustomDatePicker'
import CustomTextField from 'components/Common/Inputs/CustomTextField'
import {
  AreasState, setEndDate, setLabel, setStartDate, toggleOpenModal,
} from 'reducers/areas'
import { Area } from 'objects/types/protections'
import Parser from 'objects/parser'
import { InputTypes } from 'objects/attributes'
import { RootState, store } from 'Store'

import './AreaModal.scss'

type Props = {
  title: string;
  onButtonClick: () => void;
  buttonText: typeof CREATE_BUTTON_TEXT;
}

const INPUTS = (area: Partial<Area>) => [
  {
    onChange: (d: string) => { store.dispatch(setLabel(d)) },
    value: area.label || '',
    field: {
      key: 'label',
      path: 'label',
      label: 'Areas.details.label',
      parser: Parser.identity,
      type: InputTypes.TextField,
    },
  },
  {
    onChange: (d: string) => { store.dispatch(setStartDate(d)) },
    value: area.activityStartDate === undefined ? '' : area.activityStartDate,
    field: {
      key: 'activityStartDate',
      path: 'activityStartDate',
      label: 'Attributes.common.activityStartDate',
      parser: Parser.date,
      type: InputTypes.DatePicker,
      checkboxLabel: 'Attributes.common.activityStartDateCheckbox',
    },
  },
  {
    onChange: (d: string) => { store.dispatch(setEndDate(d)) },
    value: area.activityEndDate === undefined ? '' : area.activityEndDate,
    field: {
      key: 'activityEndDate',
      path: 'activityEndDate',
      label: 'Attributes.common.activityEndDate',
      parser: Parser.date,
      type: InputTypes.DatePicker,
      checkboxLabel: 'Attributes.common.activityEndDateCheckbox',
    },
  },
]

export default function AreaModal({
  title, onButtonClick, buttonText,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { modal: { open, area, buttonStatus } } = useSelector((state: RootState) => state.areas) as AreasState

  return (
    <Modal open={open} onClose={() => dispatch(toggleOpenModal())} id="area-modal">
      <Paper>
        <div className="title d-flex justify-content-center align-items-center text-center">
          <h1>
            {t(title)}
          </h1>
        </div>
        <div>
          {INPUTS(area).map(input => {
            if (input.field.type === InputTypes.DatePicker) {
              return (
                <CustomDatePicker
                  key={input.field.key}
                  value={input.value}
                  field={input.field}
                  onChange={v => input.onChange(v as string)}
                />
              )
            }
            return (
              <CustomTextField
                key={input.field.key}
                value={input.value}
                field={input.field}
                onChange={v => input.onChange(v as string)}
              />
            )
          })}
        </div>
        <div className="button-wrapper">
          <ActionButton
            title={buttonText}
            onClick={onButtonClick}
            status={buttonStatus}
          />
        </div>
      </Paper>
    </Modal>
  )
}
