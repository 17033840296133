import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import { Instruction, User } from 'objects/types/instructions'
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AdminState } from 'reducers/admin'
import { resetInstruction, setInstruction } from 'reducers/instruction'
import { RootState } from 'Store'
import UserBadges from '../UserBadges'
import Parameters from './Parameters/Parameters'
import './InstructionDashboard.scss'

type Props = {
  instruction: Instruction;
}

export default function InstructionDashboard({ instruction }: Props): ReactElement {
  const dispatch = useDispatch()
  const { usersInfo } = useSelector((state: RootState) => state.admin) as AdminState
  const [displayParamsModal, setDisplayParamsModal] = useState(false)

  return (
    <div className="instruction-dashboard d-flex h-100 w-100">
      <div className="parameters">
        <div>
          <h2>{`Utilisateurs (${instruction.users.length})`}</h2>
          <div className="my-3">
            <UserBadges userIds={instruction.users as User[]} usersInfo={usersInfo} />
          </div>
        </div>
        <div>
          <SimpleButton
            title="Paramètrer la consigne"
            style={ButtonStyle.light}
            onClick={() => {
              dispatch(setInstruction(instruction))
              setDisplayParamsModal(true)
            }}
          />
        </div>
      </div>
      <div className="objects d-flex justify-content-center align-items-center">
        <div>
          Avancement des objets
        </div>
      </div>
      <div className="overview d-flex justify-content-center align-items-center">
        <div>
          Vue d&apos;ensemble
        </div>
      </div>
      {displayParamsModal && (
        <Parameters
          onClose={() => {
            setDisplayParamsModal(false)
            dispatch(resetInstruction())
          }}
        />
      )}
    </div>
  )
}
