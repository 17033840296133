import { ReactElement } from 'react'

import HomeOperator from 'views/operator/Home'
import HomeAdmin from 'views/admin/Home'
import HomeSupervisor from 'views/supervisor/Home'

export const OPERATOR_HOMEPATH = '/accueil'
export const ADMIN_HOMEPATH = '/approbation'
export const SUPERVISOR_HOMEPATH = '/verification'

export type RouteType = {
  path: string;
  component: () => ReactElement;
}

export const OPERATOR_ROUTES: Array<RouteType> = [
  {
    path: OPERATOR_HOMEPATH,
    component: HomeOperator,
  },
]

export const SUPERVISOR_ROUTES = [
  ...OPERATOR_ROUTES,
  {
    path: SUPERVISOR_HOMEPATH,
    component: HomeSupervisor,
  },
]

export const ADMIN_ROUTES = [
  ...SUPERVISOR_ROUTES,
  {
    path: ADMIN_HOMEPATH,
    component: HomeAdmin,
  },
]
