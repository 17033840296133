/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Feature, Geometry } from 'geojson'
import { ElectricalProtectionGroup } from 'objects/types/protections'
import { ObjectLayer } from 'objects/types/const'
import ObjectURI from 'objects/uri'
import { filterFields, getUpdatedFields } from 'objects/utils'
import { ValidationParams } from 'components/Panels/DetailsPanel/utils'
import { nestedObject, ObjOfStrOrNum, ThunkApiConfig } from 'types'
import { addAssociatedSubArea, deleteAssociatedSubArea } from './associatedSubAreas'
import { addResponsibleSubArea, deleteResponsibleSubArea } from './responsibleSubAreas'
import { addElectricalElement, deleteElectricalElement } from './composition'
import { addIncompatibleObject, deleteIncompatibleObject } from './incompatibleObjects'
import { addExtremity, deleteExtremity, switchExtremityDirection } from './extremities'
import ELECTRICAL_PROTECTION_GROUP_ATTRIBUTES from '../ElectricalProtectionGroupAttributes'

const getDetails = createAsyncThunk<ElectricalProtectionGroup, string, ThunkApiConfig>(
  'electricalProtectionGroup/getDetails',
  async (id, thunkApi) => {
    try {
      const response: ElectricalProtectionGroup = await get(
        `/midi-next/v2/${ObjectURI.ElectricalProtectionGroups}/${id}`,
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<Geometry, ElectricalProtectionGroup>, string, ThunkApiConfig>(
  'electricalProtectionGroup/getGeometry',
  async (id, thunkApi) => {
    try {
      const response: Feature<Geometry, ElectricalProtectionGroup> = await get(
        `/chartis/v2/layer/${ObjectLayer.ElectricalProtectionGroup}/geojson_feature/sch/`,
        { id },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<ElectricalProtectionGroup, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'electricalProtectionGroup/create',
  async (newElectricalProtectionGroup, thunkApi) => {
    try {
      const response: ElectricalProtectionGroup = await post(
        `/midi-next/v2/${ObjectURI.ElectricalProtectionGroups}/`,
        newElectricalProtectionGroup,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<ElectricalProtectionGroup, ElectricalProtectionGroup, ThunkApiConfig>(
  'electricalProtectionGroup/updateById',
  async (updatedElectricalProtectionGroup, thunkApi) => {
    try {
      const response: ElectricalProtectionGroup = await patch(
        `/midi-next/v2/${ObjectURI.ElectricalProtectionGroups}/${updatedElectricalProtectionGroup.id}`,
        filterFields(updatedElectricalProtectionGroup, getUpdatedFields(ELECTRICAL_PROTECTION_GROUP_ATTRIBUTES())),
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'electricalProtectionGroup/deleteById',
  async (id, thunkApi) => {
    try {
      await deleteRequest(`/midi-next/v2/${ObjectURI.ElectricalProtectionGroups}/${id}`)
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<ElectricalProtectionGroup, ValidationParams, ThunkApiConfig>(
  'electricalProtectionGroup/validate',
  async ({ id, operation }, thunkApi) => {
    try {
      const response: ElectricalProtectionGroup = await post(
        `/midi-next/v2/${ObjectURI.ElectricalProtectionGroups}/${id}/${operation}/`, {},
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const ElectricalProtectionGroupServices = {
  getDetails,
  getGeometry,
  create,
  update,
  validate,
  delete: deleteObject,
  addResponsibleSubArea,
  deleteResponsibleSubArea,
  addElectricalElement,
  deleteElectricalElement,
  addAssociatedSubArea,
  deleteAssociatedSubArea,
  addIncompatibleObject,
  deleteIncompatibleObject,
  addExtremity,
  deleteExtremity,
  switchExtremityDirection,
}

export default ElectricalProtectionGroupServices
