/* eslint-disable @typescript-eslint/no-explicit-any */
import { patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ElectricalElement } from 'objects/types/protections'
import { KPOrientation, ObjectLayer } from 'objects/types/const'
import { nestedObject, ObjOfStrOrNum, ThunkApiConfig } from 'types'
import { store } from 'Store'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import { LAYER_NAME } from 'objects/attributes'
import ExtremityCreation from 'objects/common/Extremities/types'
import ObjectURI from 'objects/uri'

export const addExtremity = createAsyncThunk<ElectricalElement, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'electricalElement/addExtremity',
  async (newExtremity, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, extremities } = detailsPanelState.item as ElectricalElement
    const typedNewExtremity = newExtremity as unknown as ExtremityCreation

    const formattedNewExtremity = typedNewExtremity[LAYER_NAME] === ObjectLayer.TrackIdentificationSystem
      ? {
        kp: typedNewExtremity.kilometricPoints[0],
        orientation: newExtremity.orientation,
      }
      : {
        extremityObject: newExtremity.extremityObject,
        orientation: newExtremity.orientation,
      }

    const updatedElectricalElement = {
      id,
      checksum,
      extremities: [
        ...extremities,
        formattedNewExtremity,
      ],
    }

    try {
      const response: ElectricalElement = await patch(
        `/midi-next/v2/${ObjectURI.ElectricalElements}/${updatedElectricalElement.id}`,
        updatedElectricalElement,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteExtremity = createAsyncThunk<ElectricalElement, number, ThunkApiConfig>(
  'electricalElement/deleteExtremityById',
  async (deletedExtremityId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, extremities } = detailsPanelState.item as ElectricalElement

    const updatedElectricalElement = {
      id,
      checksum,
      extremities: extremities.filter(extremity => extremity.id !== deletedExtremityId),
    }

    try {
      const response: ElectricalElement = await patch(
        `/midi-next/v2/${ObjectURI.ElectricalElements}/${updatedElectricalElement.id}`,
        updatedElectricalElement,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const switchExtremityDirection = createAsyncThunk<ElectricalElement, number, ThunkApiConfig>(
  'electricalElement/switchExtremityDirection',
  async (extremityId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, extremities } = detailsPanelState.item as ElectricalElement

    const updatedElectricalElement = {
      id,
      checksum,
      extremities: extremities.map(extremity => {
        if (extremity.id === extremityId) {
          return {
            ...extremity,
            orientation: extremity.orientation === KPOrientation.ASC
              ? KPOrientation.DESC
              : KPOrientation.ASC,
          }
        }
        return extremity
      }),
    }

    try {
      const response: ElectricalElement = await patch(
        `/midi-next/v2/${ObjectURI.ElectricalElements}/${updatedElectricalElement.id}`,
        updatedElectricalElement,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
