import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import Divider from '@mui/material/Divider'

import Attributes from 'components/Panels/DetailsPanel/Attributes'
import { TrackProtection } from 'objects/types/protections'
import { RootState } from 'Store'

import { ExtraKind } from 'objects/types/const'
import { CollapsibleMenu } from 'reducers/types'
import TRACK_PROTECTION_ATTRIBUTES from './TrackProtectionAttributes'
import Extremities from '../common/Extremities/Extremities'
import ResponsibleSubAreas from '../common/ResponsibleSubAreas/ResponsibleSubAreas'
import AssociatedSubAreas from '../common/AssociatedSubAreas/AssociatedSubAreas'
import LinkedProtections from '../common/LinkedProtections/LinkedProtections'
import TrackProtectionServices from './TrackProtectionServices'

export default function TrackProtectionDetails(): ReactElement | null {
  const { item: trackProtection }: { item: TrackProtection } = useSelector((state: RootState) => state.detailsPanel)
  const {
    extremities, associatedSubAreas, responsibleSubAreas, incompatibleProtections: incompatibleTrackProtections,
  } = trackProtection

  return (
    <div className="object-attributes">
      <Attributes item={trackProtection} attributes={TRACK_PROTECTION_ATTRIBUTES()} />

      <Divider className="my-2" />

      <div id="linked-objects">
        <Extremities
          type={ExtraKind.TPExtremity}
          extremities={extremities}
          deleteExtremity={TrackProtectionServices.deleteExtremity}
          switchOrientation={TrackProtectionServices.switchExtremityDirection}
        />
        <ResponsibleSubAreas
          responsibleSubAreas={responsibleSubAreas}
          deleteRSAAction={TrackProtectionServices.deleteResponsibleSubArea}
          addRSAAction={TrackProtectionServices.addResponsibleSubArea}
        />
        <AssociatedSubAreas
          associatedSubAreas={associatedSubAreas}
          deleteASAAction={TrackProtectionServices.deleteAssociatedSubArea}
          addASAAction={TrackProtectionServices.addAssociatedSubArea}
        />
        <LinkedProtections
          linkedProtections={incompatibleTrackProtections}
          deleteAction={TrackProtectionServices.deleteIncompatibleObject}
          addIPAction={TrackProtectionServices.addIncompatibleObject}
          type={CollapsibleMenu.incompatibleObjects}
        />
      </div>
    </div>
  )
}
