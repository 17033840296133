import { useTranslation } from '@osrdata/app_core/dist/translation'
import { HTMLAttributes, ReactElement, ReactNode } from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { User } from 'services/cerbereTypes'

import './UserMultiSelect.scss'

type Props = {
  options: User[];
  label: string;
  placeholder: string;
  onChange: (users: User[]) => void;
  onBlur?: () => void;
  defaultValue?: User[];
  elevated?: boolean;
}

const defaultProps = {
  onBlur: () => {},
  defaultValue: undefined,
  elevated: false,
}

const formatUserLabel = (user: User): string => (
  `${user.firstName} ${user.lastName} - ${user.username.toUpperCase()}`
)

const renderOptions = (
  props: HTMLAttributes<HTMLLIElement>, option: User, value: User[] | undefined, selected: boolean,
): ReactNode => !selected
  && ((value && !value.map(u => u.id).includes(option.id)) || value === undefined)
  && (
    <li {...props}>
      {formatUserLabel(option)}
    </li>
  )

export default function UserMultiSelect({
  options, label, placeholder, onChange, onBlur, defaultValue, elevated,
}: Props): ReactElement {
  const { t } = useTranslation()
  return (
    <Autocomplete
      multiple
      id="operator-select"
      className={`user-multi-select ${elevated ? 'elevation-2' : ''}`}
      options={options}
      disableCloseOnSelect
      noOptionsText={t('Instruction.creation.userAssignation.noOptionsText')}
      getOptionLabel={option => formatUserLabel(option)}
      renderOption={(props, option, { selected }) => renderOptions(props, option, defaultValue, selected)}
      value={defaultValue}
      onChange={(_e, newUsers) => onChange(newUsers)}
      onBlur={onBlur}
      renderInput={inputParams => (
        <TextField
          variant="standard"
          {...inputParams}
          label={t(label)}
          placeholder={t(placeholder)}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...inputParams.InputProps,
            disableUnderline: true,
          }}
        />
      )}
    />
  )
}

UserMultiSelect.defaultProps = defaultProps
