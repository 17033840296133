import { ReactElement, useEffect } from 'react'
import { Editor } from 'react-map-gl-draw'
import { useSelector } from 'react-redux'

import { GeoEditorState } from 'reducers/geoEditor'
import { RootState } from 'Store'

import { EditorLayerState, EDITOR_MODES } from './types'
import { getFeatureStyle } from './stylingHelpers'
import { onUpdate, onSelect, onToolChange } from './helpers/coreMethods'

function EditorLayer({ editorRef }: EditorLayerState): ReactElement | null {
  const {
    object, mode, editorLayerModeName, modeConfig,
  } = useSelector((state: RootState): GeoEditorState => state.TIVEditor)

  useEffect(() => {
    onToolChange()
  }, [mode, object])

  return (
    <Editor
      ref={editorRef}
      style={{ width: '100%', height: '100%' }}
      clickRadius={12}
      mode={EDITOR_MODES[editorLayerModeName]}
      modeConfig={modeConfig}
      features={object ? [object] : undefined}
      featureStyle={getFeatureStyle}
      onUpdate={onUpdate}
      onSelect={onSelect}
      editHandleShape="circle"
      selectedFeatureIndex={0}
      selectable
    />
  )
}

export default EditorLayer
