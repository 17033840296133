import { Polygon } from 'geojson'
import { ObjectLayer, ObjectStatus } from './const'

export enum InstructionType {
  S6 = 'S6',
  S9 = 'S9',
  S11 = 'S11',
}

export const S6ObjectLayers: ObjectLayer[] = [
  ObjectLayer.Isolator, ObjectLayer.Signal, ObjectLayer.Track,
  ObjectLayer.TrackIdentificationSystem, ObjectLayer.TrackSection,
]

export const S9ObjectLayers: ObjectLayer[] = [
  ...S6ObjectLayers, ObjectLayer.TrackProtection, ObjectLayer.TrackProtectionGroup,
]

export const S11ObjectLayers: ObjectLayer[] = [
  ...S6ObjectLayers, ObjectLayer.ElectricalElement, ObjectLayer.ElectricalProtectionGroup, ObjectLayer.Feeder,
  ObjectLayer.Sector, ObjectLayer.SubSector,
]

export const LayersByInstructionType = {
  [InstructionType.S6]: S6ObjectLayers,
  [InstructionType.S9]: S9ObjectLayers,
  [InstructionType.S11]: S11ObjectLayers,
}

export type User = {
  sub: string;
}

export type InstructionItem = {
  id?: string;
  itemId?: string;
  itemType: string;
  mainRepresentation?: string;
  secondaryRepresentation?: string;
  detailUrl?: string;
  status: ObjectStatus;
}

export type Instruction = {
  id: string;
  creationDate: string;
  updateDate: string;
  applicationDate: string;
  itemCount: number;
  name: string;
  reference: string;
  type: InstructionType;
  users: string[] | User[];
  version: string;
  versionDate: string;
  geom?: Polygon;
}
