/* eslint-disable @typescript-eslint/no-explicit-any */
import { patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ShortProtection, TrackProtection } from 'objects/types/protections'
import { ThunkApiConfig } from 'types'
import { store } from 'Store'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import ObjectURI from 'objects/uri'

export const addIncompatibleObject = createAsyncThunk<TrackProtection, ShortProtection, ThunkApiConfig>(
  'trackProtection/addIncompatibleObject',
  async (newIncompatibleObject, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, incompatibleProtections } = detailsPanelState.item as TrackProtection

    const updatedTrackProtection = {
      id,
      checksum,
      incompatibleProtections: [...incompatibleProtections, {
        id: newIncompatibleObject.id,
      }],
    }

    try {
      const response: TrackProtection = await patch(
        `/midi-next/v2/${ObjectURI.TrackProtections}/${updatedTrackProtection.id}`,
        updatedTrackProtection,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteIncompatibleObject = createAsyncThunk<TrackProtection, string, ThunkApiConfig>(
  'trackProtection/deleteIncompatibleObject',
  async (deletedTpId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, incompatibleProtections } = detailsPanelState.item as TrackProtection

    const updatedTrackProtection: Partial<TrackProtection> = {
      id,
      checksum,
      incompatibleProtections: incompatibleProtections.filter(tp => tp.id !== deletedTpId),
    }

    try {
      const response: TrackProtection = await patch(
        `/midi-next/v2/${ObjectURI.TrackProtections}/${updatedTrackProtection.id}`,
        updatedTrackProtection,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
