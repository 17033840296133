import { useTranslation } from '@osrdata/app_core/dist/translation'
import { useDispatch, useSelector } from 'react-redux'
import { ReactElement } from 'react'
import MidiRole from 'services/midiRoleTypes'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import PanelNavigator from 'components/Panels/PanelNavigator'
import { updateItem } from 'reducers/panels/detailsPanel'
import { setHoveredPanelObject } from 'reducers/map'
import { disableGeoEditor } from 'reducers/geoEditor'
import { PanelName } from 'reducers/panels/panel'
import { ShortMidiObject } from 'objects/types'
import { ObjectStatus } from 'objects/types/const'
import { AppState } from 'reducers/app'
import { RootState } from 'Store'
import { getMainText, getSubText } from './utils'
import './ObjectItem.scss'

type Props = {
  item: unknown;
}

export default function ObjectItem({ item }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { role } = useSelector((state: RootState): AppState => state.app)

  const typedItem = item as ShortMidiObject

  const onItemClick = () => {
    dispatch(disableGeoEditor())
    dispatch(updateItem(typedItem))
    PanelNavigator.push(PanelName.details)
  }

  const getStatusIcon = () => {
    switch (typedItem.status) {
      case ObjectStatus.VALIDATED:
        if (role === MidiRole.operator) {
          return <CheckCircleIcon color="success" />
        }
        return <ArrowForwardIosIcon className="icon" />
      case ObjectStatus.VERIFIED:
        if (role === MidiRole.supervisor) {
          return <CheckCircleIcon color="success" />
        }
        return <ArrowForwardIosIcon className="icon" />
      default:
        return <ArrowForwardIosIcon className="icon" />
    }
  }

  return (
    <ListItemButton
      role={undefined}
      onClick={() => onItemClick()}
      className="list-item-wrapper elevation-2"
      onMouseEnter={() => {
        dispatch(setHoveredPanelObject(item as ShortMidiObject))
      }}
      onMouseLeave={() => {
        dispatch(setHoveredPanelObject(undefined))
      }}
    >
      <ListItemText id={typedItem.itemId}>
        <div className="list-text">{getMainText(typedItem)}</div>
        <div className="list-subtitle">{t(getSubText(typedItem))}</div>
      </ListItemText>
      {getStatusIcon()}
    </ListItemButton>
  )
}
