import { BIG_SIGNAL_ICONS, EXISTING_SIGNAL_PANELS } from './SignalTypes'

const Y_OFFSET = -90
const X_OFFSET = -27

const BIG_ICON_SIZE = 0.7
const MAT_SIZE = 1.225

export const BASE_LAYOUT: mapboxgl.AnyLayout = {
  'icon-rotation-alignment': 'map',
  'icon-pitch-alignment': 'map',
  'icon-rotate': ['get', 'angle'],
  'icon-allow-overlap': true,
  'icon-ignore-placement': true,
}

const hasIcon: mapboxgl.Expression = ['in', ['string', ['get', 'type']], ['literal', EXISTING_SIGNAL_PANELS]]
const hasBigIcon: mapboxgl.Expression = ['in', ['string', ['get', 'type']], ['literal', BIG_SIGNAL_ICONS]]

// If no icon or the icon is big, use the bigicon offset
export const iconOffset: mapboxgl.Expression = ['case',
  ['any',
    ['!=', hasIcon, ['literal', true]],
    ['all', hasIcon, hasBigIcon],
  ], ['literal', [X_OFFSET * 2, Y_OFFSET]],
  ['literal', [X_OFFSET, Y_OFFSET]],
]

export const getIconSize = (): mapboxgl.Expression => ['step', ['zoom'],
  0,
  14, ['case',
    hasBigIcon, BIG_ICON_SIZE / 2,
    hasIcon, BIG_ICON_SIZE,
    BIG_ICON_SIZE / 2,
  ],
]

export const getIconImage = (): mapboxgl.Expression => ['case',
  hasIcon, ['concat', 'SCH ', ['get', 'type']],
  'SCH VIDEN',
]

export const getMatSize = (): mapboxgl.Expression => ['step', ['zoom'],
  0,
  14, MAT_SIZE / 2,
]
