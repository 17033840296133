/* eslint-disable @typescript-eslint/no-explicit-any */
import { get } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Line } from 'objects/types'
import { ThunkApiConfig } from 'types'

const getAll = createAsyncThunk<Line[], string, ThunkApiConfig>(
  'lines/getAll',
  async (search: string, thunkApi) => {
    try {
      const res = await get('/midi-next/v2/lines', { search })
      return res.results as Line[]
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const LineServices = {
  getAll,
}

export default LineServices
