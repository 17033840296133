import { isAnyOf } from '@reduxjs/toolkit'
import FeederServices from 'objects/Feeders/FeederServices'

export const updateFeederLinkedObjectsErrorMatcher = isAnyOf(
  FeederServices.deleteExtremity.rejected,
  FeederServices.addResponsibleSubArea.rejected,
  FeederServices.deleteResponsibleSubArea.rejected,
  FeederServices.addAssociatedSubArea.rejected,
  FeederServices.deleteAssociatedSubArea.rejected,
  FeederServices.addIncompatibleObject.rejected,
  FeederServices.deleteIncompatibleObject.rejected,
)

export const updateFeederLinkedObjectsFulfilledMatcher = isAnyOf(
  FeederServices.deleteExtremity.fulfilled,
  FeederServices.addResponsibleSubArea.fulfilled,
  FeederServices.deleteResponsibleSubArea.fulfilled,
  FeederServices.addAssociatedSubArea.fulfilled,
  FeederServices.deleteAssociatedSubArea.fulfilled,
  FeederServices.addIncompatibleObject.fulfilled,
  FeederServices.deleteIncompatibleObject.fulfilled,
)

export const updateFeederLinkedObjectsPendingMatcher = isAnyOf(
  FeederServices.deleteExtremity.pending,
  FeederServices.addResponsibleSubArea.pending,
  FeederServices.deleteResponsibleSubArea.pending,
  FeederServices.addAssociatedSubArea.pending,
  FeederServices.deleteAssociatedSubArea.pending,
  FeederServices.addIncompatibleObject.pending,
  FeederServices.deleteIncompatibleObject.pending,
)
