/* eslint-disable @typescript-eslint/no-explicit-any */
import { patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ShortProtection, Sector } from 'objects/types/protections'
import { ThunkApiConfig } from 'types'
import { store } from 'Store'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import ObjectURI from 'objects/uri'

export const addIncompatibleObject = createAsyncThunk<Sector, ShortProtection, ThunkApiConfig>(
  'sector/addIncompatibleObject',
  async (newIncompatibleObject, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, incompatibleProtections } = detailsPanelState.item as Sector

    const updatedSector = {
      id,
      checksum,
      incompatibleProtections: [...(incompatibleProtections || []), {
        id: newIncompatibleObject.id,
      }],
    }

    try {
      const response: Sector = await patch(
        `/midi-next/v2/${ObjectURI.Sectors}/${updatedSector.id}`,
        updatedSector,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteIncompatibleObject = createAsyncThunk<Sector, string, ThunkApiConfig>(
  'sector/deleteIncompatibleObject',
  async (deletedTpId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, incompatibleProtections } = detailsPanelState.item as Sector

    const updatedTrackProtection: Partial<Sector> = {
      id,
      checksum,
      incompatibleProtections: incompatibleProtections.filter(tp => tp.id !== deletedTpId),
    }

    try {
      const response: Sector = await patch(
        `/midi-next/v2/${ObjectURI.Sectors}/${updatedTrackProtection.id}`,
        updatedTrackProtection,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
