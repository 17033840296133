import { switchToGeoCreator } from 'components/GeoEditor/utils'
import { store } from 'Store'
import PanelNavigator from 'components/Panels/PanelNavigator'
import { PanelName } from 'reducers/panels/panel'
import { ObjectKind, ObjectLayer } from 'objects/types/const'
import { createNewObject } from 'reducers/panels/creationPanel'

const MENU_ITEMS = [
  {
    key: ObjectLayer.Track,
    label: 'Object.type.track',
    onClick: (): void => {
      store.dispatch(createNewObject(ObjectKind.Track))
      PanelNavigator.push(PanelName.creation)
    },
  },
  {
    key: ObjectLayer.Track,
    label: 'Object.type.trackSection',
    onClick: (): void => {
      switchToGeoCreator(undefined)
    },
  },
  {
    key: ObjectLayer.Isolator,
    label: 'Object.type.isolator',
    onClick: (): void => {
      store.dispatch(createNewObject(ObjectKind.Isolator))
      PanelNavigator.push(PanelName.creation)
    },
  },
  {
    key: ObjectLayer.Signal,
    label: 'Object.type.signal',
    onClick: (): void => {
      store.dispatch(createNewObject(ObjectKind.Signal))
      PanelNavigator.push(PanelName.creation)
    },
  },
  {
    key: ObjectLayer.TrackProtection,
    label: 'Object.type.trackProtection',
    onClick: (): void => {
      store.dispatch(createNewObject(ObjectKind.TrackProtection))
      PanelNavigator.push(PanelName.creation)
    },
  },
  {
    key: ObjectLayer.TrackProtectionGroup,
    label: 'Object.type.trackProtectionGroup',
    onClick: (): void => {
      store.dispatch(createNewObject(ObjectKind.TrackProtectionGroup))
      PanelNavigator.push(PanelName.creation)
    },
  },
  {
    key: ObjectLayer.ElectricalElement,
    label: 'Object.type.electricalElement',
    onClick: (): void => {
      store.dispatch(createNewObject(ObjectKind.ElectricalElement))
      PanelNavigator.push(PanelName.creation)
    },
  },
  {
    key: ObjectLayer.ElectricalProtectionGroup,
    label: 'Object.type.electricalProtectionGroup',
    onClick: (): void => {
      store.dispatch(createNewObject(ObjectKind.ElectricalProtectionGroup))
      PanelNavigator.push(PanelName.creation)
    },
  },
  {
    key: ObjectLayer.Sector,
    label: 'Object.type.sector',
    onClick: (): void => {
      store.dispatch(createNewObject(ObjectKind.Sector))
      PanelNavigator.push(PanelName.creation)
    },
  },
  {
    key: ObjectLayer.SubSector,
    label: 'Object.type.subSector',
    onClick: (): void => {
      store.dispatch(createNewObject(ObjectKind.SubSector))
      PanelNavigator.push(PanelName.creation)
    },
  },
  {
    key: ObjectLayer.Feeder,
    label: 'Object.type.feeder',
    onClick: (): void => {
      store.dispatch(createNewObject(ObjectKind.Feeder))
      PanelNavigator.push(PanelName.creation)
    },
  },
]

export default MENU_ITEMS
