import { ReactElement } from 'react'

import { ContextMenuItem } from 'components/Common/ContextMenu/ContextMenu'
import InstructionServices from 'services/InstructionServices'
import { Instruction, User } from 'objects/types/instructions'
import { User as CerbereUser } from 'services/cerbereTypes'
import { store } from 'Store'
import UserBadges from 'views/admin/UserBadges'
import { setInstruction, toggleDisplayNewVersion } from 'reducers/instruction'
import { formatDate, formatRelativeDate } from 'views/admin/HomeTab/utils'

type Formatter<T, P, R> = (t: T, p: R) => P

type Column = {
  title: string;
  propertyName: keyof Instruction;
  formatter: Formatter<unknown, string | number | ReactElement, CerbereUser[]>;
}

export const COLUMNS: Column[] = [
  {
    title: 'Instruction.dashboard.columns.myInstructions',
    propertyName: 'name',
    formatter: (o): string => o as string,
  },
  {
    title: 'Instruction.dashboard.columns.type',
    propertyName: 'type',
    formatter: (o): string => o as string,
  },
  {
    title: 'Instruction.dashboard.columns.attributions',
    propertyName: 'users',
    formatter: (userIds: unknown, usersInfo: CerbereUser[]): ReactElement => (
      <UserBadges userIds={userIds as User[]} usersInfo={usersInfo} />
    ),
  },
  {
    title: 'Instruction.dashboard.columns.nbObjects',
    propertyName: 'itemCount',
    formatter: o => o as number,
  },
  {
    title: 'Instruction.dashboard.columns.lastUpdate',
    propertyName: 'updateDate',
    formatter: d => formatRelativeDate(d as string),
  },
  {
    title: 'Instruction.dashboard.columns.creationDate',
    propertyName: 'creationDate',
    formatter: d => formatDate(d as string),
  },
  {
    title: 'Instruction.dashboard.columns.applicationDate',
    propertyName: 'applicationDate',
    formatter: d => formatDate(d as string),
  },
]

export const INSTRUCTION_CONTEXT_MENU = (instruction: Instruction): ContextMenuItem[] => [
  {
    key: 'updateVersion',
    label: 'Créer une nouvelle version',
    onClick: () => {
      const {
        type, name, reference, applicationDate,
      } = instruction
      store.dispatch(setInstruction({
        type, name, reference, applicationDate,
      }))
      store.dispatch(toggleDisplayNewVersion())
    },
    show: true,
  },
  {
    key: 'delete',
    label: 'Supprimer',
    onClick: () => { store.dispatch(InstructionServices.delete(instruction.id)) },
    show: true,
  },
]
