import { ReactElement } from 'react'

import Loader from 'components/Common/Loader'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import getCSSValue from 'helpers'

const style = {
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: getCSSValue('--color-near-white'),
  opacity: 0.7,
}

type Props = {
  message?: string | undefined;
}

const defaultProps = {
  message: undefined,
}

export default function MapLoader({ message }: Props): ReactElement {
  const { t } = useTranslation()
  return (
    <div style={style} className="position-absolute">
      <Loader message={message ? t(message) : undefined} />
    </div>
  )
}

MapLoader.defaultProps = defaultProps
