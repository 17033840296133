/* eslint-disable camelcase */
export type User = {
  id: string;
  lastName?: string;
  username: string;
  firstName?: string;
  email?: string;
  enabled?: boolean;
}

export enum Role {
  operator = 'user',
  supervisor = 'supervisor',
  admin = 'administrator',
}

export type Group = {
  id: string;
  name: string;
  path: string;
  children: Group[];
  members_count: number;
}

export type GroupListItem = {
  'service_name': string;
  'groups': Group[];
}
