import { useDispatch, useSelector } from 'react-redux'
import { ReactElement } from 'react'

import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import { ValidateServiceOfKind } from 'objects/services'
import { ObjectKind } from 'objects/types/const'
import MidiRole from 'services/midiRoleTypes'
import { AppState } from 'reducers/app'
import { RootState } from 'Store'

import SupervisorButtons from './SupervisorButtons'
import OperatorButtons from './OperatorButtons'

type Props = {
  kind: ObjectKind;
}

export default function DetailsPanelButtons({ kind }: Props): ReactElement {
  const dispatch = useDispatch()
  const { role } = useSelector((state: RootState): AppState => state.app)
  const { item } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)

  const onValidate = (operation: string) => {
    dispatch(ValidateServiceOfKind[kind]({ id: item?.id as string, operation }))
  }

  if (role === MidiRole.operator) {
    return <OperatorButtons onValidate={onValidate} />
  }
  return <SupervisorButtons onValidate={onValidate} />
}
