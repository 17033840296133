/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Feature, Geometry } from 'geojson'
import { TrackProtection } from 'objects/types/protections'
import { ObjectLayer } from 'objects/types/const'
import { ValidationParams } from 'components/Panels/DetailsPanel/utils'
import { nestedObject, ObjOfStrOrNum, ThunkApiConfig } from 'types'
import ObjectURI from 'objects/uri'
import { filterFields, getUpdatedFields } from 'objects/utils'
import { deleteExtremity, switchExtremityDirection, addExtremity } from './extremities'
import { addResponsibleSubArea, deleteResponsibleSubArea } from './responsibleSubAreas'
import { addAssociatedSubArea, deleteAssociatedSubArea } from './associatedSubAreas'
import { addIncompatibleObject, deleteIncompatibleObject } from './incompatibleObjects'
import TRACK_PROTECTION_ATTRIBUTES from '../TrackProtectionAttributes'

const getDetails = createAsyncThunk<TrackProtection, string, ThunkApiConfig>(
  'trackProtection/getDetails',
  async (id, thunkApi) => {
    try {
      const response: TrackProtection = await get(`/midi-next/v2/${ObjectURI.TrackProtections}/${id}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<Geometry, TrackProtection>, string, ThunkApiConfig>(
  'trackProtection/getGeometry',
  async (id, thunkApi) => {
    try {
      const response: Feature<Geometry, TrackProtection> = await get(
        `/chartis/v2/layer/${ObjectLayer.TrackProtection}/geojson_feature/sch/`,
        { id },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<TrackProtection, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'trackProtection/create',
  async (newTrackProtection, thunkApi) => {
    try {
      const response: TrackProtection = await post(`/midi-next/v2/${ObjectURI.TrackProtections}/`, newTrackProtection)
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<TrackProtection, Partial<TrackProtection>, ThunkApiConfig>(
  'trackProtection/updateById',
  async (updatedTrackProtection, thunkApi) => {
    try {
      const response: TrackProtection = await patch(
        `/midi-next/v2/${ObjectURI.TrackProtections}/${updatedTrackProtection.id}`,
        filterFields<TrackProtection>(updatedTrackProtection, getUpdatedFields(TRACK_PROTECTION_ATTRIBUTES())),
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'trackProtection/deleteById',
  async (id, thunkApi) => {
    try {
      await deleteRequest(`/midi-next/v2/${ObjectURI.TrackProtections}/${id}`)
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<TrackProtection, ValidationParams, ThunkApiConfig>(
  'trackProtection/validate',
  async ({ id, operation }, thunkApi) => {
    try {
      const response: TrackProtection = await post(
        `/midi-next/v2/${ObjectURI.TrackProtections}/${id}/${operation}/`,
        {},
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const TrackProtectionServices = {
  getDetails,
  getGeometry,
  create,
  update,
  validate,
  delete: deleteObject,
  addExtremity,
  deleteExtremity,
  switchExtremityDirection,
  addResponsibleSubArea,
  deleteResponsibleSubArea,
  addAssociatedSubArea,
  deleteAssociatedSubArea,
  addIncompatibleObject,
  deleteIncompatibleObject,
}

export default TrackProtectionServices
