import { ReactElement } from 'react'

import { TrackProtection } from 'objects/types/protections'
import ContextMenu, { ContextMenuItem } from 'components/Common/ContextMenu/ContextMenu'
import { store } from 'Store'
import TrackProtectionGroupServices from '../TrackProtectionGroupServices'

const COMPOSITION_CONTEXT_MENU_ITEMS = (tp: TrackProtection): ContextMenuItem[] => [
  {
    key: 'delete',
    label: 'Details.groupCompositionContextMenu.delete',
    onClick: () => { store.dispatch(TrackProtectionGroupServices.deleteTrackProtection(tp.id)) },
    show: true,
  },
]

type Props = {
  tp: TrackProtection;
}

export default function CompositionContextMenu({ tp }: Props): ReactElement {
  return <ContextMenu menuItems={COMPOSITION_CONTEXT_MENU_ITEMS(tp)} />
}
