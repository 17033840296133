import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { MapEvent, Popup } from 'react-map-gl'
import { useTranslation } from '@osrdata/app_core/dist/translation'

import Paper from '@mui/material/Paper'

import { GeoEditorState } from 'reducers/geoEditor'
import { RootState } from 'Store'

import { MapLayerObject } from '../utils'
import { getTitle, getObjectPopupIcon } from '../popupUtils'
import './HoverPopup.scss'

type Props = {
  event: MapEvent | undefined;
}

export default function HoverPopup({ event }: Props): ReactElement | null {
  const item = event && event.features
    ? event.features[0].properties
    : undefined
  const { t } = useTranslation()
  const { active: isGeoEditorActive } = useSelector((state: RootState): GeoEditorState => state.TIVEditor)

  if (!event || event?.features === undefined || !item || isGeoEditorActive) return null

  const coords = event?.lngLat

  return (
    <div id="hover-popup">
      <Popup
        longitude={coords[0]}
        latitude={coords[1]}
        closeButton={false}
        className="popup-wrapper"
        tipSize={0}
        anchor="bottom"
      >
        <Paper className="popup d-flex">
          <div className="pr-1">
            <img src={getObjectPopupIcon(event.features[0] as MapLayerObject)} alt="track icon" className="icon" />
          </div>
          <div className="d-flex align-items-center">
            <div className="title">{t(getTitle(event.features[0], t))}</div>
          </div>
        </Paper>
      </Popup>
    </div>
  )
}
