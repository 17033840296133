import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement } from 'react'
import get from 'lodash/get'

import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'

import { Attribute } from 'objects/attributes'
import { PanelName } from 'reducers/panels/panel'
import { MidiObject } from 'objects/types'
import Parser from 'objects/parser'
import { ObjOfStr, ObjOfStrOrNum, StrOrNum } from 'types'

import editButton from 'assets/icons/edit_button.svg'
import PanelNavigator from '../PanelNavigator'
import './Attributes.scss'

type Props = {
  attributes: Array<Attribute>;
  item: MidiObject;
}

export default function Attributes({ item, attributes }: Props): ReactElement {
  const { t } = useTranslation()
  const onClickModify = () => {
    PanelNavigator.push(PanelName.modification)
  }

  const displayData = (rawData: ObjOfStrOrNum & StrOrNum, att: Attribute) => (att.parser
    ? att.parser(rawData)
    : rawData
  )

  const attributeLine = (o: MidiObject | ObjOfStr, att: Attribute) => {
    const formattedValue = displayData(get(o, att.path), att) as string
    return formattedValue === '' && att.hideIfEmpty
      ? null
      : (
        <ListItem className="d-flex justify-space-between" key={att.key} dense>
          <div className="key" style={{ flex: 1 }}>{t(att.label)}</div>
          <div className="value text-right" style={{ flex: 1 }}>
            {t(formattedValue)}
          </div>
        </ListItem>
      )
  }

  // Going through each array item, each item has its own attributes definition,
  // in the `elements` props of the parent attribute
  const renderNestedArray = (att: Attribute) => {
    if (get(item, att.path) !== undefined) {
      return get(item, att.path).map((el: ObjOfStr) => (
        <>
          {att.elements?.map(nestedAtt => attributeLine(el, nestedAtt))}
          <Divider className="my-2 nested-array-divider" light />
        </>
      ))
    }
    return null
  }

  return (
    <div className="attributes-container">
      <List className="list">
        {attributes.filter(a => !a.hideDetails).map(att => (att.isArray
          ? renderNestedArray(att)
          : attributeLine(item, att)
        ))}
      </List>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="m-0 last-modification">
          {`Dernière modification le ${Parser.date(item.updateDate)}`}
        </h3>
        <IconButton onClick={onClickModify} className="modify-button">
          <img src={editButton} alt="edit button" />
        </IconButton>
      </div>
    </div>
  )
}
