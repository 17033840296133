import MAIN_API from 'config/config'
import { InstructionState } from 'reducers/instruction'
import { MapState } from 'reducers/map'
import { SubnetState } from 'reducers/subnet'
import { FieldValue } from 'reducers/types'
import { store } from 'Store'
import {
  nestedObject, ObjOfStrOrNum, StrOrNum, StrOrNumOrBool,
} from 'types'
import { Attribute } from './attributes'
import { NULL_STRING } from './types/const'
import { BooleanType } from './types/protections/const'

const isObjShortTrack = (o: nestedObject): boolean => (
  (((o.line !== undefined) && (o.line as nestedObject)?.code !== undefined)
  || o.lineCode !== undefined)
  && (o.name !== undefined || o.trackName !== undefined)
)

export const formatLineChoice = (l: ObjOfStrOrNum | string | undefined): string => (
  l && typeof l !== 'string'
    ? `${l.code} - ${l.label}`
    : '')

export const formatTrackChoice = (o: nestedObject | undefined): string => (
  o && isObjShortTrack(o)
    ? `${o.lineCode || (o.line as nestedObject).code} Voie ${o.name || o.trackName}`
    : '')

export const formatExtremitySignalChoice = (o: nestedObject | undefined): string => (
  o && o.label
    ? `Signal ${o.label}`
    : 'Signal')

export const formatExtremityIsolatorChoice = (): string => 'Joint de Zone'
export const formatExtremityTrackChoice = (): string => 'Voie'

export const formatIdentityChoice = (v: ObjOfStrOrNum | string | undefined): string => (
  v && typeof v === 'string'
    ? v
    : ''
)

export const fieldValueToString = (v: StrOrNumOrBool | null): StrOrNum => {
  switch (v) {
    case null:
      return NULL_STRING
    case false:
      return BooleanType.False
    case true:
      return BooleanType.True
    default:
      return v
  }
}

export const parseStringFieldValue = (v: FieldValue): FieldValue => {
  switch (v) {
    case NULL_STRING:
      return null
    case BooleanType.False:
    case BooleanType.True:
      return JSON.parse(v)
    default:
      return v
  }
}

export function getUpdatedFields<T>(attributes: Attribute[]): Array<keyof T> {
  return [
    ...attributes.filter(att => att.isEditable).map(att => att.key), 'checksum',
  ] as Array<keyof T>
}

export function filterFields<T>(
  partial: Partial<T>, fields: Array<keyof T>, excludedFields: Array<keyof T> = [],
): Partial<T> {
  return (Object.keys(partial) as Array<keyof T>)
    .filter(key => fields.includes(key) && !excludedFields.includes(key))
    .reduce((obj, key) => {
      const value = partial[key]
      if (value !== undefined) {
        return {
          ...obj,
          [key]: value,
        }
      }
      return obj
    }, {})
}

export const formatLayerUrl = (sourceLayer: string): string => {
  const { selectedSubnet } = store.getState().map as MapState
  const { subnet } = store.getState().subnet as SubnetState
  const { instruction } = store.getState().instruction as InstructionState

  let layerUrl = `${
    MAIN_API.proxy
  }/chartis/v2/layer/${sourceLayer}/mvt/sch/?`

  if (instruction.applicationDate !== undefined) {
    layerUrl += `activityStartDate__lte_or_null=${
      instruction.applicationDate
    }&activityEndDate__gte_or_null=${
      instruction.applicationDate
    }`
  }

  if (selectedSubnet !== undefined) {
    layerUrl += `&subnetId=${selectedSubnet.id}`
  } else if (subnet && subnet.originalSubnet !== undefined) {
    layerUrl += `&subnetId=${subnet.originalSubnet}`
  }

  return layerUrl
}
