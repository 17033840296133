import { useTranslation } from '@osrdata/app_core/dist/translation'
import { useDispatch, useSelector } from 'react-redux'
import { ReactElement } from 'react'

import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp'
import { SxProps } from '@mui/material'

import SimpleButton from 'components/Common/SimpleButton/SimpleButton'
import { InstructionState, setActiveSubStep } from 'reducers/instruction'
import InstructionServices from 'services/InstructionServices'
import { RootState } from 'Store'
import getCSSValue from 'helpers'
import './SelectionFulfilled.scss'
import { Polygon } from 'geojson'

const editButtonStyle: SxProps = {
  backgroundColor: 'transparent',
  color: 'white',
  border: 'solid 2px rgba(255, 255, 255, 0.1)',
  maxWidth: '360px',
  borderRadius: '23px',
  boxShadow: 'none',
  paddingTop: '12px',
}

const continueButtonStyle: SxProps = {
  backgroundColor: 'white',
  color: getCSSValue('--color-blue-900'),
  maxWidth: '360px',
  borderRadius: '23px',
  boxShadow: 'none',
  paddingTop: '12px',
}

export default function SelectionFulfilled(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { instruction, items } = useSelector((state: RootState) => state.instruction) as InstructionState

  return (
    <div id="selection-fulfilled" className="blue-background w-100 h-100">
      <div className="w-100 d-flex justify-content-center algin-items-center h-100 flex-column text-center">
        <div className="pb-2">
          <CheckCircleSharpIcon className="check-icon" />
        </div>
        <div>
          <h2>
            <strong>
              {items.length}
              {' '}
              {t('Instruction.creation.itemSelection.objects')}
            </strong>
            {' '}
            {t('Instruction.creation.itemSelection.foundInPerimeter')}
          </h2>
        </div>
      </div>
      <div className="button-wrapper d-flex justify-content-around align-items-center w-100 mb-4">
        <div className="w-75 d-flex">
          <SimpleButton
            customStyle={editButtonStyle}
            title={t('Instruction.button.editObjectsList')}
            onClick={() => {
              if (instruction.id) {
                dispatch(InstructionServices.updateItems({
                  id: instruction.id, items, geom: instruction.geom as unknown as Polygon,
                }))
                dispatch(setActiveSubStep(3))
              }
            }}
          />
          <SimpleButton
            customStyle={continueButtonStyle}
            title={t('Instruction.button.continue')}
            onClick={() => {
              if (instruction.id) {
                dispatch(InstructionServices.addItems({
                  id: instruction.id, items, geom: instruction.geom as unknown as Polygon,
                }))
                dispatch(setActiveSubStep(4))
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}
