import { DateTime } from 'luxon'
import { Attribute, InputTypes } from 'objects/attributes'
import LineServices from 'objects/Lines/LineServices'

import { TrackTypeMap } from 'objects/types/const'
import { formatLineChoice } from 'objects/utils'
import Parser from 'objects/parser'

const TRACK_ATTRIBUTES = (defaultValues?: {[key: string]: string | undefined}): Array<Attribute> => ([
  {
    key: 'name',
    path: 'name',
    label: 'Attributes.Track.name',
    isEditable: true,
    required: true,
    parser: Parser.identity,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'type',
    path: 'type',
    label: 'Attributes.Track.type',
    parser: Parser.trackType,
    isEditable: true,
    required: true,
    type: InputTypes.Select,
    values: TrackTypeMap,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'line',
    label: 'Attributes.Track.lineCode',
    path: ['line', 'code'],
    updatePath: 'line',
    isEditable: true,
    type: InputTypes.Autocomplete,
    autoCompleteParams: {
      key: 'lines',
      displayedValueFormatter: formatLineChoice,
      source: LineServices.getAll,
    },
    parser: Parser.identity,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'activityStartDate',
    path: 'activityStartDate',
    label: 'Attributes.common.activityStartDate',
    parser: Parser.date,
    isEditable: true,
    required: true,
    type: InputTypes.DatePicker,
    checkboxLabel: 'Attributes.common.activityStartDateCheckbox',
    creation: {
      required: true,
      defaultValue: defaultValues?.activityStartDate || DateTime.now().toISODate(),
    },
  },
  {
    key: 'activityEndDate',
    path: 'activityEndDate',
    label: 'Attributes.common.activityEndDate',
    parser: Parser.date,
    isEditable: true,
    required: true,
    type: InputTypes.DatePicker,
    checkboxLabel: 'Attributes.common.activityEndDateCheckbox',
    creation: {
      required: true,
      defaultValue: null,
    },
  },
])

export default TRACK_ATTRIBUTES
