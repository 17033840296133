import { DateTime } from 'luxon'
import { ObjectLayer, DirectionTypeMap } from 'objects/types/const'
import { InputTypes, Attribute, INDEX_PARAM_PLACEHOLDER } from 'objects/attributes'
import { KP } from 'objects/types/common'
import { formatIdentityChoice } from 'objects/utils'
import Parser from 'objects/parser'
import { ALL_SIGNAL_TYPES } from './SignalTypes'

const NEW_EMPTY_KP: KP = {
  lineCode: '',
  trackIdentificationSystem: '',
  trackName: '',
  value: '',
}

const SIGNAL_ATTRIBUTES = (defaultValues?: {[key: string]: string | undefined}): Array<Attribute> => ([
  {
    key: 'kilometricPoints',
    path: 'kilometricPoints',
    label: 'Attributes.common.kp',
    parser: Parser.identity,
    isEditable: true,
    isArray: true,
    groupTitle: 'Attributes.Signal.positions',
    elements: [
      {
        key: 'trackName',
        path: 'trackName',
        label: 'Attributes.common.track',
        parser: Parser.identity,
        type: InputTypes.CaptureClick,
        isEditable: true,
        required: true,
        creation: {
          required: true,
          defaultValue: '',
        },
        captureClickHelper: 'Modification.helperText.trackCaptureClick',
        captureClickParams: {
          updateParams: [
            {
              clickedObjectPath: 'trackName',
              path: ['kilometricPoints', INDEX_PARAM_PLACEHOLDER, 'trackName'],
            },
            {
              clickedObjectPath: 'lineCode',
              path: ['kilometricPoints', INDEX_PARAM_PLACEHOLDER, 'lineCode'],
            },
            {
              clickedObjectPath: 'id',
              path: ['kilometricPoints', INDEX_PARAM_PLACEHOLDER, 'trackIdentificationSystem'],
            },
          ],
          objectLayer: ObjectLayer.TrackIdentificationSystem,
        },
      },
      {
        key: 'kp',
        path: 'value',
        updatePath: ['kilometricPoints', INDEX_PARAM_PLACEHOLDER, 'value'],
        label: 'Attributes.common.kp',
        parser: Parser.identity,
      },
    ],
    defaultElement: NEW_EMPTY_KP,
    required: true,
    creation: {
      required: true,
      defaultValue: [NEW_EMPTY_KP],
    },
  },
  {
    key: 'label',
    path: 'label',
    label: 'Attributes.common.label',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'type',
    path: 'type',
    label: 'Attributes.Signal.type',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    type: InputTypes.Autocomplete,
    autoCompleteParams: {
      key: 'signalTypes',
      displayedValueFormatter: formatIdentityChoice,
      options: ALL_SIGNAL_TYPES.sort(),
    },
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'readingDirection',
    path: 'readingDirection',
    label: 'Attributes.Signal.direction',
    parser: Parser.directionType,
    isEditable: true,
    required: true,
    type: InputTypes.Select,
    values: DirectionTypeMap,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'activityStartDate',
    path: 'activityStartDate',
    label: 'Attributes.common.activityStartDate',
    parser: Parser.date,
    isEditable: true,
    required: true,
    type: InputTypes.DatePicker,
    checkboxLabel: 'Attributes.common.activityStartDateCheckbox',
    creation: {
      required: true,
      defaultValue: defaultValues?.activityStartDate || DateTime.now().toISODate(),
    },
  },
  {
    key: 'activityEndDate',
    path: 'activityEndDate',
    label: 'Attributes.common.activityEndDate',
    parser: Parser.date,
    isEditable: true,
    required: true,
    type: InputTypes.DatePicker,
    checkboxLabel: 'Attributes.common.activityEndDateCheckbox',
    creation: {
      required: true,
      defaultValue: null,
    },
  },
])

export default SIGNAL_ATTRIBUTES
