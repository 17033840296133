import { ReactElement } from 'react'

import { ContextMenuItem } from 'components/Common/ContextMenu/ContextMenu'
import { User as CerbereUser } from 'services/cerbereTypes'
import { store } from 'Store'
import { formatDate, formatRelativeDate, formatStatus } from 'views/admin/HomeTab/utils'
import { Subnet } from 'objects/types/subnets'
import SubnetServices from 'services/SubnetServices'
import { toggleOpenModal } from 'reducers/subnet'

type Formatter<T, P, R> = (t: T, p: R) => P

type Column = {
  title: string;
  propertyName: keyof Subnet;
  formatter: Formatter<unknown, string | number | ReactElement, CerbereUser[]>;
}

export const COLUMNS: Column[] = [
  {
    title: 'Subnets.dashboard.mySubnets',
    propertyName: 'label',
    formatter: (o): string => o as string,
  },
  {
    title: 'Subnets.dashboard.lastUpdate',
    propertyName: 'updateDate',
    formatter: d => formatRelativeDate(d as string),
  },
  {
    title: 'Subnets.dashboard.creationDate',
    propertyName: 'creationDate',
    formatter: d => formatDate(d as string),
  },
  {
    title: 'Statut',
    propertyName: 'taskStatus',
    formatter: (o): ReactElement => formatStatus(o as string),
  },
]

export const SUBNET_CONTEXT_MENU = (subnet: Subnet): ContextMenuItem[] => [
  {
    key: 'update',
    label: 'Modifier',
    onClick: () => {
      store.dispatch(SubnetServices.getDetails(subnet.slug))
      store.dispatch(toggleOpenModal())
    },
    show: true,
  },
  {
    key: 'delete',
    label: 'Supprimer',
    onClick: () => { store.dispatch(SubnetServices.delete(subnet.slug)) },
    show: true,
  },
]
