import { ReactElement, useEffect, useState } from 'react'
import { MapEvent } from 'react-map-gl'
import { useSelector } from 'react-redux'

import TrackSectionsLayer from 'objects/TrackSections/TrackSectionsLayer'
import DirectionsLayer from 'objects/TrackSections/DirectionsLayer'
import TracksLayer from 'objects/Tracks/TracksLayer'
import NamesLayer from 'objects/Tracks/NamesLayer'
import IsolatorsLayer from 'objects/Isolators/IsolatorsLayer'
import { findObjectKind, KIND_TO_LAYER } from 'objects/kind'
import { MidiObject, ShortMidiObject } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import { MapState } from 'reducers/map'
import { RootState } from 'Store'
import SignalsLayer from 'objects/Signals/SignalsLayer'
import SimpleLineLayer from 'objects/common/SimpleLineLayer'
import FeedersLayer from 'objects/Feeders/FeedersLayer'
import SimpleNameLayer from 'objects/common/SimpleNameLayer'
import HoverLayer from '../Hover/HoverLayer'

type Props = {
  hoveredEvent: MapEvent | undefined;
};

type NameLayerParam = {
  layer: ObjectLayer;
  textOffset: [number, number];
}

// This array is sorted, layers order matters
const LINE_LAYERS = [
  ObjectLayer.TrackIdentificationSystem,
  ObjectLayer.TrackProtectionGroup, ObjectLayer.TrackProtection,
  ObjectLayer.ElectricalProtectionGroup, ObjectLayer.Sector, ObjectLayer.SubSector,
  ObjectLayer.ElectricalElement,
]

const NAME_LAYERS: NameLayerParam[] = [
  {
    layer: ObjectLayer.TrackProtection,
    textOffset: [0, 1],
  },
  {
    layer: ObjectLayer.ElectricalElement,
    textOffset: [0, -1],
  },
]

export default function ObjectLayers({ hoveredEvent }: Props): ReactElement {
  const { layers, hoveredPanelObject } = useSelector(
    (state: RootState): MapState => state.map,
  )
  const { item } = useSelector((state: RootState) => state.detailsPanel)

  const [hoveredObject, setHoveredObject] = useState<
    MidiObject | ShortMidiObject | undefined
  >(undefined)
  const [hoveredLayer, setHoveredLayer] = useState<ObjectLayer | undefined>(
    undefined,
  )

  useEffect(() => {
    if (hoveredEvent && hoveredEvent.features !== undefined) {
      const newHoveredObject: MidiObject = hoveredEvent.features[0].properties
      setHoveredObject(newHoveredObject)
      setHoveredLayer(hoveredEvent.features[0].sourceLayer)
    } else if (hoveredPanelObject) {
      setHoveredObject(hoveredPanelObject)
      const kind = findObjectKind(hoveredPanelObject)
      setHoveredLayer(KIND_TO_LAYER[kind])
    } else {
      setHoveredObject(undefined)
      setHoveredLayer(undefined)
    }
  }, [hoveredEvent, hoveredPanelObject])

  const getHoveredSourceLayer = () => {
    if (hoveredLayer) return hoveredLayer
    if (item) return KIND_TO_LAYER[findObjectKind(item)]
    return undefined
  }

  return (
    <>
      <DirectionsLayer />
      <TracksLayer
        visible={layers.includes(ObjectLayer.Track)}
      />
      <TrackSectionsLayer
        visible={layers.includes(ObjectLayer.TrackSection)}
      />
      {LINE_LAYERS.map(layer => (
        <SimpleLineLayer
          key={layer}
          visible={layers.includes(layer)}
          layer={layer}
          item={item}
        />
      ))}
      <FeedersLayer
        visible={layers.includes(ObjectLayer.Feeder)}
      />
      <IsolatorsLayer
        visible={layers.includes(ObjectLayer.Isolator)}
      />
      <SignalsLayer
        visible={layers.includes(ObjectLayer.Signal)}
      />
      <HoverLayer
        item={hoveredObject || item}
        sourceLayer={getHoveredSourceLayer()}
      />
      <NamesLayer />
      {NAME_LAYERS.map(({ layer, textOffset }) => (
        <SimpleNameLayer
          key={layer}
          visible={layers.includes(layer)}
          layer={layer}
          textOffset={textOffset}
        />
      ))}
    </>
  )
}
