/* eslint-disable @typescript-eslint/no-explicit-any */
import { patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ShortProtection, ElectricalProtectionGroup } from 'objects/types/protections'
import { ThunkApiConfig } from 'types'
import { store } from 'Store'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import ObjectURI from 'objects/uri'

export const addIncompatibleObject = createAsyncThunk<ElectricalProtectionGroup, ShortProtection, ThunkApiConfig>(
  'electricalProtectionGroup/addIncompatibleObject',
  async (newIncompatibleObject, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, incompatibleProtections } = detailsPanelState.item as ElectricalProtectionGroup

    const updatedElectricalProtectionGroup = {
      id,
      checksum,
      incompatibleProtections: [...(incompatibleProtections || []), {
        id: newIncompatibleObject.id,
      }],
    }

    try {
      const response: ElectricalProtectionGroup = await patch(
        `/midi-next/v2/${ObjectURI.ElectricalProtectionGroups}/${updatedElectricalProtectionGroup.id}`,
        updatedElectricalProtectionGroup,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteIncompatibleObject = createAsyncThunk<ElectricalProtectionGroup, string, ThunkApiConfig>(
  'electricalProtectionGroup/deleteIncompatibleObject',
  async (deletedTpId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, incompatibleProtections } = detailsPanelState.item as ElectricalProtectionGroup

    const updatedElectricalElement: Partial<ElectricalProtectionGroup> = {
      id,
      checksum,
      incompatibleProtections: incompatibleProtections.filter(tp => tp.id !== deletedTpId),
    }

    try {
      const response: ElectricalProtectionGroup = await patch(
        `/midi-next/v2/${ObjectURI.ElectricalProtectionGroups}/${updatedElectricalElement.id}`,
        updatedElectricalElement,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
