import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AdminState } from 'reducers/admin'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import {
  SubnetState, setActiveStep, setSubnetOrigin,
} from 'reducers/subnet'
import { RootState } from 'Store'
import './OriginSubnet.scss'
import CustomSelect from 'components/Common/Inputs/CustomSelect/CustomSelect'
import { Subnet } from 'objects/types/subnets'
import { DEFAULT_SUBNET_SLUG } from 'config/config'
import { setSelectedSubnet } from 'reducers/map'

type Props = {
  title: string;
  subtitle?: string;
}

const defaultProps = {
  subtitle: undefined,
}

const getDefaultSubnet = (subnetsList: Subnet[]) => (
  subnetsList.find(s => s.slug === DEFAULT_SUBNET_SLUG) || subnetsList[0]
)

export default function OriginSubnet({
  title, subtitle,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { subnetsList } = useSelector((state: RootState) => state.admin) as AdminState
  const { subnet, activeStep } = useSelector((state: RootState) => state.subnet) as SubnetState
  const [selectedSubnetId, setSelectedSubnetId] = useState(
    subnet.originalSubnet || getDefaultSubnet(subnetsList)?.id || '',
  )

  useEffect(() => {
    if (subnet.originalSubnet === undefined) {
      dispatch(setSubnetOrigin(getDefaultSubnet(subnetsList)?.id || ''))
    }
  }, [])

  return (
    <div id="origin-subnet" className="d-flex w-100 flex-column h-100">
      <div className="colored-title purple d-flex justify-content-center align-items-center">
        <div className="w-50 mx-auto">
          <div className="title-wrapper d-flex justify-content-center text-center flex-column">
            <h1>{t(title)}</h1>
            {subtitle && <h3>{t(subtitle)}</h3>}
          </div>
          <div className="w-50 mx-auto">
            <CustomSelect
              field={{
                key: 'subnetLabel',
                path: 'label',
                label: 'Représentation de départ',
                values: subnetsList.map(s => ({
                  label: s.label,
                  value: s.id,
                })),
                parser: s => s,
              }}
              onChange={v => {
                setSelectedSubnetId(v as string)
                dispatch(setSubnetOrigin(v as string))
                dispatch(setSelectedSubnet(subnetsList.find(s => s.id === v as string)))
              }}
              value={selectedSubnetId}
            />
          </div>
        </div>
      </div>
      <div className="blank-footer" />
      <div className="button-wrapper w-100 d-flex justify-content-center algin-items-center">
        <SimpleButton
          disabled={subnet.label === undefined || subnet.label.length === 0}
          style={ButtonStyle.primary}
          title={t('Instruction.button.continue')}
          onClick={() => { dispatch(setActiveStep(activeStep + 1)) }}
        />
      </div>
    </div>
  )
}

OriginSubnet.defaultProps = defaultProps
