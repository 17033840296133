import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { createNewObject, setInitialValues } from 'reducers/panels/creationPanel'
import PanelNavigator from 'components/Panels/PanelNavigator'
import { PanelName } from 'reducers/panels/panel'
import { ObjectKind } from 'objects/types/const'
import { useDispatch, useSelector } from 'react-redux'
import { ReactElement } from 'react'
import { RootState } from 'Store'
import { Track } from 'objects/types'

export default function CreateTISButton(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { item }: { item: Track } = useSelector((state: RootState) => state.detailsPanel)

  return (
    <div className="px-4 mb-1">
      <SimpleButton
        title={t('Details.button.createTIS')}
        onClick={() => {
          dispatch(createNewObject(ObjectKind.TrackIdentificationSystem))
          dispatch(setInitialValues([
            {
              path: 'track',
              value: item,
            },
          ]))
          PanelNavigator.push(PanelName.creation)
        }}
        style={ButtonStyle.light}
      />
    </div>
  )
}
