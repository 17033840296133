/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Feature, LineString } from 'geojson'
import { ObjectLayer } from 'objects/types/const'
import { ValidationParams } from 'components/Panels/DetailsPanel/utils'
import { TrackSection } from 'objects/types'
import { store } from 'Store'
import { nestedObject, ObjOfStrOrNum, ThunkApiConfig } from 'types'
import ObjectURI from 'objects/uri'
import { KP } from 'objects/types/common'
import { filterFields, getUpdatedFields } from 'objects/utils'
import { MapState } from 'reducers/map'
import TRACK_SECTION_ATTRIBUTES from './TrackSectionAttributes'

export const fetchTSGeometryHelper = async (
  id: string, subnetId: string,
): Promise<Feature<LineString, TrackSection>> => {
  const response: Feature<LineString, TrackSection> = await get(
    `/chartis/v2/layer/${ObjectLayer.TrackSection}/geojson_feature/sch/`, { id, subnetId },
  )
  return response as Feature<LineString, TrackSection>
}

export const fetchTSDetailsHelper = async (id: string): Promise<TrackSection> => (
  get(`/midi-next/v2/${ObjectURI.TrackSections}/${id}`)
)

const getDetails = createAsyncThunk<TrackSection, string, ThunkApiConfig>(
  'trackSection/getDetails',
  async (id: string, thunkApi) => {
    try {
      return await fetchTSDetailsHelper(id)
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<LineString, TrackSection>, string, ThunkApiConfig>(
  'trackSection/getGeometry',
  async (id, thunkApi) => {
    const { selectedSubnet } = store.getState().map as MapState
    try {
      return await fetchTSGeometryHelper(id, selectedSubnet?.id || '')
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<TrackSection, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'trackSection/create',
  async (newTrackSection: ObjOfStrOrNum | nestedObject, thunkApi) => {
    const newGeom = store.getState().TIVEditor?.object?.geometry
    const trackSectionWithGeom = { ...newTrackSection, geom: newGeom }
    try {
      const trackSection: TrackSection = await post(`/midi-next/v2/${ObjectURI.TrackSections}/`, trackSectionWithGeom)
      return trackSection
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const formatKP = (rawKP: KP | undefined) => ({
  value: rawKP !== undefined ? rawKP.value : undefined,
  trackIdentificationSystem: rawKP !== undefined ? rawKP.trackIdentificationSystem : undefined,
})

const update = createAsyncThunk<TrackSection, TrackSection, ThunkApiConfig>(
  'trackSection/updateById',
  async (updatedTrackSection, thunkApi) => {
    const { startKp, endKp } = updatedTrackSection
    const payload: Partial<TrackSection> = { // skip KP id to force new KP creation in back-end
      ...filterFields(updatedTrackSection, getUpdatedFields(TRACK_SECTION_ATTRIBUTES()), ['track']),
      startKp: formatKP(startKp),
      endKp: formatKP(endKp),
    }
    try {
      const trackSection: TrackSection = await patch(
        `/midi-next/v2/${ObjectURI.TrackSections}/${updatedTrackSection.id}/`, payload,
      )
      return trackSection
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'trackSection/deleteById',
  async (id: string, thunkApi) => {
    try {
      await deleteRequest(`/midi-next/v2/${ObjectURI.TrackSections}/${id}`)
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<TrackSection, ValidationParams, ThunkApiConfig>(
  'trackSection/validate',
  async ({ id, operation }, thunkApi) => {
    try {
      const response: TrackSection = await post(`/midi-next/v2/${ObjectURI.TrackSections}/${id}/${operation}/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const TrackSectionServices = {
  getDetails,
  getGeometry,
  create,
  update,
  validate,
  delete: deleteObject,
}

export default TrackSectionServices
