import {
  replace as replaceAction,
  goBack as goBackAction,
  push as pushAction,
  PanelName,
  PanelState,
  setPanelHistory,
} from 'reducers/panels/panel'
import { store } from 'Store'

const push = (panel: PanelName): void => {
  const { panelHistory } = store.getState().panel as PanelState
  const [lastPanel] = panelHistory.slice(-1)

  if (lastPanel === panel) {
    store.dispatch(replaceAction(panel))
  } else if (panelHistory.includes(panel)) {
    const newHistory = panelHistory.filter(p => p !== panel)
    store.dispatch(setPanelHistory([...newHistory, panel]))
  } else {
    store.dispatch(pushAction(panel))
  }
}

const goBack = (): void => {
  store.dispatch(goBackAction())
}

const replace = (panel: PanelName): void => {
  store.dispatch(replaceAction(panel))
}

const PanelNavigator = {
  push, goBack, replace,
}

export default PanelNavigator
