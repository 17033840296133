/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Feature, Geometry } from 'geojson'
import { Signal } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import { ValidationParams } from 'components/Panels/DetailsPanel/utils'
import ObjectURI from 'objects/uri'
import { nestedObject, ObjOfStrOrNum, ThunkApiConfig } from 'types'

export const fetchSignalDetails = async (id: string): Promise<Signal> => {
  const response: Signal = await get(`/midi-next/v2/${ObjectURI.Signals}/${id}`)
  return response
}

const getDetails = createAsyncThunk<Signal, string, ThunkApiConfig>(
  'signal/getDetails',
  async (id: string, thunkApi) => {
    try {
      return await fetchSignalDetails(id)
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<Geometry, Signal>, string, ThunkApiConfig>(
  'signal/getGeometry',
  async (id: string, thunkApi) => {
    try {
      const response: Feature<Geometry, Signal> = await get(
        `/chartis/v2/layer/${ObjectLayer.Signal}/geojson_feature/sch/`,
        { id },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<Signal, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'signal/create',
  async (newSignal: ObjOfStrOrNum | nestedObject, thunkApi) => {
    try {
      const response: Signal = await post(`/midi-next/v2/${ObjectURI.Signals}/`, newSignal)
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<Signal, Signal, ThunkApiConfig>(
  'signal/updateById',
  async (updatedSignal: Signal, thunkApi) => {
    try {
      const response: Signal = await patch(`/midi-next/v2/${ObjectURI.Signals}/${updatedSignal.id}`, updatedSignal)
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'signal/deleteById',
  async (id: string, thunkApi) => {
    try {
      await deleteRequest(`/midi-next/v2/${ObjectURI.Signals}/${id}`)
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<Signal, ValidationParams, ThunkApiConfig>(
  'signal/validate',
  async ({ id, operation }, thunkApi) => {
    try {
      const response: Signal = await post(`/midi-next/v2/${ObjectURI.Signals}/${id}/${operation}/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const SignalServices = {
  getDetails,
  getGeometry,
  create,
  update,
  validate,
  delete: deleteObject,
}

export default SignalServices
