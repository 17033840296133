import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'

import Loader from 'components/Common/Loader'
import AreaServices from 'services/AreaServices'
import { AreasState, setSubAreaLoading } from 'reducers/areas'
import { Area } from 'objects/types/protections'
import getCSSValue from 'helpers'
import { RootState } from 'Store'

import './SubAreas.scss'
import SubAreaLine from './SubAreaLine'

type Props = {
  areaId: string;
  subAreas: Area[];
}

export default function SubAreas({ areaId, subAreas: subareas }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { subAreasStates: dataFetchers } = useSelector((state: RootState) => state.areas) as AreasState

  const [isLoading, setIsLoading] = useState(false)
  const [displayNewline, setDisplayNewline] = useState(false)

  useEffect(() => {
    const associatedDf = dataFetchers.find(df => df.areaId === areaId)
    if (associatedDf) {
      setIsLoading(associatedDf.loading)
      if (!associatedDf.loading) setDisplayNewline(false)
    }
  }, [dataFetchers])

  const onAddClick = () => {
    if (!displayNewline) {
      setDisplayNewline(true)
    }
  }

  if (isLoading) return <Loader />

  return (
    <div className="subareas">
      <div className="d-flex justify-content-between align-items-center">
        <div className="title">
          {t('Areas.subAreasTitle')}
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <IconButton onClick={() => onAddClick()}>
            <AddIcon htmlColor={getCSSValue('--color-carbone')} />
          </IconButton>
        </div>
      </div>
      <div className="subareas-list">
        {subareas.map(subarea => (
          <SubAreaLine
            key={subarea.id}
            subarea={subarea}
            onBlur={e => {
              if (e.target.value !== '' && subarea.label !== e.target.value) {
                dispatch(AreaServices.updateSubArea({
                  id: areaId,
                  subArea: {
                    ...subarea,
                    label: e.target.value,
                  },
                }))
              }
            }}
            onRemove={() => (dispatch(AreaServices.deleteSubArea({
              id: areaId,
              subArea: subarea,
            })))}
          />
        ))}
        {displayNewline && (
          <SubAreaLine
            onBlur={e => {
              if (e.target.value !== '') {
                dispatch(setSubAreaLoading(areaId))
                dispatch(AreaServices.addSubArea({
                  id: areaId,
                  subArea: {
                    label: e.target.value,
                  },
                }))
              } else {
                setDisplayNewline(false)
              }
            }}
            onRemove={() => setDisplayNewline(false)}
          />
        )}
      </div>
    </div>
  )
}
