import {
  ReactElement, RefObject, useEffect, useState,
} from 'react'
import { Editor } from 'react-map-gl-draw'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from '@osrdata/app_core/dist/translation'

import Snackbar from '@mui/material/Snackbar'

import { MapRef } from 'react-map-gl'
import { GeoEditorState, hideGeoEditor } from 'reducers/geoEditor'
import { RootState } from 'Store'
import GeoEditorTools from './GeoEditorTools'
import EditorLayer from './EditorLayer'
import SnappingLayer from './SnappingLayer'

type Props = {
  geoEditorRef: RefObject<Editor> | null;
  mapRef: RefObject<MapRef>;
}

export default function GeoEditor({ geoEditorRef, mapRef }: Props): ReactElement | null {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { active, object } = useSelector((state: RootState): GeoEditorState => state.TIVEditor)
  const [errorOpen, setErrorOpen] = useState(true)

  useEffect(() => {
    if (object && object?.geometry.type !== 'LineString') setErrorOpen(true)
  }, [object])

  if (object && object?.geometry.type !== 'LineString') {
    dispatch(hideGeoEditor())
    return (
      <Snackbar
        open={errorOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={5000}
        onClose={() => setErrorOpen(false)}
        message={t('GeoEditor.invalidGeometry')}
      />
    )
  }

  return active
    ? (
      <>
        <GeoEditorTools />
        <EditorLayer editorRef={geoEditorRef} />
        <SnappingLayer mapRef={mapRef} />
      </>
    ) : null
}
