import drawMouse from 'assets/icons/draw_mouse.svg'
import grabMouse from 'assets/icons/grab_hand.svg'
import bin from 'assets/icons/bin.svg'
import { Modes } from '../utils'

export type Tool = {
  key: string;
  label: string;
  icon: string;
  mode: Modes;
  onClick?: () => void;
}

const TOOLS: Tool[] = [
  {
    key: 'draw',
    label: 'Dessiner',
    icon: drawMouse,
    mode: Modes.draw,
  },
  {
    key: 'grab',
    label: 'Déplacer',
    icon: grabMouse,
    mode: Modes.grab,
  },
  {
    key: 'bin',
    label: 'Supprimer un point',
    icon: bin,
    mode: Modes.deletePoint,
  },
]

export default TOOLS
