import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from '@osrdata/app_core/dist/translation'

import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'

import { PanelName, PanelState } from 'reducers/panels/panel'
import SimpleButton from 'components/Common/SimpleButton/SimpleButton'
import { RootState, store } from 'Store'
import {
  disableGeoEditor, GeoEditorState, updateGeoEditorMode,
} from 'reducers/geoEditor'

import close from 'assets/icons/close.svg'

import TOOLS from './tools'
import './GeoEditorTools.scss'
import GeoEditorService from '../GeoEditorService'
import { Modes, updateCursor } from '../utils'

const changeMode = (newMode: Modes) => {
  store.dispatch(updateGeoEditorMode(newMode))
  updateCursor(newMode)
}

export default function GeoEditorTools(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { mode, object } = useSelector((state: RootState): GeoEditorState => state.TIVEditor)
  const { panelHistory } = useSelector((state: RootState): PanelState => state.panel)

  const onClose = () => {
    dispatch(disableGeoEditor())
  }

  const onClick = () => {
    if (object && object.properties) {
      dispatch(GeoEditorService.updateTrackSectionGeometry(object.geometry))
      onClose()
    }
  }

  const isSelected = (m?: Modes) => (m === mode ? 'selected' : '')
  const showValidateButton = object && panelHistory[panelHistory.length - 1] !== PanelName.creation
  const showCloseButton = panelHistory[panelHistory.length - 1] !== PanelName.creation

  return (
    <div className="geoeditor-tools d-flex justify-content-center align-items-center">
      <Paper className="button-group d-flex align-items-center justify-content-center">
        {TOOLS.map(tool => (
          <IconButton
            key={tool.key}
            className={`${'icon-button mx-1 '}${isSelected(tool.mode)}`}
            onClick={() => changeMode(tool.mode)}
          >
            <img src={tool.icon} alt={tool.key} />
          </IconButton>
        ))}
        {showCloseButton && (
          <>
            <Divider orientation="vertical" className="mx-1 divider" sx={{ height: 28, m: 0.5, color: '#F2F2F2' }} />
            <IconButton
              key="close"
              className="icon-button mx-1"
              onClick={onClose}
            >
              <img src={close} alt="close button" />
            </IconButton>
          </>
        )}
      </Paper>
      {showValidateButton && (
        <div className="px-2">
          <SimpleButton title={t('GeoEditor.saveGeometry')} onClick={onClick} />
        </div>
      )}
    </div>
  )
}
