/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Instruction } from 'objects/types/instructions'
import URI from 'services/uri'
import { ThunkApiConfig } from 'types'
import {
  addItems, getItemsInBbox, getItemsDetailsList, updateItems, deleteItem,
} from './items'

const getAll = createAsyncThunk<Instruction[], undefined, ThunkApiConfig>(
  'instruction/getAll',
  async (_unused, thunkApi) => {
    try {
      const response = await get(`/midi-next/v2/${URI.instructions}/`)
      return response.results as Instruction[]
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getDetails = createAsyncThunk<Instruction, string, ThunkApiConfig>(
  'instruction/getDetails',
  async (id: string, thunkApi) => {
    try {
      const response: Instruction = await get(`/midi-next/v2/${URI.instructions}/${id}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const addUsers = createAsyncThunk<Instruction, Partial<Instruction>, ThunkApiConfig>(
  'instruction/addUsers',
  async (instruction, thunkApi) => {
    try {
      const response: Instruction = await patch(
        `/midi-next/v2/${URI.instructions}/${instruction.id}`,
        { users: instruction.users },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<Instruction, Partial<Instruction>, ThunkApiConfig>(
  'instruction/create',
  async (newInstruction, thunkApi) => {
    try {
      const response: Instruction = await post(`/midi-next/v2/${URI.instructions}/`, newInstruction)
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const UPDATABLE_FIELDS: Array<keyof Instruction> = [
  'name', 'reference', 'version', 'applicationDate',
]

const update = createAsyncThunk<Instruction, Partial<Instruction>, ThunkApiConfig>(
  'instruction/update',
  async (newInstruction, thunkApi) => {
    let filteredInstruction: Partial<Instruction> = {
      id: newInstruction.id,
    }
    filteredInstruction = Object.keys(newInstruction)
      .filter(key => UPDATABLE_FIELDS.includes(key as keyof Instruction))
      .reduce((obj, key) => {
        const value = newInstruction[key as keyof Instruction]
        if (value !== undefined) {
          return {
            ...obj,
            [key as keyof Instruction]: value,
          }
        }
        return obj
      }, filteredInstruction)
    try {
      const response: Instruction = await patch(
        `/midi-next/v2/${URI.instructions}/${newInstruction.id}`,
        filteredInstruction,
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'instruction/delete',
  async (id, thunkApi) => {
    try {
      await deleteRequest(`/midi-next/v2/${URI.instructions}/${id}`)
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const InstructionServices = {
  getAll,
  getDetails,
  create,
  update,
  delete: deleteObject,
  addItems,
  updateItems,
  addUsers,
  getItemsInBbox,
  getItemsDetailsList,
  deleteItem,
}

export default InstructionServices
