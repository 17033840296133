import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import Attributes from 'components/Panels/DetailsPanel/Attributes'
import { Isolator } from 'objects/types'
import { RootState } from 'Store'

import ISOLATOR_ATTRIBUTES from './IsolatorAttributes'

export default function IsolatorDetails(): ReactElement | null {
  const { item: isolator }: { item: Isolator } = useSelector((state: RootState) => state.detailsPanel)
  return (
    <div id="isolator-details" className="object-attributes">
      <Attributes item={isolator} attributes={ISOLATOR_ATTRIBUTES()} />
    </div>
  )
}
