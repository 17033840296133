import ButtonStatus from 'components/Common/buttonStatus'
import ElectricalElementServices from 'objects/ElectricalElements/ElectricalElementServices'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'
import IsolatorServices from 'objects/Isolators/IsolatorServices'
import { findObjectKind } from 'objects/kind'
import SectorServices from 'objects/Sectors/SectorServices'
import SignalServices from 'objects/Signals/SignalServices'
import SubSectorServices from 'objects/SubSectors/SubSectorServices'
import TrackIdentificationSystemServices from 'objects/TrackIdentificationSystems/TISServices'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import TrackServices from 'objects/Tracks/TrackServices'
import FeederServices from 'objects/Feeders/FeederServices'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'
import { ShortMidiObject } from 'objects/types'
import { createFulfilledDisplayedObjMatcher } from 'reducers/matchers/createMatchers'
import {
  getDetailsErrorMatcher, getDetailsFulfilledMatcher, getDetailsPendingMatcher,
} from 'reducers/matchers/getDetailsMatchers'
import { getGeomErrorMatcher, getGeomPendingMatcher } from 'reducers/matchers/getGeomMatchers'
import {
  updateEELinkedObjectsErrorMatcher, updateEELinkedObjectsFulfilledMatcher,
  updateEELinkedObjectsPendingMatcher, updateSectorLinkedObjectsErrorMatcher,
  updateSectorLinkedObjectsFulfilledMatcher, updateSectorLinkedObjectsPendingMatcher,
  updateSubSectorLinkedObjectsErrorMatcher,
  updateSubSectorLinkedObjectsFulfilledMatcher, updateSubSectorLinkedObjectsPendingMatcher,
  updateTPGLinkedObjectsErrorMatcher, updateTPGLinkedObjectsFulfilledMatcher,
  updateTPGLinkedObjectsPendingMatcher, updateTPLinkedObjectsErrorMatcher,
  updateEPGLinkedObjectsPendingMatcher, updateEPGLinkedObjectsErrorMatcher,
  updateEPGLinkedObjectsFulfilledMatcher,
  updateTPLinkedObjectsFulfilledMatcher, updateTPLinkedObjectsPendingMatcher,
  updateFeederLinkedObjectsFulfilledMatcher, updateFeederLinkedObjectsPendingMatcher,
  updateFeederLinkedObjectsErrorMatcher,
} from 'reducers/matchers/update'
import {
  validationErrorMatcher, validationFulfilledMatcher, validationPendingMatcher,
} from 'reducers/matchers/validationMatchers'

import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { CollapsibleMenu } from 'reducers/types'

export interface DetailsPanelState {
  item: ShortMidiObject | undefined;
  isLoading: boolean;
  shouldRefresh: boolean;
  buttonStatus: ButtonStatus;
  openMenus: CollapsibleMenu[];
}

const initialState: DetailsPanelState = {
  item: undefined,
  isLoading: true,
  shouldRefresh: false,
  buttonStatus: ButtonStatus.Base,
  openMenus: [],
}

const updateLocalItem = (state: DetailsPanelState, action: PayloadAction<ShortMidiObject | undefined>) => {
  state.item = action.payload
  state.isLoading = false
  state.shouldRefresh = false
}

export const detailsPanelSlice = createSlice({
  name: 'detailsPanel',
  initialState,
  reducers: {
    updateItem: updateLocalItem,
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    shouldRefresh: (state, action: PayloadAction<boolean>) => {
      state.shouldRefresh = action.payload
    },
    addOpenMenu: (state, action: PayloadAction<CollapsibleMenu>) => {
      if (!state.openMenus.includes(action.payload)) state.openMenus.push(action.payload)
    },
    removeOpenMenu: (state, action: PayloadAction<CollapsibleMenu>) => {
      if (state.openMenus.includes(action.payload)) {
        state.openMenus = state.openMenus.filter(om => om !== action.payload)
      }
    },
    resetOpenMenus: state => {
      state.openMenus = []
    },
  },
  extraReducers: builder => {
    builder.addCase(TrackSectionServices.update.fulfilled, (state, action) => {
      if (state.item && findObjectKind(state.item) === findObjectKind(action.payload)) {
        updateLocalItem(state, action)
      } else {
        state.shouldRefresh = true
      }
    })

    builder.addMatcher(isAnyOf(
      getDetailsFulfilledMatcher, createFulfilledDisplayedObjMatcher,
      TrackServices.update.fulfilled, ElectricalElementServices.update.fulfilled,
      SectorServices.update.fulfilled, SubSectorServices.update.fulfilled,
    ), updateLocalItem)

    builder.addMatcher(isAnyOf(
      TrackIdentificationSystemServices.delete.fulfilled,
      TrackIdentificationSystemServices.update.fulfilled,
      TrackIdentificationSystemServices.create.fulfilled,
      IsolatorServices.update.fulfilled,
      SignalServices.update.fulfilled,
      TrackProtectionServices.update.fulfilled,
      TrackProtectionServices.addExtremity.fulfilled,
      updateTPLinkedObjectsFulfilledMatcher,
      TrackProtectionGroupServices.update.fulfilled,
      TrackProtectionGroupServices.addExtremity.fulfilled,
      updateTPGLinkedObjectsFulfilledMatcher,
      updateEPGLinkedObjectsFulfilledMatcher,
      ElectricalElementServices.addExtremity.fulfilled,
      ElectricalProtectionGroupServices.addExtremity.fulfilled,
      ElectricalProtectionGroupServices.update.fulfilled,
      FeederServices.addExtremity.fulfilled,
      FeederServices.update.fulfilled,
      updateFeederLinkedObjectsFulfilledMatcher,
      updateEELinkedObjectsFulfilledMatcher,
      updateSectorLinkedObjectsFulfilledMatcher,
      updateSubSectorLinkedObjectsFulfilledMatcher,
    ), state => {
      state.shouldRefresh = true
    })

    builder.addMatcher(isAnyOf(
      getDetailsPendingMatcher, getGeomPendingMatcher,
      updateTPLinkedObjectsPendingMatcher, updateTPGLinkedObjectsPendingMatcher,
      updateEPGLinkedObjectsPendingMatcher, updateFeederLinkedObjectsPendingMatcher,
      updateEELinkedObjectsPendingMatcher, updateSectorLinkedObjectsPendingMatcher,
      updateSubSectorLinkedObjectsPendingMatcher,
    ), state => { state.isLoading = true })

    builder.addMatcher(isAnyOf(
      getDetailsErrorMatcher, getGeomErrorMatcher,
      updateTPLinkedObjectsErrorMatcher, updateTPGLinkedObjectsErrorMatcher,
      updateEPGLinkedObjectsErrorMatcher, updateFeederLinkedObjectsErrorMatcher,
      updateEELinkedObjectsErrorMatcher, updateSectorLinkedObjectsErrorMatcher,
      updateSubSectorLinkedObjectsErrorMatcher,
    ), state => { state.isLoading = false })

    builder.addMatcher(isAnyOf(
      validationFulfilledMatcher,
    ), state => { state.buttonStatus = ButtonStatus.Base })

    builder.addMatcher(isAnyOf(
      validationPendingMatcher,
    ), state => {
      state.buttonStatus = ButtonStatus.Loading
    })
    builder.addMatcher(isAnyOf(
      validationErrorMatcher,
    ), state => {
      state.buttonStatus = ButtonStatus.Base
    })
  },
})

export const {
  updateItem, setLoading, shouldRefresh, addOpenMenu, removeOpenMenu, resetOpenMenus,
} = detailsPanelSlice.actions

export default detailsPanelSlice.reducer
