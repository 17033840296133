import { DateTime } from 'luxon'
import { Attribute, InputTypes } from 'objects/attributes'
import { ObjectLayer } from 'objects/types/const'
import Parser from 'objects/parser'
import { formatTrackChoice } from 'objects/utils'

const TRACK_IDENTIFICATION_SYSTEM_ATTRIBUTES = (
  defaultValues?: {[key: string]: string | undefined},
): Array<Attribute> => ([
  {
    key: 'track',
    path: 'track',
    label: 'Attributes.common.track',
    parser: Parser.identity,
    type: InputTypes.CaptureClick,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: '',
    },
    captureClickHelper: 'Modification.helperText.trackCaptureClick',
    captureClickParams: {
      updateParams: [{
        clickedObjectPath: undefined, // Meaning we put all the object
        path: 'track',
      }],
      objectLayer: ObjectLayer.Track,
      displayedValueFormatter: formatTrackChoice,
    },
  },
  {
    key: 'startKp',
    path: 'startKp',
    label: 'Attributes.common.startKp',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'endKp',
    path: 'endKp',
    label: 'Attributes.common.endKp',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'activityStartDate',
    path: 'activityStartDate',
    label: 'Attributes.common.activityStartDate',
    parser: Parser.date,
    isEditable: true,
    required: true,
    type: InputTypes.DatePicker,
    checkboxLabel: 'Attributes.common.activityStartDateCheckbox',
    creation: {
      required: true,
      defaultValue: defaultValues?.activityStartDate || DateTime.now().toISODate(),
    },
  },
  {
    key: 'activityEndDate',
    path: 'activityEndDate',
    label: 'Attributes.common.activityEndDate',
    parser: Parser.date,
    isEditable: true,
    required: true,
    type: InputTypes.DatePicker,
    checkboxLabel: 'Attributes.common.activityEndDateCheckbox',
    creation: {
      required: true,
      defaultValue: null,
    },
  },
])

export default TRACK_IDENTIFICATION_SYSTEM_ATTRIBUTES
