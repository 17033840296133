import { DropResult } from 'react-beautiful-dnd'
import set from 'lodash/set'

import TrackSectionServices, { fetchTSDetailsHelper } from 'objects/TrackSections/TrackSectionServices'
import { setTisListLoading } from 'reducers/trackIdentificationSystemList'
import { TrackSection } from 'objects/types'
import { setError } from 'reducers/feedback'
import { store } from 'Store'

const onDragEnd = async (result: DropResult): Promise<void> => {
  if (!result.destination || result.destination.droppableId === result.source.droppableId) {
    return
  }

  const { destination, draggableId } = result

  const tsId = draggableId.split('/')[1]

  store.dispatch(setTisListLoading(true))
  try {
    let modifiedTS: TrackSection = await fetchTSDetailsHelper(tsId)
    modifiedTS = set(modifiedTS, ['startKp', 'trackIdentificationSystem'], destination.droppableId)
    modifiedTS = set(modifiedTS, ['endKp', 'trackIdentificationSystem'], destination.droppableId)
    store.dispatch(TrackSectionServices.update(modifiedTS))
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response) {
      store.dispatch(setError({
        data: e.response.data,
        code: e.response.status,
      }))
    }
    store.dispatch(setTisListLoading(false))
  }
}

export default onDragEnd
