/* eslint-disable react/destructuring-assignment */
import { ReactElement } from 'react'

import { createNewObject } from 'reducers/panels/creationPanel'
import { ObjectKind, ObjectLayer } from 'objects/types/const'
import { store } from 'Store'

import { ReactComponent as GeoEditorIcon } from 'assets/icons/geoeditor.svg'
import plusIcon from 'assets/icons/plus_light.svg'
import binIcon from 'assets/icons/bin.svg'
import trackLightIcon from 'assets/icons/track_light.svg'
import {
  switchToGeoCreator,
  switchToGeoEditor,
} from 'components/GeoEditor/utils'
import { PanelName } from 'reducers/panels/panel'
import PanelNavigator from 'components/Panels/PanelNavigator'
import { KIND_TO_LAYER } from 'objects/kind'
import { DeleteServiceOfKind } from 'objects/services'
import getCSSValue from 'helpers'
import { MapLayerObject } from '../utils'

type ContextMenuItemType = {
  key: string;
  label: string;
  layer?: ObjectLayer;
  icon: ReactElement;
  onClick: () => void;
  show?: boolean;
};

const CONTEXT_MENU_ITEMS = (object?: MapLayerObject): ContextMenuItemType[] => [
  {
    key: 'newTrack',
    layer: ObjectLayer.Track,
    label: 'Map.contextMenu.createTrack',
    icon: <img src={trackLightIcon} alt="plus icon" />,
    onClick: () => {
      store.dispatch(createNewObject(ObjectKind.Track))
      PanelNavigator.push(PanelName.creation)
    },
    show: true,
  },
  {
    key: 'newTrackSection',
    layer: ObjectLayer.TrackSection,
    label: 'Map.contextMenu.createTrackSection',
    icon: <img src={trackLightIcon} alt="plus icon" />,
    onClick: () => {
      switchToGeoCreator(object)
    },
    show: true,
  },
  {
    key: 'newIsolator',
    layer: ObjectLayer.Isolator,
    label: 'Map.contextMenu.createIsolator',
    icon: <img src={plusIcon} alt="plus icon" />,
    onClick: () => {
      store.dispatch(createNewObject(ObjectKind.Isolator))
      PanelNavigator.push(PanelName.creation)
    },
    show: !!object && object.sourceLayer === ObjectLayer.Track,
  },
  {
    key: 'newSignal',
    layer: ObjectLayer.Signal,
    label: 'Map.contextMenu.createSignal',
    icon: <img src={plusIcon} alt="plus icon" />,
    onClick: () => {
      store.dispatch(createNewObject(ObjectKind.Signal))
      PanelNavigator.push(PanelName.creation)
    },
    show: !!object && object.sourceLayer === ObjectLayer.Track,
  },
  {
    key: 'newTrackProtection',
    layer: ObjectLayer.TrackProtection,
    label: 'Map.contextMenu.createTrackProtection',
    icon: <img src={plusIcon} alt="plus icon" />,
    onClick: () => {
      store.dispatch(createNewObject(ObjectKind.TrackProtection))
      PanelNavigator.push(PanelName.creation)
    },
    show: !!object && object.sourceLayer === ObjectLayer.Track,
  },
  {
    key: 'newTrackProtectionGroup',
    layer: ObjectLayer.TrackProtectionGroup,
    label: 'Map.contextMenu.createTrackProtectionGroup',
    icon: <img src={plusIcon} alt="plus icon" />,
    onClick: () => {
      store.dispatch(createNewObject(ObjectKind.TrackProtectionGroup))
      PanelNavigator.push(PanelName.creation)
    },
    show: !!object && object.sourceLayer === ObjectLayer.Track,
  },
  {
    key: 'newElectricalElement',
    layer: ObjectLayer.ElectricalElement,
    label: 'Map.contextMenu.createElectricalElement',
    icon: <img src={plusIcon} alt="plus icon" />,
    onClick: () => {
      store.dispatch(createNewObject(ObjectKind.ElectricalElement))
      PanelNavigator.push(PanelName.creation)
    },
    show: !!object && object.sourceLayer === ObjectLayer.Track,
  },
  {
    key: 'newElectricalProtectionGroup',
    layer: ObjectLayer.ElectricalProtectionGroup,
    label: 'Map.contextMenu.createElectricalProtectionGroup',
    icon: <img src={plusIcon} alt="plus icon" />,
    onClick: () => {
      store.dispatch(createNewObject(ObjectKind.ElectricalProtectionGroup))
      PanelNavigator.push(PanelName.creation)
    },
    show: !!object && object.sourceLayer === ObjectLayer.Track,
  },
  {
    key: 'newSector',
    layer: ObjectLayer.Sector,
    label: 'Map.contextMenu.createSector',
    icon: <img src={plusIcon} alt="plus icon" />,
    onClick: () => {
      store.dispatch(createNewObject(ObjectKind.Sector))
      PanelNavigator.push(PanelName.creation)
    },
    show: !!object && object.sourceLayer === ObjectLayer.Track,
  },
  {
    key: 'newSubSector',
    layer: ObjectLayer.SubSector,
    label: 'Map.contextMenu.createSubSector',
    icon: <img src={plusIcon} alt="plus icon" />,
    onClick: () => {
      store.dispatch(createNewObject(ObjectKind.SubSector))
      PanelNavigator.push(PanelName.creation)
    },
    show: !!object && object.sourceLayer === ObjectLayer.Track,
  },
  {
    key: 'newFeeder',
    label: 'Map.contextMenu.createFeeder',
    icon: <img src={plusIcon} alt="plus icon" />,
    onClick: () => {
      store.dispatch(createNewObject(ObjectKind.Feeder))
      PanelNavigator.push(PanelName.creation)
    },
    show: !!object && object.sourceLayer === ObjectLayer.Track,
  },
  {
    key: 'geoEditor',
    label: 'Map.contextMenu.switchToGeoEditor',
    icon: <GeoEditorIcon fill={getCSSValue('--color-white')} />,
    onClick: () => {
      if (object) {
        switchToGeoEditor(object.properties.id)
      }
    },
    show:
      !!object
      && object.sourceLayer === ObjectLayer.TrackSection
      && !store.getState().TIVEditor.active,
  },
  {
    key: 'delete',
    label: 'Map.contextMenu.deleteObject',
    icon: <img src={binIcon} alt="bin icon" />,
    onClick: () => {
      if (object) {
        const { id } = object.properties
        const kind = Object.entries(KIND_TO_LAYER).find(
          ([, value]) => value === object.sourceLayer,
        )?.[0] as ObjectKind

        store.dispatch(DeleteServiceOfKind[kind](id))
      }
    },
    show: !!object,
  },
]

export default CONTEXT_MENU_ITEMS
