import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import { InstructionState, resetInstruction, toggleDisplayCreation } from 'reducers/instruction'
import { RootState } from 'Store'

import InstructionsTable from './InstructionsTable/InstructionsTable'
import InstructionCreation from '../../instructions/Creation/InstructionCreation'
import NewVersion from '../../instructions/Creation/NewVersion'
import './InstructionsTab.scss'

export default function InstructionsTab(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    displayCreation, displayNewVersion,
  } = useSelector((state: RootState) => state.instruction) as InstructionState
  return (
    <div id="instructions-tab" className="d-flex h-100">
      <div style={{ flex: 1 }}>
        <InstructionsTable />
      </div>
      <div className="button-wrapper w-100 d-flex justify-content-center align-items-center">
        <SimpleButton
          style={ButtonStyle.primary}
          title={t('Instruction.button.createNewInstruction')}
          onClick={() => {
            dispatch(resetInstruction())
            dispatch(toggleDisplayCreation())
          }}
        />
      </div>
      {displayCreation && <InstructionCreation />}
      {displayNewVersion && <NewVersion />}
    </div>
  )
}
