import { isAnyOf } from '@reduxjs/toolkit'
import IsolatorServices from 'objects/Isolators/IsolatorServices'
import SignalServices from 'objects/Signals/SignalServices'
import TrackIdentificationSystemServices from 'objects/TrackIdentificationSystems/TISServices'
import TrackServices from 'objects/Tracks/TrackServices'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'

export const validationErrorMatcher = isAnyOf(
  TrackSectionServices.validate.rejected,
  TrackServices.validate.rejected,
  IsolatorServices.validate.rejected,
  TrackIdentificationSystemServices.validate.rejected,
  SignalServices.validate.rejected,
  TrackProtectionServices.validate.rejected,
  TrackProtectionGroupServices.validate.rejected,
)

export const validationFulfilledMatcher = isAnyOf(
  TrackSectionServices.validate.fulfilled,
  TrackServices.validate.fulfilled,
  IsolatorServices.validate.fulfilled,
  TrackIdentificationSystemServices.validate.fulfilled,
  SignalServices.validate.fulfilled,
  TrackProtectionServices.validate.fulfilled,
  TrackProtectionGroupServices.validate.fulfilled,
)

export const validationPendingMatcher = isAnyOf(
  TrackSectionServices.validate.pending,
  TrackServices.validate.pending,
  IsolatorServices.validate.pending,
  TrackIdentificationSystemServices.validate.pending,
  SignalServices.validate.pending,
  TrackProtectionServices.validate.pending,
  TrackProtectionGroupServices.validate.pending,
)
