import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'

import { GeoEditorState } from 'reducers/geoEditor'
import { hoveredObjectColor } from 'objects/styles'
import { MidiObject, ShortMidiObject } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import { RootState } from 'Store'
import getCSSValue from 'helpers'
import { formatLayerUrl } from 'objects/utils'

type Props = {
  item: ShortMidiObject | MidiObject | undefined;
  sourceLayer: ObjectLayer | undefined;
}

export default function HoverLayer({ item, sourceLayer }: Props): ReactElement | null {
  const { active: isGeoEditorActive } = useSelector((state: RootState): GeoEditorState => state.TIVEditor)

  const getId = (): string => {
    if (item) {
      if (item.itemId) return item.itemId
      if (item.id) return item.id
      return ''
    }
    return ''
  }

  if (!isGeoEditorActive) {
    return (
      <>
        {Object.values(ObjectLayer).map(l => (
          <Source
            key={l}
            type="vector"
            url={formatLayerUrl(l)}
            source-layer={l}
            id={`hover-source-${l}`}
          >
            <Layer
              type="line"
              paint={{
                'line-color': getCSSValue(hoveredObjectColor),
                'line-width': 3,
              }}
              id={`hover-layer-${l}`}
              filter={['all', ['==', ['get', 'deletedAt'], null], ['==', 'id', getId()]]}
              source-layer={l}
              layout={{ visibility: l === sourceLayer ? 'visible' : 'none' }}
              source={sourceLayer}
            />
          </Source>
        ))}
      </>
    )
  }

  return null
}
