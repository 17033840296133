import { useDispatch, useSelector } from 'react-redux'
import { ReactElement, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'

import { setPageNumber, ListPanelState } from 'reducers/panels/listPanel'
import ObjectItem from 'components/ObjectItem/ObjectItem'
import { InstructionItem } from 'objects/types/instructions'
import { RootState } from 'Store'

import './ObjectsList.scss'

type Props = {
  items: InstructionItem[];
}

export default function ObjectsList({ items }: Props): ReactElement {
  const dispatch = useDispatch()
  const [hasMore, setHasMore] = useState(true)
  const { pageNumber, totalItemsCount } = useSelector((state: RootState): ListPanelState => state.listPanel)

  const getNext = () => {
    if (items.length >= totalItemsCount) {
      setHasMore(false)
      return
    }
    dispatch(setPageNumber(pageNumber + 1))
  }

  return (
    <div className="objects-list" id="scrollableDiv">
      <List sx={{ height: '100%', flex: 1, width: '100%' }}>
        <InfiniteScroll
          dataLength={items.length}
          next={getNext}
          hasMore={hasMore}
          loader={<></>}
          scrollableTarget="scrollableDiv"
        >
          {items.map(item => (
            <ListItem key={item.id}>
              <ObjectItem item={item} />
            </ListItem>
          ))}
        </InfiniteScroll>
      </List>
    </div>
  )
}
