import { DateTime } from 'luxon'
import { StrOrNum } from 'types'
import { ParserFunction } from './attributes'
import {
  DirectionType, DirectionTypeMap, KPOrientation, KPOrientationMap, TrackType, TrackTypeMap,
} from './types/const'
import { Protection } from './types/protections'
import {
  BooleanTypeMap, MGPTTypeMap, TrackProtectionGroupType, TrackProtectionGroupTypeMap,
  TrackProtectionType, TrackProtectionTypeMap, WorkTrainsTypeMap,
} from './types/protections/const'
import { fieldValueToString } from './utils'

const identity: ParserFunction<StrOrNum> = self => self

const dateParser: ParserFunction<string> = rawDate => {
  if (rawDate === null) return 'Attributes.common.noDate'
  if (DateTime.fromISO(rawDate).isValid) return DateTime.fromISO(rawDate).toFormat('dd/MM/yyyy')
  return rawDate
}

const trackTypeParser: ParserFunction<TrackType> = rawType => (
  TrackTypeMap.find(type => type.value === rawType)?.label || ''
)

const directionTypeParser: ParserFunction<DirectionType> = rawType => (
  DirectionTypeMap.find(type => type.value === rawType)?.label || ''
)

const trackProtectionTypeParser: ParserFunction<TrackProtectionType> = rawType => (
  TrackProtectionTypeMap.find(type => type.value === rawType)?.label || ''
)

const trackProtectionGroupTypeParser: ParserFunction<TrackProtectionGroupType> = rawType => (
  TrackProtectionGroupTypeMap.find(type => type.value === rawType)?.label || ''
)

const mgptParser: ParserFunction<StrOrNum> = rawMgpt => (
  MGPTTypeMap.find(type => type.value === fieldValueToString(rawMgpt))?.label || ''
)

const workTrainsParser: ParserFunction<boolean> = rawValue => (
  WorkTrainsTypeMap.find(type => type.value === String(rawValue))?.label || ''
)

const booleanParser: ParserFunction<boolean> = rawValue => (
  BooleanTypeMap.find(type => type.value === String(rawValue))?.label || ''
)

const orientationTypeParser: ParserFunction<KPOrientation> = rawValue => (
  KPOrientationMap.find(type => type.value === rawValue)?.label || ''
)

const sectorParser: ParserFunction<Protection> = p => p?.label || ''

const Parser = {
  boolean: booleanParser,
  date: dateParser,
  directionType: directionTypeParser,
  identity,
  orientationType: orientationTypeParser,
  mgpt: mgptParser,
  sector: sectorParser,
  trackProtectionType: trackProtectionTypeParser,
  trackProtectionGroupType: trackProtectionGroupTypeParser,
  trackType: trackTypeParser,
  workTrains: workTrainsParser,
}

export default Parser
