import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useState } from 'react'
import { useDispatch } from 'react-redux'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import 'components/ObjectItem/ObjectItem.scss'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import { TrackProtection } from 'objects/types/protections'
import ObjectTile from 'components/ObjectItem/ObjectTile'
import { shouldRefresh, updateItem } from 'reducers/panels/detailsPanel'
import { disableGeoEditor, hideGeoEditor } from 'reducers/geoEditor'
import PanelNavigator from 'components/Panels/PanelNavigator'
import { PanelName } from 'reducers/panels/panel'
import { CaptureMapClickParams } from 'objects/attributes'
import { ObjectLayer } from 'objects/types/const'
import { setCaptureClick, setLayers } from 'reducers/map'
import { setMessage } from 'reducers/feedback'
import CompositionContextMenu from './CompositionContextMenu'

type Props = {
  trackProtections: TrackProtection[];
}

const captureClickParams: CaptureMapClickParams = {
  updateParams: [
    {
      clickedObjectPath: 'id',
      path: ['composition'],
      addElement: true,
    },
  ],
  objectLayer: ObjectLayer.TrackProtection,
}

export default function Composition({ trackProtections }: Props): ReactElement | null {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isCapturingClick, setIsCapturingClick] = useState(false)

  if (!trackProtections) return null

  const onClick = () => {
    if (!isCapturingClick) {
      setIsCapturingClick(true)
      dispatch(hideGeoEditor())
      dispatch(setCaptureClick(captureClickParams))
      dispatch(setLayers([captureClickParams.objectLayer]))
      dispatch(setMessage('Modification.helperText.trackProtectionGroupCaptureClick'))
    } else {
      setIsCapturingClick(false)
      dispatch(setCaptureClick(undefined))
    }
  }

  return (
    <Accordion disableGutters>
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
        {`${t('Attributes.TrackProtectionGroup.composition')} (${
          trackProtections && trackProtections.length
        })`}
      </AccordionSummary>
      <AccordionDetails>
        {trackProtections && trackProtections.map(tp => (
          <ObjectTile
            key={tp.id}
            onClick={() => {
              dispatch(disableGeoEditor())
              dispatch(updateItem(tp))
              dispatch(shouldRefresh(true))
              PanelNavigator.push(PanelName.details)
            }}
            title={tp.label}
            subtitle={t('Object.type.trackProtection')}
            contextMenu={<CompositionContextMenu tp={tp} />}
          />
        ))}
        <div className="my-2 capture-button">
          <SimpleButton
            title={t('Details.button.addTrackProtectionToGroup')}
            onClick={onClick}
            style={ButtonStyle.light}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
