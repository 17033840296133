import arrow from 'assets/icons/arrow.png'
import arrowServ from 'assets/icons/arrowServ.png'
import arrowDeleted from 'assets/icons/arrowDeletedTransparent.png'
import isolatorIcon from 'assets/icons/isolator.png'
import feederIcon from 'assets/icons/feeder_icon.png'

export enum MapIconNames {
  main = 'arrow',
  serv = 'arrowServ',
  deleted = 'arrowDeleted',
  isolator = 'isolatorIcon',
  signal = 'signalIcon',
  feeder = 'feederIcon',
}

const MAP_ICONS = [
  {
    name: MapIconNames.main,
    image: arrow,
  },
  {
    name: MapIconNames.serv,
    image: arrowServ,
  },
  {
    name: MapIconNames.deleted,
    image: arrowDeleted,
  },
  {
    name: MapIconNames.isolator,
    image: isolatorIcon,
  },
  {
    name: MapIconNames.feeder,
    image: feederIcon,
  },
]

export default MAP_ICONS
