import { DateTime } from 'luxon'
import { InputTypes, Attribute } from 'objects/attributes'
import { NULL_STRING } from 'objects/types/const'
import Parser from 'objects/parser'
import { MGPTTypeMap, WorkTrainsTypeMap } from 'objects/types/protections/const'

const TRACK_PROTECTION_GROUP_ATTRIBUTES = (defaultValues?: {[key: string]: string | undefined}): Array<Attribute> => ([
  {
    key: 'label',
    path: 'label',
    label: 'Attributes.common.label',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'type',
    path: 'type',
    label: 'Attributes.TrackProtection.type',
    parser: Parser.trackProtectionGroupType,
  },
  {
    key: 'mgpt',
    path: 'mgpt',
    label: 'Attributes.TrackProtection.mgpt',
    parser: Parser.mgpt,
    isEditable: true,
    required: true,
    type: InputTypes.Select,
    values: MGPTTypeMap,
    creation: {
      required: true,
      defaultValue: NULL_STRING,
    },
  },
  {
    key: 'workTrainsAllowed',
    path: 'workTrainsAllowed',
    label: 'Attributes.TrackProtection.workTrains',
    parser: Parser.workTrains,
    isEditable: true,
    required: true,
    type: InputTypes.Select,
    values: WorkTrainsTypeMap,
    creation: {
      required: true,
      defaultValue: 'false',
    },
  },
  {
    key: 'activityStartDate',
    path: 'activityStartDate',
    label: 'Attributes.common.activityStartDate',
    parser: Parser.date,
    isEditable: true,
    required: true,
    type: InputTypes.DatePicker,
    checkboxLabel: 'Attributes.common.activityStartDateCheckbox',
    creation: {
      required: true,
      defaultValue: defaultValues?.activityStartDate || DateTime.now().toISODate(),
    },
  },
  {
    key: 'activityEndDate',
    path: 'activityEndDate',
    label: 'Attributes.common.activityEndDate',
    parser: Parser.date,
    isEditable: true,
    required: true,
    type: InputTypes.DatePicker,
    checkboxLabel: 'Attributes.common.activityEndDateCheckbox',
    creation: {
      required: true,
      defaultValue: null,
    },
  },
])

export default TRACK_PROTECTION_GROUP_ATTRIBUTES
