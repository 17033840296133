import { ReactElement } from 'react'

import ContextMenu, { ContextMenuItem } from 'components/Common/ContextMenu/ContextMenu'

import { setTisListLoading } from 'reducers/trackIdentificationSystemList'
import { updateModificationItem } from 'reducers/panels/modificationPanel'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import { Track, TrackIdentificationSystem } from 'objects/types'
import { ObjectKind, ObjectLayer } from 'objects/types/const'
import PanelNavigator from 'components/Panels/PanelNavigator'
import { PanelName } from 'reducers/panels/panel'
import { setLayers } from 'reducers/map'
import { store } from 'Store'

import { createNewObject, setInitialValues } from 'reducers/panels/creationPanel'
import { enableGeoEditor } from 'reducers/geoEditor'
import { setError } from 'reducers/feedback'
import TrackIdentificationSystemServices, { getTISDetailsRaw } from './TISServices'

const editTis = async (tis: TrackIdentificationSystem): Promise<void> => {
  const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
  const track = detailsPanelState.item as Track
  try {
    const tisDetails = await getTISDetailsRaw(tis.id)
    store.dispatch(updateModificationItem({
      ...tisDetails,
      track,
    }))
    PanelNavigator.push(PanelName.modification)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response) {
      store.dispatch(setError({
        data: e.response.data,
        code: e.response.status,
      }))
    }
  }
}

const deleteTis = (tis: TrackIdentificationSystem): void => {
  store.dispatch(setTisListLoading(true))
  store.dispatch(TrackIdentificationSystemServices.delete(tis.id))
}

const createTS = (tis: TrackIdentificationSystem): void => {
  const { item } = store.getState().detailsPanel as DetailsPanelState
  store.dispatch(createNewObject(ObjectKind.TrackSection))
  store.dispatch(setInitialValues([
    {
      path: ['track'],
      value: item as Track || tis,
    },
    {
      path: ['startKp', 'trackIdentificationSystem'],
      value: tis.id,
    },
    {
      path: ['endKp', 'trackIdentificationSystem'],
      value: tis.id,
    },
  ]))
  store.dispatch(enableGeoEditor())
  store.dispatch(setLayers([ObjectLayer.TrackIdentificationSystem]))
  PanelNavigator.push(PanelName.creation)
}

const TIS_CONTEXT_MENU_ITEMS = (tis: TrackIdentificationSystem): ContextMenuItem[] => {
  const { deletedAt, trackSections } = tis
  return [
    {
      key: 'createTS',
      label: 'Details.tisContextMenu.createTS',
      onClick: () => createTS(tis),
      show: true,
    },
    {
      key: 'editTIS',
      label: 'Details.tisContextMenu.edit',
      onClick: () => editTis(tis),
      show: true,
    },
    {
      key: 'deleteTIS',
      label: 'Details.tisContextMenu.delete',
      onClick: () => deleteTis(tis),
      show: deletedAt === null && trackSections.filter(ts => ts.deletedAt === null).length === 0,
    },
  ]
}

type Props = {
  tis: TrackIdentificationSystem;
}

export default function TISContextMenu({ tis }: Props): ReactElement {
  return <ContextMenu menuItems={TIS_CONTEXT_MENU_ITEMS(tis)} />
}
