import { useTranslation } from '@osrdata/app_core/dist/translation'
import { useDispatch, useSelector } from 'react-redux'
import { ReactElement } from 'react'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import 'components/ObjectItem/ObjectItem.scss'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import Parser from 'objects/parser'
import PanelNavigator from 'components/Panels/PanelNavigator'
import { createNewObject } from 'reducers/panels/creationPanel'
import { PanelName } from 'reducers/panels/panel'
import { ExtraKind } from 'objects/types/const'
import { Extremity } from 'objects/types/protections'
import ObjectTile from 'components/ObjectItem/ObjectTile'
import { GenericAction } from 'types'
import { RootState } from 'Store'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import { CollapsibleMenu } from 'reducers/types'
import ExtremityContextMenu from './ExtremityContextMenu'
import toggleOpenCollapsible from '../utils'

const getExtremityTitle = (extremity: Extremity): string => {
  if (extremity.extremityObject === null) {
    return 'Voie'
  } if (extremity?.lineIdentificationSystem) {
    return `Ligne (${extremity.value})`
  }
  return `${extremity.extremityObject.mainRepresentation}`
}

type Props = {
  type: ExtraKind;
  extremities: Extremity[];
  deleteExtremity: GenericAction<number>;
  switchOrientation?: GenericAction<number>;
}

const defaultProps = {
  switchOrientation: undefined,
}

export default function Extremities({
  type, extremities, deleteExtremity: deleteExtremityAction, switchOrientation,
}: Props): ReactElement | null {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { openMenus } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)

  if (!extremities) return null
  return (
    <Accordion
      disableGutters
      expanded={openMenus.includes(CollapsibleMenu.extremities)}
      onChange={() => toggleOpenCollapsible(CollapsibleMenu.extremities)}
    >
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
        {`${t('Attributes.TrackProtection.extremities')} (${extremities && extremities.length})`}
      </AccordionSummary>
      <AccordionDetails>
        {extremities.map(extremity => (
          <ObjectTile
            key={extremity.id}
            onClick={() => {}}
            title={getExtremityTitle(extremity)}
            subtitle={extremity.orientation ? String(Parser.orientationType(extremity.orientation)) : undefined}
            contextMenu={(
              <ExtremityContextMenu
                extremity={extremity}
                deleteAction={deleteExtremityAction}
                switchOrientationAction={switchOrientation}
              />
            )}
          />
        ))}
        <div className="my-2">
          <SimpleButton
            title={t('Details.button.addExtremity')}
            onClick={() => {
              dispatch(createNewObject(type))
              PanelNavigator.push(PanelName.creation)
            }}
            style={ButtonStyle.light}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

Extremities.defaultProps = defaultProps
