import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import Chip from '@mui/material/Chip'

import { getMainText } from 'components/ObjectItem/utils'
import { MidiObject } from 'objects/types'
import { RootState } from 'Store'
import './DetailsTitle.scss'

export default function DetailsTitle(): ReactElement {
  const { t } = useTranslation()
  const { item } = useSelector((state: RootState) => state.detailsPanel)

  return (
    <div className="details-title d-flex align-items-center flex-wrap">
      <div className="title">
        {getMainText(item)}
      </div>
      {(item as MidiObject).inConflict && (
        <Chip
          className="conflict"
          label={t('Details.title.inConflict')}
        />
      )}
      {(item as MidiObject).deletedAt && (
        <Chip
          className="deleted"
          label={t('Details.title.deleted')}
        />
      )}
    </div>
  )
}
