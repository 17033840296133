import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import ObjectTile from 'components/ObjectItem/ObjectTile'
import { InstructionState } from 'reducers/instruction'
import Loader from 'components/Common/Loader'
import { RootState } from 'Store'

type Props = {
  onDeleteItem: (id: string) => void;
}

export default function ItemsList({ onDeleteItem }: Props): ReactElement {
  const { t } = useTranslation()
  const { items, loading, totalItems } = useSelector((state: RootState) => state.instruction) as InstructionState

  const renderContent = () => {
    if (loading) {
      return (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Loader message="Chargement" />
        </div>
      )
    }
    if (totalItems === 0) {
      return (
        <div className="d-flex justify-content-center align-items-center h-100">
          <h2>{t('Instruction.creation.editList.noObject')}</h2>
        </div>
      )
    }
    return (items.map(item => (
      <ObjectTile
        key={item.itemId}
        onClick={() => {}}
        title={item.mainRepresentation || item.itemId || ''}
        subtitle={item.secondaryRepresentation || item.itemId || ''}
        contextMenu={(
          <IconButton onClick={() => onDeleteItem(String(item.id))}>
            <CloseIcon color="primary" />
          </IconButton>
        )}
      />
    )))
  }

  return (
    <div className="items-wrapper w-25 mx-auto d-flex flex-column">
      {renderContent()}
    </div>
  )
}
