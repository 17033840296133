import {
  FeatureWithProps, LineString, ExtendLineStringMode,
} from '@nebula.gl/edit-modes'
import { MidiObject } from 'objects/types'
import { RefObject } from 'react'
import {
  RENDER_STATE, SHAPE, Editor, EditingMode, DrawLineStringMode, DrawRectangleMode, DrawPolygonMode,
} from 'react-map-gl-draw'
import { Feature } from 'geojson'
import { Properties } from '@turf/helpers'

export type FeatureStyleType = {
  feature: Feature;
  state?: RENDER_STATE;
  shape?: SHAPE;
}

export type GeoEditorFeature = FeatureWithProps<LineString, MidiObject>;
export type LineStringFeature = Feature<LineString, Properties>

export enum EditorModeName {
  Edit = 'edit',
  Draw = 'draw',
  Select = 'select',
  Extend = 'extend',
  DrawPolygon = 'drawPolygon'
}

export const EDITOR_MODES = {
  [EditorModeName.Edit]: new EditingMode(),
  [EditorModeName.Draw]: new DrawLineStringMode(),
  [EditorModeName.Select]: new DrawRectangleMode(),
  [EditorModeName.Extend]: new ExtendLineStringMode(),
  [EditorModeName.DrawPolygon]: new DrawPolygonMode(),
}

export type EditorMode = EditingMode | DrawLineStringMode | DrawRectangleMode | ExtendLineStringMode | DrawPolygonMode

export type EditorLayerState = {
  editorRef: RefObject<Editor> | null;
}

export type EditContextObj = {
  featureIndexes?: number[];
  editHandleIndexes?: number[];
  screenCoords?: [number, number];
  mapCoords?: [number, number];
}

export type UpdateEvent = {
  editType: string;
  data: GeoEditorFeature[];
  editContext: EditContextObj;
}

export type SelectEvent = {
  selectedFeature: Feature | null;
  selectedFeatureIndex: number | null;
  selectedEditHandleIndex: number | null;
  screenCoords?: [number, number];
  mapCoords?: [number, number];
}

export type ModeConfig = {
  dragToDraw?: boolean;
  drawAtFront?: boolean;
}
