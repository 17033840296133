import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import Divider from '@mui/material/Divider'

import Attributes from 'components/Panels/DetailsPanel/Attributes'
import AssociatedSubAreas from 'objects/common/AssociatedSubAreas/AssociatedSubAreas'
import LinkedProtections from 'objects/common/LinkedProtections/LinkedProtections'
import ResponsibleSubAreas from 'objects/common/ResponsibleSubAreas/ResponsibleSubAreas'
import { SubSector } from 'objects/types/protections'
import { ITEM_TYPES } from 'objects/types/const'
import { RootState } from 'Store'

import { CollapsibleMenu } from 'reducers/types'
import SUB_SECTOR_ATTRIBUTES from './SubSectorAttributes'
import SubSectorServices from './SubSectorServices'

export default function SubSectorDetails(): ReactElement | null {
  const { item: subSector }: { item: SubSector } = useSelector((state: RootState) => state.detailsPanel)

  const {
    incompatibleProtections, associatedSubAreas, responsibleSubAreas, children,
  } = subSector

  return (
    <div id="sub-sectors-details" className="object-attributes">
      <Attributes item={subSector} attributes={SUB_SECTOR_ATTRIBUTES()} />
      <Divider className="my-2" />
      <div id="linked-objects">
        <LinkedProtections
          linkedProtections={children}
          deleteAction={SubSectorServices.deleteChild}
          addIPAction={SubSectorServices.addChild}
          params={{
            title: 'Attributes.Protection.children',
            searchPlaceholder: 'Details.searchPlaceholder.electricalProtections',
            buttonLabel: 'Details.button.addElectricalProtection',
            filteredTypes: [ITEM_TYPES.feeder, ITEM_TYPES.electricalelement],
          }}
          type={CollapsibleMenu.children}
        />
        <ResponsibleSubAreas
          responsibleSubAreas={responsibleSubAreas}
          deleteRSAAction={SubSectorServices.deleteResponsibleSubArea}
          addRSAAction={SubSectorServices.addResponsibleSubArea}
        />
        <AssociatedSubAreas
          associatedSubAreas={associatedSubAreas}
          deleteASAAction={SubSectorServices.deleteAssociatedSubArea}
          addASAAction={SubSectorServices.addAssociatedSubArea}
        />
        <LinkedProtections
          linkedProtections={incompatibleProtections}
          deleteAction={SubSectorServices.deleteIncompatibleObject}
          addIPAction={SubSectorServices.addIncompatibleObject}
          type={CollapsibleMenu.incompatibleObjects}
        />
      </div>
    </div>
  )
}
