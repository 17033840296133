import { useSelector } from 'react-redux'
import {
  ReactElement, useEffect, useState,
} from 'react'
import get from 'lodash/get'

import { InputTypes } from 'objects/attributes'
import { MapState } from 'reducers/map'
import { ResponseError } from 'types'
import { RootState } from 'Store'

import CustomAutoComplete from './CustomAutoComplete/CustomAutoComplete'
import CustomDatePicker from './CustomDatePicker/CustomDatePicker'
import { InputDataType, validateField } from './utils'
import CustomSelect from './CustomSelect/CustomSelect'
import CaptureClick from './CaptureClick/CaptureClick'
import CustomTextField from './CustomTextField'

export type GenericInputProps = InputDataType & {
  onFieldError: (k: string, v: boolean) => void;
  responseError: ResponseError | undefined;
}

export default function GenericInput({
  field, value, onChange, onFieldError, responseError, index, noLabel, disabled: disabledProp,
}: GenericInputProps): ReactElement {
  const { captureClick } = useSelector((state: RootState): MapState => state.map)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  // Handle local validation for this Attribute
  useEffect(() => {
    const errorText = validateField(field, value)
    if (errorText) {
      setErrorMessage(errorText)
      onFieldError(field.key, true)
    } else {
      setErrorMessage(undefined)
      onFieldError(field.key, false)
    }
  }, [value])

  // Handle response validation from back-end for this Attribute
  useEffect(() => {
    if (responseError && get(responseError.data, field.updatePath || field.path)) {
      const message = get(responseError.data, field.updatePath || field.path)
      // Handling nested nonFieldErrors
      if (message.nonFieldErrors) return
      setErrorMessage(message[0])
      onFieldError(field.key, true)
    }
  }, [responseError])

  const disabled = disabledProp || captureClick !== undefined

  switch (field.type) {
    case InputTypes.Select:
      return (
        <CustomSelect
          key={field.key}
          field={field}
          value={value}
          onChange={onChange}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          disabled={disabled}
        />
      )
    case InputTypes.DatePicker:
      return (
        <CustomDatePicker
          key={field.key}
          field={field}
          value={value}
          onChange={onChange}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          disabled={disabled}
        />
      )
    case InputTypes.Autocomplete:
      return (
        <CustomAutoComplete
          field={field}
          value={value}
          onChange={onChange}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          disabled={disabled}
        />
      )
    case InputTypes.CaptureClick:
      return (
        <CaptureClick
          field={field}
          value={value}
          onChange={onChange}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          index={index}
          noLabel={noLabel}
        />
      )
    default:
      return (
        <CustomTextField
          key={field.key}
          field={field}
          value={value}
          onChange={onChange}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          disabled={disabled}
          noLabel={noLabel}
        />
      )
  }
}
