import debounce from 'lodash/debounce'
import { Tab } from 'components/Common/TabsMenu/TabsMenu'
import { InstructionItem } from 'objects/types/instructions'
import {
  getZoneObjects,
  GetZoneObjectsParams,
} from 'reducers/panels/listPanel'
import MidiRole from 'services/midiRoleTypes'
import { store } from 'Store'
import { AppState } from 'reducers/app'
import { ObjectStatus } from 'objects/types/const'
import ObjectsList from './ObjectsList'

enum LISTPANEL_TAB_KEYS {
  all = 'all',
  toDo = 'toDo',
  validated = 'validated',
}

export const tabs = (items: InstructionItem[]): Tab[] => [
  {
    key: LISTPANEL_TAB_KEYS.all,
    value: '1',
    label: 'StatusTabs.allObjects',
    component: <ObjectsList items={items} />,
  },
  {
    key: LISTPANEL_TAB_KEYS.toDo,
    value: '2',
    label: 'StatusTabs.toDo',
    component: <ObjectsList items={items} />,
  },
  {
    key: LISTPANEL_TAB_KEYS.validated,
    value: '3',
    label: 'StatusTabs.toValidate',
    component: <ObjectsList items={items} />,
  },
]

export const refresh = (
  id: string,
  page: number,
  search: string,
  selectedTab: Tab,
  filterObjectType: string,
): void => {
  const { role } = store.getState().app as AppState
  const params: GetZoneObjectsParams = {
    id,
    page,
    search,
    filterObjectType,
  }
  if (role === MidiRole.operator) {
    switch (selectedTab.key) {
      case LISTPANEL_TAB_KEYS.toDo:
        params.statusLTE = ObjectStatus.IN_PROGRESS
        break
      case LISTPANEL_TAB_KEYS.validated:
        params.status = ObjectStatus.VALIDATED
        break
      case LISTPANEL_TAB_KEYS.all:
      default:
        params.statusLTE = ObjectStatus.VERIFIED
        break
    }
  } else if (role === MidiRole.supervisor) {
    switch (selectedTab.key) {
      case LISTPANEL_TAB_KEYS.toDo:
        params.status = ObjectStatus.VALIDATED
        break
      case LISTPANEL_TAB_KEYS.validated:
        params.status = ObjectStatus.VERIFIED
        break
      case LISTPANEL_TAB_KEYS.all:
      default:
        params.statusGTE = ObjectStatus.VALIDATED
        params.statusLTE = ObjectStatus.VERIFIED
        break
    }
  }
  store.dispatch(getZoneObjects(params))
}

export const debouncedRefresh = debounce(refresh, 500)
