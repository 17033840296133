import { PanelName, PanelState } from 'reducers/panels/panel'
import { store } from 'Store'

const getCSSValue = (name: string): string => getComputedStyle(document.documentElement).getPropertyValue(name)

export const getActivePanel = (): PanelName => {
  const { panelHistory } = store.getState().panel as PanelState
  return panelHistory[panelHistory.length - 1]
}

export const isStringEmpty = (s: string | undefined): boolean => (
  s === undefined || s.trim().length === 0
)

export default getCSSValue
