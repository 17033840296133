import { useTranslation } from '@osrdata/app_core/dist/translation'
import { Editor, DrawPolygonMode } from 'react-map-gl-draw'
import { Feature, Polygon } from '@nebula.gl/edit-modes'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MapGL, { MapRef } from 'react-map-gl'

import Button from '@mui/material/Button'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import {
  DEFAULT_VIEWPORT, MAX_ZOOM, refreshTiles, transformRequest,
} from 'components/Map/utils'
import { EditorMode, EditorModeName, EDITOR_MODES } from 'components/GeoEditor/types'
import InstructionServices from 'services/InstructionServices'
import { InstructionState, setActiveSubStep, setInstructionGeom } from 'reducers/instruction'
import mapStyle from 'components/Map/style_empty.json'
import TracksLayer from 'objects/Tracks/TracksLayer'
import NamesLayer from 'objects/Tracks/NamesLayer'
import { RootState } from 'Store'
import { InstructionType } from 'objects/types/instructions'
import { MapState, setLayersToUpdate } from 'reducers/map'
import Toolbar from 'components/Map/Toolbar/Toolbar'
import { ObjectLayer } from 'objects/types/const'

type UpdateEvent = {
  data: Feature[];
  editType: string;
}

type Props = {
  onClose: () => void;
}

export default function NewPerimeterMap({ onClose }: Props): ReactElement {
  const mapRef = useRef<MapRef>(null)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [viewport, setViewport] = useState(DEFAULT_VIEWPORT)
  const [bbox, setBbox] = useState<Feature | undefined>()
  const [mode, setMode] = useState<EditorMode>(EDITOR_MODES[EditorModeName.DrawPolygon])
  const { instruction } = useSelector((state: RootState) => state.instruction) as InstructionState
  const { selectedSubnet } = useSelector((state: RootState) => state.map) as MapState
  const [disableScroll, setDisableScroll] = useState(false)

  useEffect(() => {
    dispatch(setLayersToUpdate([ObjectLayer.Track, ObjectLayer.TrackSection]))
  }, [])

  useEffect(() => {
    refreshTiles(mapRef)
  }, [selectedSubnet])

  const onUpdate = (params: UpdateEvent) => {
    if (params.editType !== 'addTentativePosition' && params.editType !== 'updateTentativeFeature') {
      if (mode instanceof DrawPolygonMode) {
        setMode(EDITOR_MODES[EditorModeName.Edit])
      }
      setBbox(params.data[0])
    }
  }

  const isButtonDisabled = bbox === undefined

  return (
    <div id="new-perimeter" className="d-flex w-100 flex-column h-100">
      <div className="colored-title d-flex justify-content-center align-items-center">
        <div className="update-banner">
          <div className="d-flex justify-content-between align-items-center">
            <h1>{t('Instruction.creation.itemSelection.updateTitle')}</h1>
            <div className="return-wrapper">
              <Button variant="outlined" endIcon={<ArrowForwardIcon />} onClick={onClose}>
                <span>{t('Instruction.dashboard.parameters.closeNewPerimeter')}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100" style={{ flex: 1 }}>
        <MapGL
          {...viewport}
          ref={mapRef}
          transformRequest={transformRequest}
          maxZoom={MAX_ZOOM}
          width="100%"
          height="100%"
          mapStyle={mapStyle}
          onViewportChange={(newViewport: typeof DEFAULT_VIEWPORT) => { setViewport(newViewport) }}
          clickRadius={10}
          preventStyleDiffing
          scrollZoom={!disableScroll}
        >
          <TracksLayer visible />
          <NamesLayer />
          <Editor
            style={{ width: '100%', height: '100%' }}
            clickRadius={12}
            mode={mode}
            features={bbox ? [bbox] : undefined}
            onUpdate={onUpdate}
            editHandleShape="circle"
            selectedFeatureIndex={0}
            selectable
          />
          <Toolbar disableScroll={setDisableScroll} light />
        </MapGL>
      </div>
      <div className="button-wrapper w-100 d-flex justify-content-center algin-items-center">
        <SimpleButton
          disabled={isButtonDisabled}
          style={ButtonStyle.primary}
          title={t('Instruction.button.continue')}
          onClick={() => {
            dispatch(setInstructionGeom(bbox?.geometry as unknown as Polygon))
            dispatch(InstructionServices.getItemsInBbox({
              bpolygon: bbox?.geometry as Polygon,
              type: instruction.type as InstructionType,
              date: instruction.applicationDate || '',
              subnet_id: selectedSubnet?.id || '',
            }))
            dispatch(setActiveSubStep(1))
          }}
        />
      </div>
    </div>
  )
}
