import { Instruction } from 'objects/types/instructions'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  InstructionState, setInstructionDate, setInstructionName, setInstructionRef,
  setInstructionVersion, setInstructionVersionDate,
} from 'reducers/instruction'
import CustomTextField, { InputStyle } from 'views/admin/common/CustomTextField'
import InstructionServices from 'services/InstructionServices'
import { RootState } from 'Store'

import './GeneralInfo.scss'

const INPUTS = (instruction: Partial<Instruction>) => [
  {
    label: 'Instruction.creation.nameChoice.inputLabel',
    placeholder: 'Instruction.creation.nameChoice.inputLabel',
    value: instruction.name,
    updateFunction: setInstructionName,
  },
  {
    label: 'Instruction.creation.refChoice.refInput.label',
    placeholder: 'Instruction.creation.refChoice.refInput.placeholder',
    value: instruction.reference,
    updateFunction: setInstructionRef,
  },
  {
    label: 'Instruction.creation.refChoice.versionInput.label',
    placeholder: 'Instruction.creation.refChoice.versionInput.placeholder',
    value: instruction.version,
    updateFunction: setInstructionVersion,
  },
  {
    label: 'Instruction.creation.refChoice.versionDateInput.label',
    placeholder: 'Instruction.creation.refChoice.versionDateInput.placeholder',
    value: instruction.versionDate,
    updateFunction: setInstructionVersionDate,
    type: 'date',
  },
  {
    label: 'Instruction.creation.refChoice.dateInput.label',
    placeholder: 'Instruction.creation.refChoice.dateInput.placeholder',
    value: instruction.applicationDate,
    updateFunction: setInstructionDate,
    type: 'date',
  },
]

export default function GeneralInfo(): ReactElement {
  const dispatch = useDispatch()
  const { instruction } = useSelector((state: RootState) => state.instruction) as InstructionState

  return (
    <div id="general-info" className="d-flex align-items-center flex-column">
      <div className="w-75 pt-3">
        {INPUTS(instruction).map(input => (
          <div key={input.label} className="d-flex justify-content-center align-items-center">
            <div className="w-50 m-auto">
              <CustomTextField
                input={input}
                style={InputStyle.filled}
                onBlur={() => dispatch(InstructionServices.update(instruction))}
                dense
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
