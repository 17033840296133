import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import { setFilterObjectType } from 'reducers/panels/listPanel'
import Radio from '@mui/material/Radio'
import Divider from '@mui/material/Divider'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import IconButton from '@mui/material/IconButton'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import './ObjectsFilterMenu.scss'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { RootState } from 'Store'
import { InstructionState } from 'reducers/instruction'
import { InstructionType, LayersByInstructionType } from 'objects/types/instructions'
import { ALL_FILTER, editButtonStyle, OBJECTS_TYPE } from './const'

type Props = {
  displayFilters: boolean;
  onApply: () => void;
  onClickReturn: () => void;
};

export default function ObjectsFilterMenu({ displayFilters, onApply, onClickReturn }: Props): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { instruction } = useSelector((state: RootState): InstructionState => state.instruction)

  const [value, setValue] = useState('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setFilterObjectType(e.target.value))
    setValue(e.target.value)
  }

  const filterList = [
    ALL_FILTER,
    ...OBJECTS_TYPE.filter(
      object => LayersByInstructionType[instruction.type as InstructionType].includes(object.layer),
    ),
  ]

  return (
    <>
      <div id="filter-menu" className={`${displayFilters ? 'display' : ''}`}>
        <div className="d-flex align-items-center my-2">
          <IconButton onClick={onClickReturn} className="mr-3 ml-2">
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <span>{t('ObjectsFilterMenu.title')}</span>
        </div>
        <Divider
          className="ml-2"
          style={{ flex: 1, borderColor: 'var(--color-grey-800)' }}
        />
        <FormControl className="ml-3 my-3">
          <RadioGroup
            aria-labelledby="filters-radio-buttons-group-label"
            defaultValue=""
            name="radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            {filterList.map(type => (
              <div key={type.key} className="d-flex align-items-center">
                <FormControlLabel
                  value={type.key}
                  control={<Radio />}
                  label=""
                  className="mb-0"
                />
                <div className="title">{t(type.title)}</div>
              </div>
            ))}
          </RadioGroup>
        </FormControl>
        <SimpleButton
          title={t('Common.button.apply')}
          onClick={onApply}
          style={ButtonStyle.primary}
          customStyle={editButtonStyle}
        />
      </div>
    </>
  )
}
