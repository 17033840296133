import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import Divider from '@mui/material/Divider'

import Attributes from 'components/Panels/DetailsPanel/Attributes'
import AssociatedSubAreas from 'objects/common/AssociatedSubAreas/AssociatedSubAreas'
import LinkedProtections from 'objects/common/LinkedProtections/LinkedProtections'
import ResponsibleSubAreas from 'objects/common/ResponsibleSubAreas/ResponsibleSubAreas'
import { Sector } from 'objects/types/protections'
import { ITEM_TYPES } from 'objects/types/const'
import { RootState } from 'Store'

import { CollapsibleMenu } from 'reducers/types'
import SECTOR_ATTRIBUTES from './SectorAttributes'
import SectorServices from './SectorServices'

export default function SectorDetails(): ReactElement | null {
  const { item: sector }: { item: Sector } = useSelector((state: RootState) => state.detailsPanel)

  const {
    incompatibleProtections, associatedSubAreas, responsibleSubAreas, children,
  } = sector

  return (
    <div id="sectors-details" className="object-attributes">
      <Attributes item={sector} attributes={SECTOR_ATTRIBUTES()} />
      <Divider className="my-2" />
      <div id="linked-objects">
        <LinkedProtections
          linkedProtections={children}
          deleteAction={SectorServices.deleteChild}
          addIPAction={SectorServices.addChild}
          params={{
            title: 'Attributes.Protection.children',
            searchPlaceholder: 'Details.searchPlaceholder.electricalProtections',
            buttonLabel: 'Details.button.addElectricalProtection',
            filteredTypes: [ITEM_TYPES.feeder, ITEM_TYPES.electricalelement, ITEM_TYPES.subsector],
          }}
          type={CollapsibleMenu.children}
        />
        <ResponsibleSubAreas
          responsibleSubAreas={responsibleSubAreas}
          deleteRSAAction={SectorServices.deleteResponsibleSubArea}
          addRSAAction={SectorServices.addResponsibleSubArea}
        />
        <AssociatedSubAreas
          associatedSubAreas={associatedSubAreas}
          deleteASAAction={SectorServices.deleteAssociatedSubArea}
          addASAAction={SectorServices.addAssociatedSubArea}
        />
        <LinkedProtections
          linkedProtections={incompatibleProtections}
          deleteAction={SectorServices.deleteIncompatibleObject}
          addIPAction={SectorServices.addIncompatibleObject}
          type={CollapsibleMenu.incompatibleObjects}
        />
      </div>
    </div>
  )
}
