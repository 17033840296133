import {
  GeoEditorState, setEditorLayerMode, setGeoEditorModeConfig, updateGeoEditorObject,
} from 'reducers/geoEditor'
import { store } from 'Store'

import {
  EditorModeName, GeoEditorFeature, SelectEvent, UpdateEvent,
} from '../types'
import { Modes } from '../utils'
import { deletePointInGeometry } from './index'
import snapData from './snapping'

export const onToolChange = (): void => {
  const { mode, editorLayerModeName, object }: GeoEditorState = store.getState().TIVEditor

  switch (mode) {
    case Modes.select:
      store.dispatch(setGeoEditorModeConfig({ dragToDraw: true }))
      store.dispatch(setEditorLayerMode(EditorModeName.Select))
      break
    case Modes.draw:
      store.dispatch(setGeoEditorModeConfig({ dragToDraw: false }))
      if (editorLayerModeName !== EditorModeName.Extend) {
        store.dispatch(setEditorLayerMode(object ? EditorModeName.Edit : EditorModeName.Draw))
      }
      break
    default:
      store.dispatch(setGeoEditorModeConfig({ dragToDraw: false }))
      store.dispatch(setEditorLayerMode(EditorModeName.Edit))
  }
}

export const onUpdate = (params: UpdateEvent): void => {
  const { mode, editorLayerModeName }: GeoEditorState = store.getState().TIVEditor
  switch (mode) {
    case (Modes.draw):
      if (params.data[0]) {
        const modifiedData = snapData(params.data[0] as GeoEditorFeature)
        store.dispatch(updateGeoEditorObject(modifiedData))
      }
      if (editorLayerModeName === EditorModeName.Extend && params.editType === 'addPosition') {
        store.dispatch(setEditorLayerMode(EditorModeName.Edit))
        store.dispatch(setGeoEditorModeConfig({}))
      }
      break
    case (Modes.select):
      if (params.data[1]) {
        // console.log(params.data[1])
      }
      break
    default:
      break
  }
}

export const onSelect = (e: SelectEvent): void => {
  const { mode, object }: GeoEditorState = store.getState().TIVEditor
  const clickedPointIndex = e.selectedEditHandleIndex

  // Delete clicked point if in deletePoint Mode
  if (mode === Modes.deletePoint && object && clickedPointIndex !== null) {
    store.dispatch(updateGeoEditorObject(deletePointInGeometry(object, clickedPointIndex)))
  // Extend line by one point if clicked on extremity point
  } else if (object && mode === Modes.draw && clickedPointIndex !== null) {
    const { length: nbOfPoints } = object.geometry.coordinates
    if (clickedPointIndex === 0 || clickedPointIndex === nbOfPoints - 1) {
      store.dispatch(setGeoEditorModeConfig(clickedPointIndex === 0 ? { drawAtFront: true } : {}))
      setTimeout(() => store.dispatch(setEditorLayerMode(EditorModeName.Extend)), 0)
    }
  }
}
