import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import InstructionServices from 'services/InstructionServices'
import { InstructionState } from 'reducers/instruction'
import UserServices from 'services/UserServices'
import { User } from 'services/cerbereTypes'
import { RootState } from 'Store'

import { AdminState } from 'reducers/admin'
import UserMultiSelect from '../common/UserMultiSelect'
import { getAdministrators, getOperators, getSupervisors } from '../utils'

export default function SelectFields(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    instruction, operators, supervisors, administrators,
  } = useSelector(
    (state: RootState) => state.instruction,
  ) as InstructionState
  const { groups } = useSelector((state: RootState) => state.admin) as AdminState
  const [selectedAdministrators, setSelectedAdministrators] = useState<User[]>([])
  const [selectedSupervisors, setSelectedSupervisors] = useState<User[]>([])
  const [selectedOperators, setSelectedOperators] = useState<User[]>([])

  useEffect(() => {
    dispatch(UserServices.getGroups())
  }, [])

  useEffect(() => {
    getOperators(groups)
    getSupervisors(groups)
    getAdministrators(groups)
  }, [groups])

  const isButtonDisabled = selectedOperators.length === 0 || selectedSupervisors.length === 0

  return (
    <>
      <div className="colored-title d-flex justify-content-center align-items-center">
        <div className="w-75 mx-auto">
          <div className="title-wrapper text-center d-flex justify-content-center">
            <h1>{t('Instruction.creation.userAssignation.title')}</h1>
          </div>
          <div className="d-flex input-wrapper justify-content-around align-items-center w-100 flex-column">
            <div className="m-auto w-50 my-3">
              <div className="my-5">
                <UserMultiSelect
                  options={administrators}
                  label="Instruction.creation.userAssignation.adminSelect.label"
                  placeholder="Instruction.creation.userAssignation.adminSelect.placeholder"
                  onChange={setSelectedAdministrators}
                />
              </div>
              <div className="my-5">
                <UserMultiSelect
                  options={supervisors}
                  label="Instruction.creation.userAssignation.supervisorSelect.label"
                  placeholder="Instruction.creation.userAssignation.supervisorSelect.placeholder"
                  onChange={setSelectedSupervisors}
                />
              </div>
              <div className="my-5">
                <UserMultiSelect
                  options={operators}
                  label="Instruction.creation.userAssignation.operatorSelect.label"
                  placeholder="Instruction.creation.userAssignation.operatorSelect.placeholder"
                  onChange={setSelectedOperators}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blank-footer" />
      <div className="button-wrapper w-100 d-flex justify-content-center algin-items-center">
        <SimpleButton
          disabled={isButtonDisabled}
          style={ButtonStyle.primary}
          title={t('Instruction.button.continue')}
          onClick={() => {
            dispatch(InstructionServices.addUsers({
              ...instruction,
              users: [
                ...selectedOperators.map(o => o.id),
                ...selectedSupervisors.map(s => s.id),
                ...selectedAdministrators.map(a => a.id),
              ],
            }))
          }}
        />
      </div>
    </>
  )
}
