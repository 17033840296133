import { DateTime } from 'luxon'
import { ObjectLayer } from 'objects/types/const'
import { InputTypes, Attribute } from 'objects/attributes'
import { formatTrackChoice } from 'objects/utils'
import Parser from 'objects/parser'

const ISOLATOR_ATTRIBUTES = (defaultValues?: {[key: string]: string | undefined}): Array<Attribute> => ([
  {
    key: 'track',
    path: 'kp',
    label: 'Attributes.common.track',
    parser: Parser.identity,
    type: InputTypes.CaptureClick,
    isEditable: true,
    hideDetails: true,
    required: true,
    creation: {
      required: true,
      defaultValue: '',
    },
    captureClickHelper: 'Modification.helperText.trackCaptureClick',
    captureClickParams: {
      updateParams: [
        {
          clickedObjectPath: 'trackName',
          path: ['kp', 'trackName'],
        },
        {
          clickedObjectPath: 'lineCode',
          path: ['kp', 'lineCode'],
        },
        {
          clickedObjectPath: 'id',
          path: ['kp', 'trackIdentificationSystem'],
        },
      ],
      objectLayer: ObjectLayer.TrackIdentificationSystem,
      displayedValueFormatter: formatTrackChoice,
    },
  },
  {
    key: 'trackName',
    path: ['kp', 'trackName'],
    label: 'Attributes.common.track',
    parser: Parser.identity,
    isEditable: false,
    required: false,
  },
  {
    key: 'kp',
    path: ['kp', 'value'],
    label: 'Attributes.common.kp',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'activityStartDate',
    path: 'activityStartDate',
    label: 'Attributes.common.activityStartDate',
    parser: Parser.date,
    isEditable: true,
    required: true,
    type: InputTypes.DatePicker,
    checkboxLabel: 'Attributes.common.activityStartDateCheckbox',
    creation: {
      required: true,
      defaultValue: defaultValues?.activityStartDate || DateTime.now().toISODate(),
    },
  },
  {
    key: 'activityEndDate',
    path: 'activityEndDate',
    label: 'Attributes.common.activityEndDate',
    parser: Parser.date,
    isEditable: true,
    required: true,
    type: InputTypes.DatePicker,
    checkboxLabel: 'Attributes.common.activityEndDateCheckbox',
    creation: {
      required: true,
      defaultValue: null,
    },
  },
])

export default ISOLATOR_ATTRIBUTES
