import { ReactElement } from 'react'
import get from 'lodash/get'

import GenericInput from 'components/Common/Inputs/GenericInput'
import { Attribute } from 'objects/attributes'
import { nestedObject, ObjOfStrOrNum, ResponseError } from 'types'
import { FieldValue } from 'reducers/types'
import InputGroup from 'components/Common/Inputs/InputGroup/InputGroup'
import Loader from 'components/Common/Loader'

type Props = {
  attributes: Attribute[];
  handleError: (k: string, v: boolean) => void;
  item: ObjOfStrOrNum | nestedObject;
  responseError: ResponseError | undefined;
  onChange: (v: FieldValue, f: Attribute) => void;
  isLoading?: boolean;
}

const defaultProps = {
  isLoading: false,
}

export default function PanelFieldsContent({
  attributes, handleError, item, responseError, onChange, isLoading,
}: Props): ReactElement {
  return (
    <>
      {isLoading && <Loader overlay /> }
      <div style={{ flex: 1, height: 'calc(100vh - 550px)' }} className="py-3 overflow-auto">
        {attributes.map(field => (field.isArray
          ? (
            <InputGroup
              item={item}
              key={field.key}
              field={field}
              onChange={onChange}
              handleError={handleError}
              responseError={responseError}
              disabled={
              field.shouldBeDisabled && field.shouldBeDisabled.values.includes(get(item, field.shouldBeDisabled.path))
            }
            />
          )
          : (
            <div className="px-3">
              <GenericInput
                key={field.key}
                field={field}
                value={get(item, field.updatePath || field.path)}
                onChange={onChange}
                onFieldError={handleError}
                responseError={responseError}
                disabled={
                field.shouldBeDisabled && field.shouldBeDisabled.values.includes(get(item, field.shouldBeDisabled.path))
              }
              />
            </div>
          )))}
      </div>
    </>
  )
}

PanelFieldsContent.defaultProps = defaultProps
