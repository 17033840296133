import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import ObjectTile from 'components/ObjectItem/ObjectTile'
import SearchBar from 'components/Common/SearchBar/SearchBar'
import { Area } from 'objects/types/protections'
import ObjectURI from 'objects/uri'
import { GenericAction, ThunkApiConfig } from 'types'
import { AsyncThunkAction } from '@reduxjs/toolkit'
import { CollapsibleMenu } from 'reducers/types'
import { RootState } from 'Store'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import RSAContextMenu from './RSAContextMenu'
import toggleOpenCollapsible from '../utils'

type Props = {
  responsibleSubAreas: Area[];
  addRSAAction: (a: Area) => AsyncThunkAction<unknown, Area, ThunkApiConfig>;
  deleteRSAAction: GenericAction;
}

export default function ResponsibleSubAreas({
  responsibleSubAreas, addRSAAction, deleteRSAAction,
}: Props): ReactElement | null {
  const { t } = useTranslation()
  const { openMenus } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)

  const dispatch = useDispatch()
  const [displaySearch, setDisplaySearch] = useState(false)

  if (!responsibleSubAreas) return null

  const onCloseSearch = (value: Area | undefined = undefined) => {
    setDisplaySearch(false)
    if (value !== undefined) dispatch(addRSAAction(value))
  }

  return (
    <Accordion
      disableGutters
      expanded={openMenus.includes(CollapsibleMenu.responsibleSubAreas)}
      onChange={() => toggleOpenCollapsible(CollapsibleMenu.responsibleSubAreas)}
    >
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
        {`${t('Attributes.TrackProtection.responsibleSubAreas')} (${responsibleSubAreas.length})`}
      </AccordionSummary>
      <AccordionDetails>
        {responsibleSubAreas.map(rsa => (
          <ObjectTile
            key={rsa.id}
            onClick={() => {}}
            title={rsa.label}
            subtitle={rsa.areaLabel || ''}
            contextMenu={<RSAContextMenu rsa={rsa} deleteAction={deleteRSAAction} />}
          />
        ))}
        <div className="my-2">
          {displaySearch
            ? (
              <SearchBar
                onSelect={onCloseSearch}
                searchURI={`/midi-next/v2/${ObjectURI.SubAreas}/`}
                placeholder={t('Details.searchPlaceholder.areaOrSubArea')}
                labelFormatter={(option: Area) => `${option.label} / ${option.areaLabel}`}
              />
            )
            : (
              <>
                <SimpleButton
                  title={t('Details.button.addResponsibleSubArea')}
                  onClick={() => { setDisplaySearch(true) }}
                  style={ButtonStyle.light}
                />
              </>
            )}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
