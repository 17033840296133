import { RefObject } from 'react'
import { Editor } from 'react-map-gl-draw'
import { MapEvent } from 'react-map-gl'
import { Feature, point } from '@turf/helpers'
import nearestPointOnLine, { NearestPointOnLine } from '@turf/nearest-point-on-line'
import { LineString, Point } from 'geojson'

import { GeoEditorState, setSnappedGeometry, setSnappedMouseCoords } from 'reducers/geoEditor'
import { TrackSection } from 'objects/types'
import { store } from 'Store'

import { GeoEditorFeature } from '../types'
import getNearestFeature from './getNearestFeature'

// Find which point the user is draging, to know at which index the point to snap is in the geometry
export const findModifiedIndex = (newGeometry: LineString): number | undefined => {
  const { object } = store.getState().TIVEditor as GeoEditorState

  if (!object) return undefined

  let i = 0
  let modifiedPointIndex
  while (i < newGeometry.coordinates.length && modifiedPointIndex === undefined) {
    const newStrPoint = JSON.stringify(newGeometry.coordinates[i])
    const oldStrPoint = JSON.stringify(object.geometry.coordinates[i])
    if (newStrPoint !== oldStrPoint) {
      modifiedPointIndex = i
      break
    }
    i += 1
  }
  return modifiedPointIndex
}

// On hover Event, find the nearest Feature to snap to
export const snapOnHover = (e: MapEvent, editorRef: RefObject<Editor>): void => {
  const snappingLayers = ['snapping-points', 'snapping-lines']

  const nearestSnappingFeatures: Feature<Point | LineString, TrackSection>[] = e.features
    ? e.features.filter(f => snappingLayers.includes(f.layer.id))
    : []
  if (nearestSnappingFeatures.length !== 0) {
    const chosenFeature = getNearestFeature(e.lngLat, nearestSnappingFeatures)
    store.dispatch(setSnappedGeometry(chosenFeature))

    // update mousecoords when user is moving a point on the feature
    if (editorRef.current && editorRef.current !== null && editorRef.current.state.isDragging) {
      store.dispatch(setSnappedMouseCoords(e.lngLat))
    }
  } else {
    store.dispatch(setSnappedGeometry(undefined))
  }
}

// Once you have the Feature to snap to, you can find the effective point to put in your new geometry
// Either the found Point, or the orthogonal projection on the LineString
export const findSnappedPoint = (): Feature<Point> | NearestPointOnLine | null => {
  const { snappedGeometry, snappedMouseCoords } = store.getState().TIVEditor as GeoEditorState

  if (snappedGeometry && snappedMouseCoords) {
    if (snappedGeometry.geometry.type === 'Point') {
      return snappedGeometry as Feature<Point>
    }

    const mousePoint = point(snappedMouseCoords)
    return nearestPointOnLine(
      snappedGeometry.geometry,
      mousePoint,
    )
  }
  return null
}

const snap = (data: GeoEditorFeature): GeoEditorFeature => {
  const newGeometry = data.geometry
  const modifiedPointIndex = findModifiedIndex(newGeometry)
  const snappedPoint = findSnappedPoint()
  if (modifiedPointIndex !== undefined && snappedPoint) {
    newGeometry.coordinates[modifiedPointIndex] = snappedPoint.geometry.coordinates as [number, number]
  }
  return data
}

export default snap
