import { isAnyOf } from '@reduxjs/toolkit'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'

export const updateTPGLinkedObjectsPendingMatcher = isAnyOf(
  TrackProtectionGroupServices.deleteExtremity.pending,
  TrackProtectionGroupServices.switchExtremityDirection.pending,
  TrackProtectionGroupServices.addResponsibleSubArea.pending,
  TrackProtectionGroupServices.deleteResponsibleSubArea.pending,
  TrackProtectionGroupServices.addTrackProtection.pending,
  TrackProtectionGroupServices.deleteTrackProtection.pending,
  TrackProtectionGroupServices.addAssociatedSubArea.pending,
  TrackProtectionGroupServices.deleteAssociatedSubArea.pending,
  TrackProtectionGroupServices.deleteIncompatibleObject.pending,
  TrackProtectionGroupServices.addIncompatibleObject.pending,
)

export const updateTPGLinkedObjectsFulfilledMatcher = isAnyOf(
  TrackProtectionGroupServices.deleteExtremity.fulfilled,
  TrackProtectionGroupServices.switchExtremityDirection.fulfilled,
  TrackProtectionGroupServices.addResponsibleSubArea.fulfilled,
  TrackProtectionGroupServices.deleteResponsibleSubArea.fulfilled,
  TrackProtectionGroupServices.addTrackProtection.fulfilled,
  TrackProtectionGroupServices.deleteTrackProtection.fulfilled,
  TrackProtectionGroupServices.addAssociatedSubArea.fulfilled,
  TrackProtectionGroupServices.deleteAssociatedSubArea.fulfilled,
  TrackProtectionGroupServices.deleteIncompatibleObject.fulfilled,
  TrackProtectionGroupServices.addIncompatibleObject.fulfilled,
)

export const updateTPGLinkedObjectsErrorMatcher = isAnyOf(
  TrackProtectionGroupServices.deleteExtremity.rejected,
  TrackProtectionGroupServices.switchExtremityDirection.rejected,
  TrackProtectionGroupServices.addResponsibleSubArea.rejected,
  TrackProtectionGroupServices.deleteResponsibleSubArea.rejected,
  TrackProtectionGroupServices.addTrackProtection.rejected,
  TrackProtectionGroupServices.deleteTrackProtection.rejected,
  TrackProtectionGroupServices.addAssociatedSubArea.rejected,
  TrackProtectionGroupServices.deleteAssociatedSubArea.rejected,
  TrackProtectionGroupServices.deleteIncompatibleObject.rejected,
  TrackProtectionGroupServices.addIncompatibleObject.rejected,
)
