import { ReactElement, SyntheticEvent } from 'react'
import { useSelector } from 'react-redux'

import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'

import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import TabsMenu from 'components/Common/TabsMenu/TabsMenu'
import Loader from 'components/Common/Loader'
import { ObjectKind } from 'objects/types/const'
import { RootState } from 'Store'
import DetailsTitle from './DetailsTitle'
import { DetailPanelTab, TabContentOfKind } from './utils'
import './DetailsPanelContent.scss'

type Props = {
  kind: ObjectKind;
  value: string;
  tabs: DetailPanelTab[];
  handleTabChange: (e: SyntheticEvent, v: string) => void;
}

export default function DetailsPanelContent({
  kind, value, tabs, handleTabChange,
}: Props): ReactElement {
  const { isLoading } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)

  const usedKind = kind === ObjectKind.TrackIdentificationSystem
    ? ObjectKind.Track
    : kind

  return (
    <>
      {isLoading && <Loader overlay />}
      <div className="padded">
        <DetailsTitle />
      </div>
      <div id="details-panel-content" className="d-flex flex-column">
        <TabContext value={value}>
          <TabsMenu tabs={tabs} handleTabChange={handleTabChange} />
          {tabs.map(tab => (
            <TabPanel
              key={tab.key}
              value={tab.value}
              className="tab-panel flex-column"
            >
              {TabContentOfKind[usedKind][tab.key]}
            </TabPanel>
          ))}
        </TabContext>
      </div>
    </>
  )
}
