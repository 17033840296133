import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from '@osrdata/app_core/dist/translation'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

import { ReactComponent as GeoEditorIcon } from 'assets/icons/geoeditor.svg'
import { DeleteServiceOfKind } from 'objects/services'
import { ObjectKind } from 'objects/types/const'
import getCSSValue from 'helpers'
import { RootState } from 'Store'
import { switchToGeoEditor } from 'components/GeoEditor/utils'
import { Tooltip } from '@mui/material'

type Props = {
  kind: ObjectKind;
  hidePanel: () => void;
}

export default function DetailsPanelHeader({ kind, hidePanel }: Props): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { item } = useSelector((state: RootState) => state.detailsPanel)

  const deleteObject = () => {
    dispatch(DeleteServiceOfKind[kind](item.id))
    hidePanel()
  }

  return (
    <>
      <Divider orientation="vertical" className="divider mr-3" />
      <Tooltip title={t('Details.button.deleteObject')}>
        <IconButton onClick={() => deleteObject()}>
          <DeleteOutlinedIcon htmlColor={getCSSValue('--color-carbone')} />
        </IconButton>
      </Tooltip>
      {kind === ObjectKind.TrackSection && (
      <Tooltip title={t('Details.button.editGeometry')}>
        <IconButton onClick={() => switchToGeoEditor(item.id)}>
          <GeoEditorIcon fill={getCSSValue('--color-carbone')} height={24} width={24} viewBox="-3 -3 24 24" />
        </IconButton>
      </Tooltip>
      )}
    </>
  )
}
