import { useTranslation } from '@osrdata/app_core/dist/translation'
import { useSelector } from 'react-redux'
import React, { ReactElement, useState } from 'react'
import {
  DragDropContext, Draggable, DragStart, Droppable,
} from 'react-beautiful-dnd'

import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineDot from '@mui/lab/TimelineDot'
import Divider from '@mui/material/Divider'
import Timeline from '@mui/lab/Timeline'
import Chip from '@mui/material/Chip'

import { TisListState } from 'reducers/trackIdentificationSystemList'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import ObjectItem from 'components/ObjectItem/ObjectItem'
import Loader from 'components/Common/Loader'
import { isTrack } from 'objects/kind'
import { Track } from 'objects/types'
import { RootState } from 'Store'

import TISContextMenu from '../TISContextMenu'
import CreateTISButton from './CreateTISButton'
import onDragEnd from './utils'
import './TISList.scss'

export default function TrackIdentificationSystemsList(): ReactElement | null {
  const { t } = useTranslation()
  const { item } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)
  const { isLoading } = useSelector((state: RootState): TisListState => state.tisList)
  const [sourceTISId, setSourceTISId] = useState<string | undefined>(undefined)

  if (item === undefined || !isTrack(item)) return null
  if (isLoading) return <Loader />

  const onDragStart = (initial: DragStart) => {
    setSourceTISId(initial.source.droppableId)
  }

  const track = item as Track
  if (!track.trackIdentificationSystems) return null
  const disableDragAndDrop = track.trackIdentificationSystems.length === 1

  return (
    <>
      <div id="tis-list">
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          {track.trackIdentificationSystems.map(tis => (
            <React.Fragment key={tis.id}>
              <div
                className="d-flex justify-content-between align-items-center flex-wrap w-100"
              >
                <div className="tis-name">
                  {`${t('Details.title.trackIdentificationSystem')} ${tis.startKp}/${tis.endKp}`}
                </div>
                {tis.deletedAt && (
                <Chip
                  className="deleted"
                  label={t('Details.title.deleted')}
                />
                )}
                <TISContextMenu tis={tis} />
              </div>
              <Droppable droppableId={tis.id} isDropDisabled={disableDragAndDrop || sourceTISId === tis.id}>
                {provided => (
                  <Timeline ref={provided.innerRef}>
                    {tis.trackSections.map((ts, i) => (
                      <TimelineItem key={tis.id + ts.id}>
                        <TimelineSeparator>
                          <TimelineDot />
                          {i !== (tis.trackSections.length - 1) && <TimelineConnector />}
                        </TimelineSeparator>
                        <Draggable
                        // Some TS may be on several TIS, we need to make draggableId unique
                          draggableId={`${tis.id}/${ts.id}`}
                          index={i}
                          isDragDisabled={disableDragAndDrop}
                        >
                          {providedDraggable => (
                            <TimelineContent
                              ref={providedDraggable.innerRef}
                              {...providedDraggable.draggableProps}
                              {...providedDraggable.dragHandleProps}
                            >
                              <ObjectItem item={ts} />
                            </TimelineContent>
                          )}
                        </Draggable>
                      </TimelineItem>
                    ))}
                    {provided.placeholder}
                  </Timeline>
                )}
              </Droppable>
            </React.Fragment>
          ))}
        </DragDropContext>
      </div>

      <Divider className="mt-2 mb-4" />
      <CreateTISButton />
    </>
  )
}
