export enum ITEM_TYPES {
  electricalelement = 'electricalelement',
  electricalprotectiongroup = 'electricalprotectionsgroup',
  feeder = 'feeder',
  track = 'track',
  tracksection = 'tracksection',
  trackidentificationsystem = 'trackidentificationsystem',
  isolator = 'isolator',
  sector = 'sector',
  subsector = 'subsector',
  signal = 'signal',
  trackprotection = 'trackprotection',
  trackprotectiongroup = 'trackprotectiongroup',
}

export enum ObjectKind {
  ElectricalElement = 'ElectricalElement',
  ElectricalProtectionGroup = 'ElectricalProtectionGroup',
  Feeder = 'Feeder',
  Track = 'Track',
  TrackSection = 'TrackSection',
  TrackIdentificationSystem = 'TrackIdentificationSystem',
  Isolator = 'Isolator',
  Sector = 'Sector',
  SubSector = 'SubSector',
  Signal = 'Signal',
  TrackProtection = 'TrackProtection',
  TrackProtectionGroup = 'TrackProtectionGroup',
}

export enum ExtraKind {
  EEExtremity = 'EEExtremity',
  EPGExtremity = 'EPGExtremity',
  FeederExtremity = 'FeederExtremity',
  TPExtremity = 'TPExtremity',
  TPGExtremity = 'TPGExtremity',
}

export enum ObjectLayer {
  ElectricalElement = 'midi_electrical_element',
  ElectricalProtectionGroup = 'midi_electrical_protections_group',
  Feeder = 'midi_feeder',
  Isolator = 'midi_isolator',
  Sector = 'midi_sector',
  SubSector = 'midi_sub_sector',
  Signal = 'midi_signal',
  Track = 'midi_track',
  TrackIdentificationSystem = 'midi_track_identification_system',
  TrackProtection = 'midi_track_protection',
  TrackProtectionGroup = 'midi_track_protection_group',
  TrackSection = 'midi_track_section',
}

export const NULL_STRING = 'nullString'

export enum ObjectStatus {
  TODO = 0,
  IN_PROGRESS = 10,
  VALIDATED = 20,
  VERIFIED = 30,
  DISPATCHED = 40,
}

export enum KPOrientation {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum UserKPOrientation {
  ASC = 'Croissant',
  DESC = 'Décroissant',
}

export const KPOrientationMap = [
  {
    label: UserKPOrientation.ASC,
    value: KPOrientation.ASC,
  },
  {
    label: UserKPOrientation.DESC,
    value: KPOrientation.DESC,
  },
]

export enum UserTrackType {
  MAIN = 'VP',
  SERV = 'VS',
}

export enum TrackType {
  MAIN = 'MAIN',
  SERV = 'SERV',
}

export const TrackTypeMap = [
  {
    label: UserTrackType.MAIN,
    value: TrackType.MAIN,
  },
  {
    label: UserTrackType.SERV,
    value: TrackType.SERV,
  },
]

export enum UserDirectionType {
  ASC = 'Croissant',
  DES = 'Décroissant',
}

export enum DirectionType {
  ASC = 'U',
  DES = 'D',
}

export const DirectionTypeMap = [
  {
    label: UserDirectionType.ASC,
    value: DirectionType.ASC,
  },
  {
    label: UserDirectionType.DES,
    value: DirectionType.DES,
  },
]

export enum ExtraLayer {
  Direction = 'directions',
  LineCode = 'line-codes',
  TrackName = 'names',
}

export const ExtraLayerSource = {
  [ExtraLayer.Direction]: ObjectLayer.TrackSection,
  [ExtraLayer.TrackName]: ObjectLayer.Track,
  [ExtraLayer.LineCode]: ObjectLayer.Track,
}
