import { Fragment, ReactElement, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { InstructionState } from 'reducers/instruction'
import { RootState } from 'Store'

import ColorLoading from '../common/ColorLoading'
import SelectionFulfilled from '../common/SelectionFulfilled'
import EditItemsList from '../ItemsSelection/EditItemList/EditItemsList'
import NewPerimeterMap from './NewPerimeterMap'
import './NewPerimeter.scss'

const SUB_STEPS = (onClose: () => void) => [
  {
    key: 0,
    component: <NewPerimeterMap onClose={onClose} />,
  },
  {
    key: 1,
    component: <ColorLoading message="Instruction.creation.itemSelection.getObjectsLoading" />,
  },
  {
    key: 2,
    component: <SelectionFulfilled />,
  },
  {
    key: 3,
    component: <EditItemsList />,
  },
  {
    key: 4,
    component: <ColorLoading
      message="Instruction.creation.itemSelection.saveObjectsLoading"
    />,
  },
]

type Props = {
  onClose: () => void;
}

export default function NewPerimeter({ onClose }: Props): ReactElement {
  const { activeStep, activeSubStep } = useSelector((state: RootState) => state.instruction) as InstructionState

  useEffect(() => {
    if (activeStep === 1) onClose()
  }, [activeStep])

  return (
    <div className="new-perimeter">
      {SUB_STEPS(onClose).map(subStep => (activeSubStep === subStep.key
        ? (
          <Fragment key={subStep.key}>
            {subStep.component}
          </Fragment>
        )
        : null))}
    </div>
  )
}
