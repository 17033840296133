import { useTranslation } from '@osrdata/app_core/dist/translation'
import {
  ReactElement, useState, MouseEvent, ChangeEvent, useEffect,
} from 'react'

import FormControlLabel from '@mui/material/FormControlLabel'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import TodayIcon from '@mui/icons-material/Today'
import FormGroup from '@mui/material/FormGroup'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { updateDayValueFrom, validate } from './utils'
import { InputDataType } from '../utils'
import './CustomDatePicker.scss'

export default function CustomDatePicker({
  field, value, onChange, error, helperText, disabled: propsDisabled,
}: InputDataType): ReactElement {
  const { t } = useTranslation()

  const [localError, setLocalError] = useState(false)
  const [localHelperText, setLocalHelperText] = useState(helperText)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(false)
  const [disabled, setDisabled] = useState(value === null)

  useEffect(() => {
    setDisabled(value === null)
  }, [value])

  useEffect(() => {
    setLocalHelperText(helperText)
  }, [helperText])

  const onChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setDisabled(e.target.checked)
    onChange(e.target.checked ? null : '', field)
  }

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const handleChangeDate = (newDate: string) => {
    validate(newDate, field, onChange, setLocalError)
  }

  return (
    <div key={field.key} className="w-100 my-3 px-2 date-picker" id={`date-picker-${field.key}`}>
      <DatePicker
        disabled={disabled || propsDisabled}
        value={value as string}
        open={open}
        onClose={handleClose}
        onYearChange={handleChangeDate}
        onChange={(newValue: string | null) => onChange(updateDayValueFrom(newValue, value), field)}
        renderInput={(inputParams: TextFieldProps) => (
          <TextField
            {...inputParams}
            variant="standard"
            type="date"
            value={value !== null ? field.parser(value as string) : ''}
            label={t(field.label)}
            error={(error || localError) && !propsDisabled}
            helperText={localHelperText && !propsDisabled ? t(localHelperText) : undefined}
            InputLabelProps={{ shrink: true }}
            disabled={propsDisabled}
            InputProps={{
              ...inputParams.InputProps,
              className: 'input',
              disableUnderline: true,
              endAdornment: !disabled && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClick}>
                    <TodayIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        PopperProps={{ anchorEl }}
      />
      {field.checkboxLabel !== undefined && (
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={disabled} onChange={onChangeCheckbox} disabled={propsDisabled} />}
            label={t(field.checkboxLabel) as string}
            disabled={propsDisabled}
          />
        </FormGroup>
      )}
    </div>
  )
}
