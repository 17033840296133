import ElectricalElementServices from 'objects/ElectricalElements/ElectricalElementServices'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'
import FeederServices from 'objects/Feeders/FeederServices'
import IsolatorServices from 'objects/Isolators/IsolatorServices'
import SectorServices from 'objects/Sectors/SectorServices'
import SignalServices from 'objects/Signals/SignalServices'
import SubSectorServices from 'objects/SubSectors/SubSectorServices'
import TrackIdentificationSystemServices from 'objects/TrackIdentificationSystems/TISServices'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import TrackServices from 'objects/Tracks/TrackServices'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'
import AreaServices from 'services/AreaServices'
import InstructionServices from 'services/InstructionServices'

import { isAnyOf } from '@reduxjs/toolkit'
import SubnetServices from 'services/SubnetServices'

export const deleteFulfilledMatcher = isAnyOf(
  AreaServices.delete.fulfilled,
  ElectricalElementServices.delete.fulfilled,
  ElectricalProtectionGroupServices.delete.fulfilled,
  FeederServices.delete.fulfilled,
  InstructionServices.deleteItem.fulfilled,
  IsolatorServices.delete.fulfilled,
  SectorServices.delete.fulfilled,
  SignalServices.delete.fulfilled,
  SubnetServices.delete.fulfilled,
  SubSectorServices.delete.fulfilled,
  TrackIdentificationSystemServices.delete.fulfilled,
  TrackProtectionGroupServices.delete.fulfilled,
  TrackProtectionServices.delete.fulfilled,
  TrackSectionServices.delete.fulfilled,
  TrackServices.delete.fulfilled,
)

export const deletePendingMatcher = isAnyOf(
  AreaServices.delete.pending,
  ElectricalElementServices.delete.pending,
  ElectricalProtectionGroupServices.delete.pending,
  FeederServices.delete.pending,
  IsolatorServices.delete.pending,
  SectorServices.delete.pending,
  SignalServices.delete.pending,
  SubnetServices.delete.pending,
  SubSectorServices.delete.pending,
  TrackProtectionServices.delete.pending,
  TrackProtectionGroupServices.delete.pending,
  TrackSectionServices.delete.pending,
  TrackServices.delete.pending,
)

export const deleteErrorMatcher = isAnyOf(
  AreaServices.delete.rejected,
  AreaServices.deleteSubArea.rejected,
  ElectricalElementServices.delete.rejected,
  ElectricalProtectionGroupServices.delete.rejected,
  FeederServices.delete.rejected,
  InstructionServices.deleteItem.rejected,
  IsolatorServices.delete.rejected,
  SectorServices.delete.rejected,
  SignalServices.delete.rejected,
  SubnetServices.delete.rejected,
  SubSectorServices.delete.rejected,
  TrackIdentificationSystemServices.delete.rejected,
  TrackProtectionGroupServices.delete.rejected,
  TrackProtectionServices.delete.rejected,
  TrackSectionServices.delete.rejected,
  TrackServices.delete.rejected,
)
