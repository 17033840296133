/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Polygon } from 'geojson'
import { deleteRequest, get, patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Instruction, InstructionItem, InstructionType } from 'objects/types/instructions'
import { ThunkApiConfig } from 'types'
import URI from 'services/uri'
import { store } from 'Store'

const updateItemsHelper = async (id: string, items: InstructionItem[], geom: Polygon) => {
  const response: Instruction = await patch(
    `/midi-next/v2/${URI.instructions}/${id}`,
    { items, geom },
  )
  return response
}

type GetItemsInBboxParams = {
  bpolygon: Polygon;
  type: InstructionType;
  date: string;
  subnet_id: string;
}

export const getItemsInBbox = createAsyncThunk<InstructionItem[], GetItemsInBboxParams, ThunkApiConfig>(
  'instruction/getItemsInBbox',
  async (params, thunkApi) => {
    try {
      const response: InstructionItem[] = await get(
        `/midi-next/v2/${URI.instructionItems}/`,
        params,
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type AddItemsParams = {
  id: string;
  items: InstructionItem[];
  geom: Polygon;
}

export const addItems = createAsyncThunk<Instruction, AddItemsParams, ThunkApiConfig>(
  'instruction/addItems',
  async (params, thunkApi) => {
    try {
      return await updateItemsHelper(params.id, params.items, params.geom)
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const updateItems = createAsyncThunk<Instruction, AddItemsParams, ThunkApiConfig>(
  'instruction/updateItems',
  async (params, thunkApi) => {
    try {
      return await updateItemsHelper(params.id, params.items, params.geom)
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type ItemDetailsParams = {
  id: string;
  page?: number;
  search?: string;
}

type ItemDetailsResponse = {
  items: InstructionItem[];
  totalItems: number;
}

export const getItemsDetailsList = createAsyncThunk<ItemDetailsResponse, ItemDetailsParams, ThunkApiConfig>(
  'instruction/getItemsDetailsList',
  async (params, thunkApi) => {
    try {
      const response = await get(
        `/midi-next/v2/${URI.instructions}/${params.id}/${URI.instructionItems}`, {
          page: params.page || 1,
          search: params.search || '',
        },
      )
      return {
        items: response.results as InstructionItem[],
        totalItems: response.count as number,
      }
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type DeleteItemParams = {
  id: string;
  itemId: string;
}

export const deleteItem = createAsyncThunk<string, DeleteItemParams, ThunkApiConfig>(
  'instruction/deleteItem',
  async (params, thunkApi) => {
    try {
      await deleteRequest(
        `/midi-next/v2/${URI.instructions}/${params.id}/${URI.instructionItems}/${params.itemId}`,
      )
      store.dispatch(getItemsDetailsList({
        id: params.id,
      }))
      return params.id
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
