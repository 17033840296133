import {
  ReactElement, SyntheticEvent, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import { ListPanelState, resetPageNumber, setShouldRefresh } from 'reducers/panels/listPanel'
import { setLayers } from 'reducers/map'
import getCSSValue from 'helpers'
import { ReactComponent as FilterIcon } from 'assets/icons/filter_outlined.svg'
import { InstructionState } from 'reducers/instruction'
import { RootState } from 'Store'
import TabsMenu, { Tab } from 'components/Common/TabsMenu/TabsMenu'
import ErrorOverlay from 'components/Common/ErrorOverlay'
import SearchBar from 'components/Common/SimpleSearchBar'
import { ObjectLayer } from 'objects/types/const'
import Loader from 'components/Common/Loader'
import ObjectsFilterMenu from './ObjectsFilterMenu/ObjectsFilterMenu'
import { debouncedRefresh, refresh, tabs } from './utils'
import './ListPanel.scss'

const INITIAL_LAYERS = [ObjectLayer.Track, ObjectLayer.Isolator, ObjectLayer.Signal]

export default function ListPanel(): ReactElement | null {
  const dispatch = useDispatch()
  const {
    items, isLoading, errorCode, pageNumber, shouldRefresh, filterObjectType,
  } = useSelector((state: RootState): ListPanelState => state.listPanel)
  const { instruction } = useSelector((state: RootState) => state.instruction) as InstructionState
  const [value, setValue] = useState(tabs(items)[0].value)
  const [selectedTab, setSelectedTab] = useState(tabs(items)[0])
  const [search, setSearch] = useState('')
  const [displayFilters, setDisplayFilters] = useState(false)

  const handleTabChange = (_event: SyntheticEvent, newValue: string) => {
    const newTab = tabs(items).find(tab => tab.value === newValue) as Tab
    setValue(newValue)
    setSelectedTab(newTab)
    setSearch('')
    dispatch(resetPageNumber())
    refresh(instruction.id as string, 1, '', newTab, filterObjectType)
  }

  useEffect(() => {
    dispatch(
      setLayers(INITIAL_LAYERS),
    )
  }, [])

  useEffect(() => {
    refresh(
      instruction.id as string, pageNumber, search, selectedTab, filterObjectType,
    )
    if (shouldRefresh) dispatch(setShouldRefresh(false))
  }, [pageNumber, shouldRefresh])

  const onSearch = (s: string) => {
    setSearch(s)
    if (pageNumber === 1) {
      debouncedRefresh(instruction.id as string, pageNumber, s, selectedTab, filterObjectType)
    } else dispatch(resetPageNumber())
  }

  const renderContent = (tabComponent: ReactElement) => {
    if (isLoading) return <Loader />
    if (errorCode) return <ErrorOverlay code={errorCode} />
    return tabComponent
  }

  const onApplyFilter = () => {
    dispatch(resetPageNumber())
    dispatch(setShouldRefresh(true))
    setDisplayFilters(!displayFilters)
  }

  const onClickReturn = () => {
    setDisplayFilters(!displayFilters)
  }

  return (
    <div className="list-panel d-flex flex-column h-100">
      <TabContext value={value}>
        <TabsMenu tabs={tabs(items)} handleTabChange={handleTabChange} />
        {tabs(items).map(tab => (
          <TabPanel
            key={tab.key}
            value={tab.value}
            className="flex-column tab-panel p-3"
          >
            <SearchBar
              onChange={onSearch}
              value={search}
              rightIcon={<FilterIcon fill={getCSSValue('--color-carbone')} />}
              onRightIconClick={onClickReturn}
            />
            <ObjectsFilterMenu
              displayFilters={displayFilters}
              onApply={onApplyFilter}
              onClickReturn={onClickReturn}
            />

            {renderContent(tab.component || <div />)}
          </TabPanel>
        ))}
      </TabContext>
    </div>
  )
}
